import * as React from 'react';
import { CssTypography } from '../../constants/app-constants';
import "./Cards.css";
import SingleCard from './SingleCard/SingleCard';
import CustomSkeleton from '../Shared/CustomSkeleton/CustomSkeleton';
import { skeletonData } from '../../constants/card-skeleton-data';
import { capitalizeFirstLetter } from '../../actions/methods';

const Cards = ({ stateUserFlag, extractedEventsState, shortlistedEventsState, publishedEventsState, districtCategories, subdistrictCategories, importantNoEventArticles, content, aiTranslationFlag, countEvents, page, selectedEventSubType, buttonFreeze, isValidEventData, diseaseCategories, setExtractedEventsState = () => { }, setShortlistedEventsState = () => { }, setPublishedEventsState = () => { }, setImportantNoEventArticles = () => { }, setPublishEventIndexState = () => { }, setShortlistEventIndexState = () => { }, setRejectEventIndexState = () => { }, setRepublishDialogBoxState = () => { }, setSelectedEventData = () => { }, openRelatedEventsDialog = () => { }, openSummaryDialog = () => { }, sendClusterForApproval = () => { }, approveActionForCluster = () => { }, sendEmailForCluster = () => { }, openPDFDialog = () => { }, unRejectCluster = () => { }, setIsValidEventData = () => { }, updateEventAsChecked = () => { }, setShowRejectSourceDialog = () => { } }) => {
    const [stateUpdated, setStateUpdated] = React.useState(true);
    // const rejectDiv = window.getElementById('reject-button')
    var checkPublished = false;
    var mainDataPoints = [];

    if (content === "extractedEvents") {
        mainDataPoints = extractedEventsState
        checkPublished = false
    } else if (content === "importatnNoEventArticles") {
        mainDataPoints = importantNoEventArticles
        checkPublished = false
    } else if (content === "shortlistedEvents") {
        mainDataPoints = shortlistedEventsState
        checkPublished = false
    } else if (content === "publishedEvents") {
        mainDataPoints = publishedEventsState
        checkPublished = true
    }
    const handleFinalDiseaseChange = (index, event, category) => {
        var changedDisease = event
        var data = mainDataPoints
        data[index].disease = changedDisease
        data[index].disease_macro_category = category
        if (content === "extractedEvents") {
            setExtractedEventsState(data)
        } else if (content === "importatnNoEventArticles") {
            setImportantNoEventArticles(data)
        } else if (content === "shortlistedEvents") {
            setShortlistedEventsState(data)
        } else if (content === "publishedEvents") {
            setPublishedEventsState(data)
        }
        setStateUpdated(!stateUpdated)
    }

    const handleDistrictChange = (index, event) => {
        var changeDistrict = event
        var data = mainDataPoints
        data[index].district = changeDistrict
        data[index].sub_district = ""
        if (content === "extractedEvents") {
            setExtractedEventsState(data)
        } else if (content === "importatnNoEventArticles") {
            setImportantNoEventArticles(data)
        } else if (content === "shortlistedEvents") {
            setShortlistedEventsState(data)
        } else if (content === "publishedEvents") {
            setPublishedEventsState(data)
        }
        setStateUpdated(!stateUpdated)
    }
    const handleDateChange = (index, event) => {
        var changedDate = event.target.value
        var data = mainDataPoints
        data[index].extracted_date = changedDate
        if (content === "extractedEvents") {
            setExtractedEventsState(data)
        } else if (content === "importatnNoEventArticles") {
            setImportantNoEventArticles(data)
        } else if (content === "shortlistedEvents") {
            setShortlistedEventsState(data)
        } else if (content === "publishedEvents") {
            setPublishedEventsState(data)
        }
        setStateUpdated(!stateUpdated)
    }

    const handleNumberChange = (index, numberObj) => {
        var data = mainDataPoints
        data[index].number = { ...data[index].number, ...numberObj }
        if (content === "extractedEvents") {
            setExtractedEventsState(data)
        } else if (content === "importatnNoEventArticles") {
            setImportantNoEventArticles(data)
        } else if (content === "shortlistedEvents") {
            setShortlistedEventsState(data)
        } else if (content === "publishedEvents") {
            setPublishedEventsState(data)
        }
        setStateUpdated(!stateUpdated)
    }
    const handleStateChange = (index, event) => {
        var changedState = event
        var changeDistrict = districtCategories[changedState][0].value
        var data = mainDataPoints
        data[index].state = changedState
        data[index].district = changeDistrict
        data[index].sub_district = ""
        if (content === "extractedEvents") {
            setExtractedEventsState(data)
        } else if (content === "importatnNoEventArticles") {
            setImportantNoEventArticles(data)
        } else if (content === "shortlistedEvents") {
            setShortlistedEventsState(data)
        } else if (content === "publishedEvents") {
            setPublishedEventsState(data)
        }
        setStateUpdated(!stateUpdated)
    }

    const handleSubdistrictChange = (index, event) => {
        var data = mainDataPoints
        data[index].sub_district = event
        if (content === "extractedEvents") {
            setExtractedEventsState(data)
        } else if (content === "importatnNoEventArticles") {
            setImportantNoEventArticles(data)
        } else if (content === "shortlistedEvents") {
            setShortlistedEventsState(data)
        } else if (content === "publishedEvents") {
            setPublishedEventsState(data)
        }
        setStateUpdated(!stateUpdated)
    }

    const handleMessageChange = (index, event) => {
        // var changedMessage = event.target.value
        // var data = mainDataPoints
        // data[index].message = changedMessage
        // if (content === "extractedEvents") {
        //     setExtractedEventsState(data)
        // } else if (content === "importatnNoEventArticles") {
        //     setImportantNoEventArticles(data)
        // } else if (content === "shortlistedEvents") {
        //     setShortlistedEventsState(data)
        // } else if (content === "publishedEvents") {
        //     setPublishedEventsState(data)
        // }
        // setStateUpdated(!stateUpdated)
    }

    function onPublishSetIndex (data, type) {
        // if (data.sub_district) {
        //     const subdistricts = subDistLookup[data.district !== (null || "") ? data.district.toUpperCase() : ""] !== undefined ? subDistLookup[data.district !== (null || "") ? data.district.toUpperCase() : ""] : []
        //     const selectedSubdistrict = subdistricts.flatMap(category => category.options)
        //                      .find(option => option.subdistrict_name === data.sub_district);
        //     if (selectedSubdistrict) {
        //         data.local_body_code = selectedSubdistrict.local_body_code
        //     }
        // }
        const category = diseaseCategories.find(item => item.items.find(item => item.value === data.disease))?.label;
        data.disease_macro_category = category;
        setPublishEventIndexState([data, type])
    }
    function onShortistSetIndex (data, type) {
        const category = diseaseCategories.find(item => item.items.find(item => item.value === data.disease))?.label;
        data.disease_macro_category = category;
        setShortlistEventIndexState([data, type])
    }

    function onRejectSetIndex (data, type) {
        setRejectEventIndexState([data, type])
    }

    function openPublishDialog (flag, data) {
        setRepublishDialogBoxState(flag)
        setSelectedEventData(data)
    }

    return (
        <div>
            {
                mainDataPoints.length === 0 && selectedEventSubType.count !== 0 && content !== "main" &&
                Array(5).fill("").map((skeleton, idx) =>
                    <CustomSkeleton key={idx} width="80%" background={selectedEventSubType.type === "Rejected" ? "#f8d5d5" : "fff"} type1={skeletonData.type1} type6={skeletonData.type6} type3={skeletonData.type3} type4={skeletonData.type4} type5={skeletonData.type5} hasOutOfCount={true} />
                )
            }
            {
                mainDataPoints.length === 0 && content !== "main" && selectedEventSubType.count === 0 &&
                <CssTypography>No Events </CssTypography>
            }
            {
                mainDataPoints.length > 0 &&
                <div>
                    {
                        mainDataPoints.map((mainDataPoint, idx) =>
                            <SingleCard mainCardFlag={true} key={mainDataPoint.id} mainDataPoint={mainDataPoint} idx={idx} content={content} districtCategories={districtCategories} subdistrictCategories={subdistrictCategories} checkPublished={checkPublished} stateUserFlag={stateUserFlag} stateUpdated={stateUpdated} aiTranslationFlag={aiTranslationFlag} page={page} countEvents={countEvents} selectedEventSubType={selectedEventSubType} buttonFreeze={buttonFreeze} isValidEventData={isValidEventData} diseaseCategories={diseaseCategories} handleDateChange={handleDateChange} handleFinalDiseaseChange={handleFinalDiseaseChange} handleNumberChange={handleNumberChange} handleStateChange={handleStateChange} handleDistrictChange={handleDistrictChange} handleSubdistrictChange={handleSubdistrictChange} handleMessageChange={handleMessageChange} capitalizeFirstLetter={capitalizeFirstLetter} onRejectSetIndex={onRejectSetIndex} onShortistSetIndex={onShortistSetIndex} onPublishSetIndex={onPublishSetIndex} openPublishDialog={openPublishDialog} openSummaryDialog={openSummaryDialog} openRelatedEventsDialog={openRelatedEventsDialog} sendClusterForApproval={sendClusterForApproval} approveActionForCluster={approveActionForCluster} sendEmailForCluster={sendEmailForCluster} openPDFDialog={openPDFDialog} unRejectCluster={unRejectCluster} setIsValidEventData={setIsValidEventData} updateEventAsChecked={updateEventAsChecked} setShowRejectSourceDialog={setShowRejectSourceDialog} />
                        )
                    }
                </div>
            }
        </div>
    );
}

export default Cards;
