import * as React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Divider, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import CustomSeeDetailsButton from '../Shared/CustomSeeDetailsButton/CustomSeeDetailsButton';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import "./SummaryDialog.css";
import moment from 'moment';

const StyledTypography = withStyles({
    root: {
        fontWeight: "500",
        fontSize: "20px",
        color: "#413F40 !important"
    }
})(Typography);

const StylesTypeGraphyTwo = withStyles({
    root: {
        fontWeight: "600",
        fontSize: "17px",
        color: "#413F40 !important"
    }
})(Typography);

const StylesTypeGraphyThree = withStyles({
    root: {
        fontWeight: "500",
        fontSize: "18px",
        color: "#008BCA !important",
    }
})(Typography);

const StylesTypeGraphyFour = withStyles({
    root: {
        fontWeight: "500",
        fontSize: "18px",
        color: "#78828C !important",
    }
})(Typography);

const SummaryDialog = ({ dialogFlag, summaryData, isFetching, closeDialog = () => { }, openSummaryDialog = () => { } }) => {
    const [eventTimelineExpandFlag, setEventTimelineExpandFlag] = React.useState(false);
    const [outbreakReportExpandFlag, setOutbreakReportExpandFlag] = React.useState(false);
    const [preliminaryreportExpandFlag, setPreliminaryreportExpandFlag] = React.useState(false);
    const [rotation, setRotation] = React.useState(0);

    const closeDialogFun = () => {
        closeDialog(false)
    }

    const rotateReloadIcon = () => {
        setRotation(rotation + 360);
    };

    const rotateElementStyle = {
        transform: `rotate(${rotation}deg)`,
        transition: 'transform 0.8s ease',
        color: "#413F40",
    };

    return (
        <div>
            <Dialog
                open={dialogFlag}
                onClose={closeDialogFun}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'xl'}
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid container>
                        <Grid item lg={11} md={11} sm={11} style={{ display: "flex", alignItems: "center", color: "#000", fontSize: "x-large", fontWeight: "700" }}>
                        {summaryData.title}
                        </Grid>
                        <Grid item lg={1} md={1} sm={1} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                            <button className='custom-close-button' onClick={() => closeDialogFun()}>
                                <Close />
                            </button>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item lg={12} md={12} sm={12}>
                            <Timeline
                                sx={{
                                    [`& .${TimelineOppositeContent.root}`]: {
                                        flex: 0.2,
                                    },
                                }}
                            >
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot style={{ background: "#008BCA" }}>1</TimelineDot>
                                        <TimelineConnector style={{ background: summaryData.investigation_required != null ? "#008BCA" : "#78828C" }} />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Grid container className='status-bar-label'>
                                            <Grid item lg={12} md={12} sm={12} style={{ display: "flex", alignItems: "center", padding: "1rem", justifyContent: "space-between" }}>
                                                <StyledTypography>Event Timeline</StyledTypography>
                                                <CustomSeeDetailsButton theme={"primary"} expandFlag={eventTimelineExpandFlag} setExpandFlag={setEventTimelineExpandFlag} />
                                            </Grid>
                                            {eventTimelineExpandFlag && <Grid item lg={12} md={12} sm={12}>
                                                <Grid container className='dialog-table-header' style={{ padding: "1rem", marginTop: "1rem" }}>
                                                    <Grid item lg={10} md={10} sm={10}>
                                                        Action
                                                    </Grid>
                                                    <Grid item lg={2} md={2} sm={2} style={{ display: "flex", justifyContent: "center" }}>
                                                        Date
                                                    </Grid>
                                                    {/* <Grid lg={2} md={2} sm={2}>
                                                        Action
                                                    </Grid> */}
                                                </Grid>
                                                {/* <Divider style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />
                                                <Grid container style={{ padding: "1rem", fontSize: "17px" }}>
                                                    <Grid item lg={10} md={10} sm={10}>
                                                        <StylesTypeGraphyTwo>Event Date</StylesTypeGraphyTwo>
                                                    </Grid>
                                                    <Grid item lg={2} md={2} sm={2} style={{ display: "flex", justifyContent: "center" }}>
                                                        <StylesTypeGraphyThree>{moment(summaryData.event_timeline.event_date).format('DD MMM YYYY')}</StylesTypeGraphyThree>
                                                    </Grid>
                                                </Grid> */}
                                                <Divider style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />
                                                <Grid container style={{ padding: "1rem", fontSize: "17px" }}>
                                                    <Grid item lg={10} md={10} sm={10} style={{ fontWeight: 500 }}>
                                                        <StylesTypeGraphyTwo>Extracted Date</StylesTypeGraphyTwo>
                                                    </Grid>
                                                    <Grid item lg={2} md={2} sm={2} style={{ display: "flex", justifyContent: "center" }}>
                                                        <div style={{ fontSize: "14px", fontWeight: "600", color: "#413F40" }} >{moment(summaryData.event_timeline.extracted_date).format('DD MMM YYYY')}</div>
                                                    </Grid>
                                                </Grid>
                                                <Divider style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />
                                                <Grid container style={{ padding: "1rem", fontSize: "17px" }}>
                                                    <Grid item lg={10} md={10} sm={10} style={{ fontWeight: 500 }}>
                                                        <StylesTypeGraphyTwo>Shortlist Date</StylesTypeGraphyTwo>
                                                    </Grid>
                                                    <Grid item lg={2} md={2} sm={2} style={{ display: "flex", justifyContent: "center" }}>
                                                        <div style={{ fontSize: "14px", fontWeight: "600", color: "#413F40" }} >{moment(summaryData.event_timeline.shortlist_date).format('DD MMM YYYY')}</div>
                                                    </Grid>
                                                </Grid>
                                                <Divider style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />
                                                <Grid container style={{ padding: "1rem", fontSize: "17px" }}>
                                                    <Grid item lg={10} md={10} sm={10} style={{ fontWeight: 500 }}>
                                                        <StylesTypeGraphyTwo>Publish Date</StylesTypeGraphyTwo>
                                                    </Grid>
                                                    <Grid item lg={2} md={2} sm={2} style={{ display: "flex", justifyContent: "center" }}>
                                                        <div style={{ fontSize: "14px", fontWeight: "600", color: "#413F40" }} >{moment(summaryData.event_timeline.published_date).format('DD MMM YYYY')}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>}
                                        </Grid>
                                    </TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot style={{ background: summaryData.investigation_required != null ? "#008BCA" : "#78828C" }}>2</TimelineDot>
                                        {summaryData.investigation_required !== null && summaryData.investigation_required !== "No" && <TimelineConnector style={{ background: summaryData.outbreak_status === "Completed" ? "#008BCA" : "#bdbdbd" }} />}
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        {summaryData.investigation_required != null && <Grid container className='status-bar-label' style={{ padding: "1rem" }}>
                                            <Grid item lg={12} md={12} sm={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <StyledTypography>Investigation Required</StyledTypography>
                                                <StylesTypeGraphyThree style={{ marginRight: "3rem" }}>{summaryData.investigation_required}</StylesTypeGraphyThree>
                                            </Grid>
                                        </Grid>}
                                        {summaryData.investigation_required == null && <Grid container className='status-bar-label-disable' style={{ padding: "1rem" }}>
                                            <Grid item lg={12} md={12} sm={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <StyledTypography style={{ display: "flex", alignItems: "center" }}>Investigation Required
                                                    <div style={{ display: "flex", alignItems: "center", cursor: "pointer", marginLeft: "5px" }} >
                                                        <Tooltip title="Refresh to see updated status" placement="top" arrow>
                                                            <RotateRightIcon style={rotateElementStyle} className='summary-dialog-reload-icon' onClick={() => {
                                                                rotateReloadIcon()
                                                                openSummaryDialog(true, summaryData.id)
}
                                                            } />
                                                        </Tooltip>
                                                    </div>
                                                </StyledTypography>
                                                <StylesTypeGraphyFour style={{ marginRight: "1rem" }}>{isFetching ? "Awaiting Response" : "No response yet"}</StylesTypeGraphyFour>
                                            </Grid>
                                        </Grid>}
                                    </TimelineContent>
                                </TimelineItem>
                                {summaryData.investigation_required !== null && summaryData.investigation_required !== "No" && <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot style={{ background: summaryData.outbreak_status === "Completed" ? "#008BCA" : "#bdbdbd" }}>3</TimelineDot>
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        {summaryData.outbreak_reported_date != null && <Grid container className='status-bar-label' style={{ padding: "1rem" }}>
                                            <Grid item lg={12} md={12} sm={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                    <StyledTypography>Outbreak Report {summaryData.outbreak_status === "Ongoing" ? "(Ongoing)" : ""}</StyledTypography>
                                                    {summaryData.outbreak_status === "Ongoing" &&
                                                        <Tooltip title="Refresh to see updated status" placement="top" arrow>
                                                            <RotateRightIcon style={rotateElementStyle} className='summary-dialog-reload-icon' onClick={() => {
                                                                rotateReloadIcon()
                                                                openSummaryDialog(true, summaryData.id)
}
                                                            } />
                                                        </Tooltip>
                                                        }
                                                </div>
                                                <CustomSeeDetailsButton theme={"primary"} expandFlag={outbreakReportExpandFlag} setExpandFlag={setOutbreakReportExpandFlag} />
                                            </Grid>
                                            {outbreakReportExpandFlag && <div style={{ width: "100%", marginTop: "12px" }}>
                                                <Divider style={{ marginTop: "0.7rem", marginBottom: "0.7rem", backgroundColor: "#85CDD0" }} />
                                                <Grid container>
                                                    <Grid item lg={12} md={12} sm={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <StylesTypeGraphyTwo>Preliminary Investigation Report</StylesTypeGraphyTwo>
                                                        <CustomSeeDetailsButton theme={"primary"} expandFlag={preliminaryreportExpandFlag} setExpandFlag={setPreliminaryreportExpandFlag} />
                                                    </Grid>
                                                    {preliminaryreportExpandFlag && <Grid item lg={12} md={12} sm={12}>
                                                        <StylesTypeGraphyThree>{summaryData.preliminary_investigation || "-"}</StylesTypeGraphyThree>
                                                        </Grid>}
                                                </Grid>
                                                <Divider style={{ marginTop: "0.7rem", marginBottom: "0.7rem", backgroundColor: "#85CDD0" }} />
                                                <Grid container>
                                                    <Grid item lg={4} md={4} sm={4}>
                                                        <StylesTypeGraphyTwo>Outbreak Reported Date</StylesTypeGraphyTwo>
                                                        <StylesTypeGraphyThree>{summaryData.outbreak_reported_date}</StylesTypeGraphyThree>
                                                    </Grid>
                                                    <Grid item lg={4} md={4} sm={4}>
                                                        <StylesTypeGraphyTwo>Outbreak Start Date</StylesTypeGraphyTwo>
                                                        <StylesTypeGraphyThree>{summaryData.outbreak_start_date}</StylesTypeGraphyThree>
                                                    </Grid>
                                                    <Grid item lg={4} md={4} sm={4}>
                                                        <StylesTypeGraphyTwo>Number of Cases/Deaths</StylesTypeGraphyTwo>
                                                        <StylesTypeGraphyThree>{summaryData.cases}/{summaryData.deaths}</StylesTypeGraphyThree>
                                                    </Grid>
                                                </Grid>
                                                <Divider style={{ marginTop: "0.7rem", marginBottom: "0.7rem", backgroundColor: "#85CDD0" }} />
                                                <Grid container>
                                                    <Grid item lg={4} md={4} sm={4}>
                                                        <StylesTypeGraphyTwo>Final Health condition Name</StylesTypeGraphyTwo>
                                                        <StylesTypeGraphyThree>{summaryData.final_health_condition_name}</StylesTypeGraphyThree>
                                                    </Grid>
                                                    <Grid item lg={4} md={4} sm={4}>
                                                        <StylesTypeGraphyTwo>SSO Verified Status</StylesTypeGraphyTwo>
                                                        <StylesTypeGraphyThree>{summaryData.sso_verified_status}</StylesTypeGraphyThree>
                                                    </Grid>
                                                    <Grid item lg={4} md={4} sm={4}>
                                                        <StylesTypeGraphyTwo>SSO Verified Date</StylesTypeGraphyTwo>
                                                        <StylesTypeGraphyThree>{summaryData.sso_verified_date || "-"}</StylesTypeGraphyThree>
                                                    </Grid>
                                                </Grid>
                                            </div>}
                                        </Grid>}
                                        {summaryData.outbreak_reported_date == null && <Grid container className='status-bar-label-disable' style={{ padding: "1rem" }}>
                                            <Grid item lg={12} md={12} sm={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <StyledTypography style={{ display: "flex", alignItems: "center" }}>Outbreak Report
                                                    {/* <img className='summary-dialog-reload-icon' src={RefreshIcon} style={{marginLeft: "10px", height: "1.5rem", cursor: "pointer"}} onClick={() => openSummaryDialog(true, summaryData, true)}/> */}
                                                </StyledTypography>
                                                <StylesTypeGraphyFour style={{ marginRight: "1rem" }}>{isFetching ? "Awaiting Response" : "No response yet"}</StylesTypeGraphyFour>
                                            </Grid>
                                        </Grid>}
                                    </TimelineContent>
                                </TimelineItem>}
                            </Timeline>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>
        </div>
    );
}

export default SummaryDialog;
