import React from 'react'
import JoyRideBox from '../../components/Onboarding/components/JoyRideBox'
import filterIcon from '../../resources/lottieFilterIcon.json'
import languageIcon from '../../resources/lottieLanguageIcon.json'
import printIcon from '../../resources/printGif.gif'
import clusterIcon from '../../resources/lottieClusterIcon.json'
import editIcon from '../../resources/lottieEditIcon.json'
import shortlistIcon from '../../resources/shortlistGif.gif'
import publishIcon from '../../resources/publishGif.gif'
import statusIcon from '../../resources/statusGif.gif'
import reviewIcon from '../../resources/reviewGif.gif'
import helpIcon from '../../resources/helpGif.gif'
import { onboardingSelectorIds } from './idSelector'

export const joyrideTotalSteps = 11;

export const TutorialSteps = [
    {
      content: (
        <JoyRideBox iconSize="30px" lottieIcon={filterIcon} currIdx={0} totalSteps={joyrideTotalSteps} heading="Filter" subHeading="Use the Date & Time, Location, Disease name etc. details to filter out specific Unsual health events to make your scanning more focused and efficient." />
      ),
      placement: 'top',
      disableBeacon: true,
      disableOverlayClose: true,
      target: '#' + onboardingSelectorIds.filter,
    },
    {
      content: (
        <JoyRideBox iconSize="30px" lottieIcon={languageIcon} currIdx={1} totalSteps={joyrideTotalSteps} heading="Translate all articles" subHeading="‘AI translated article’ can be switched off, to view the news in its original language; by default, all articles are translated to English." />
      ),
      placement: 'left',
      disableOverlayClose: true,
      target: '#' + onboardingSelectorIds.aiTranslation,
    },
    {
      content: (
        <JoyRideBox iconSize="32px" icon={reviewIcon} currIdx={2} totalSteps={joyrideTotalSteps} heading="Review Link" subHeading="This link is the main source of the news- to verify and confirm details from" />
      ),
      placement: 'top',
      disableOverlayClose: true,
      target: '#' + onboardingSelectorIds.singleCard,
    },
    {
      content: (
        <JoyRideBox iconSize="30px" lottieIcon={editIcon} currIdx={3} totalSteps={joyrideTotalSteps} heading="Update Details" subHeading="These values should be modified- to match correctly with the article link" />
      ),
      placement: 'top',
      disableOverlayClose: true,
      target: '#' + onboardingSelectorIds.singleCard,
    },
    {
      content: (
        <JoyRideBox iconSize="32px" lottieIcon={clusterIcon} currIdx={4} totalSteps={joyrideTotalSteps} heading="Cluster Events" subHeading="Click here to view other similar articles of the same event" />
      ),
      placement: 'top',
      disableOverlayClose: true,
      target: '#' + onboardingSelectorIds.singleCard,
    },
    {
      content: (
        <JoyRideBox iconSize="30px" icon={shortlistIcon} currIdx={5} totalSteps={joyrideTotalSteps} heading="Reject / Shortlist" subHeading="Shortlist the Health event or reject it in case it gives false or incorrect or unimportant status of the specific media news! " text="Tip: AI does not read the article, instead crawls through the web descriptions and html titles- which can lead to incorrect summaries sometimes- hence we promote ‘reviewing links’ to train the AI model better." />
      ),
      placement: 'bottom',
      disableOverlayClose: true,
      target: '#' + onboardingSelectorIds.singleCard,
    },
    {
      content: (
        <JoyRideBox iconSize="30px" icon={shortlistIcon} currIdx={6} totalSteps={joyrideTotalSteps} heading="Shortlisted tab" subHeading="Receive approvals on your actions from your the epidemologist or manager. The dropdown tabs within organise- ‘shortlisted’, ‘approved’ and events ‘pending approvals’ help with better overview." />
      ),
      placement: 'bottom',
      // disableBeacon: true,
      disableOverlayClose: true,
      // hideCloseButton: true,
      // hideFooter: true,
      // spotlightClicks: true,
      // target: '#shortlist-tab-joyride-container',
      target: '#' + onboardingSelectorIds.shortListTab,
    },
    {
      content: (
        <JoyRideBox iconSize="30px" icon={publishIcon} currIdx={7} totalSteps={joyrideTotalSteps} heading="Publish tab" subHeading="Track the status of event alerts previously approved and raised. The dropdown tabs within organise- ‘follow-ups’ and ‘health information’ help with better overview. " text="Tip: You can also print her report in the template she uses using the ‘Print PDF’ option"/>
      ),
      placement: 'top',
      disableOverlayClose: true,
      target: '#' + onboardingSelectorIds.publishTab,
    },
    {
      content: (
        <JoyRideBox iconSize="28px" icon={printIcon} currIdx={8} totalSteps={joyrideTotalSteps} heading="Print PDF" subHeading="To keep published events documented as proof- use this feature to produce a well-organised pdf swiftly." />
      ),
      placement: 'top',
      disableOverlayClose: true,
      target: '#' + onboardingSelectorIds.printPdf,
    },
    {
      content: (
        <JoyRideBox iconSize="30px" icon={statusIcon} currIdx={9} totalSteps={joyrideTotalSteps} heading="View Status Details" subHeading="View the status of a Published event using the Status Tab." text="Here you can see what action has been taken by the District Surveillance officer on the Published event." />
      ),
      placement: 'top',
      disableOverlayClose: true,
      target: '#' + onboardingSelectorIds.outbreakStatus,
    },
    {
      content: (
        <JoyRideBox iconSize="30px" icon={helpIcon} currIdx={10} totalSteps={joyrideTotalSteps} heading="Help" subHeading="This is where you can access the tutorials, raise complaints and share feedback for any issues- that you are facing with the product." />
      ),
      placement: 'left',
      disableOverlayClose: true,
      target: '#' + onboardingSelectorIds.helpButton,
    }
  ]
