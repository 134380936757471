import React from 'react';
import "./CustomButtonWithIcon.css";
import { customThemes } from '../../../constants/app-constants';
import CustomLoader from '../CustomLoader/CustomLoader'
import { Tooltip } from '@material-ui/core';

const CustomButtonWithIcon = ({ label, theme, loader, icon, disabled, width, height, tooltipText, clicked = () => { } }) => {
    const themedButton = (function () {
        if (theme === customThemes.primary) {
            return (<button className='primary-custom-button-with-icon' disabled={disabled} style={{ cursor: disabled ? "not-allowed" : "pointer", width: width || "100%" }} onClick={() => clicked()}>
                {loader && <CustomLoader style={{ height: height || "1rem " }} />}
                {icon && <img src={icon} alt={label} style={{ height: height || "1rem", padding: "0.3rem" }}/>}
                {label}
            </button>)
        } else if (theme === customThemes.secondary) {
            return (<button className='secondary-custom-button-with-icon' disabled={disabled} style={{ cursor: disabled ? "not-allowed" : "pointer", width: width || "100%" }} onClick={() => clicked()}>
                {loader && <CustomLoader style={{ height: height || "1rem " }} />}
                {icon && <img src={icon} alt={label} style={{ height: height || "1rem", padding: "0.3rem" }}/>}
                {label}
            </button>)
        } else if (theme === customThemes.grey) {
            return (<button className='grey-custom-button-with-icon' disabled={disabled} style={{ cursor: disabled ? "not-allowed" : "pointer", width: width || "100%" }} onClick={() => clicked()}>
                {loader && <CustomLoader style={{ height: height || "1rem " }} />}
                {icon && <img src={icon} alt={label} style={{ height: height || "1rem", padding: "0.3rem" }}/>}
                {label}
            </button>)
        } else {
            return (<button className='tertiary-custom-button-with-icon' disabled={disabled} style={{ cursor: disabled ? "not-allowed" : "pointer", width: width || "100%" }} onClick={() => clicked()}>
                {loader && <CustomLoader style={{ height: height || "1rem " }} />}
                {icon && <img src={icon} alt={label} style={{ height: height || "1rem", padding: "0.3rem" }}/>}
                {label}
            </button>)
        }
    })();

    return (
        <>
            {tooltipText && <Tooltip title={tooltipText} placement="top" arrow>
                <div>{themedButton}</div>
            </Tooltip>}
            {!tooltipText && <div>{themedButton}</div>}
        </>
    )
}

export default CustomButtonWithIcon;
