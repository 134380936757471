import * as React from 'react';
import { InputSwitch } from "primereact/inputswitch";
import "./CustomToggleButton.css";

const CustomToggleButton = ({ toggleValue, setToggleValue = () => { } }) => {
    const [value, setValue] = React.useState(toggleValue);

    const handleChange = (event) => {
        setValue(event.value)
        setToggleValue(event.value)
    }
    return (
        <InputSwitch
            className='toggle-button'
            checked={value}
            onChange={handleChange}
        />
    )
}

export default CustomToggleButton;
