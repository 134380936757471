import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import { eventTypeMap } from '../../constants/app-constants';
import CustomSearchButton from '../../components/Shared/CustomSearchButton/CustomSearchButton';
import filterIcon from "../../resources/filterIcon.png";
import locationIcon from "../../resources/locationIcon.png";
import diseaseIcon from "../../resources/diseaseIcon.png";
import aiTranslatedIcon from "../../resources/aiTranslatedIcon.png";
import newspaperIcon from "../../resources/newspaperIcon.png";
import CustomToggleButton from '../../components/Shared/CustomToggleButton/CustomToggleButton';
import CustomPaginator from '../../components/Shared/CustomPaginator/CustomPaginator';
import CustomEventsCounter from '../../components/Shared/CustomEventsCounter/CustomEventsCounter';
import SearchIcon from '@material-ui/icons/Search';
import CustomCollapseButton from '../Shared/CustomCollapseButton/CustomCollapseButton';
import "./FilterSection.css";
import CustomSubTabs from '../Shared/CustomSubTabs/CustomSubTabs';
import CustomMultiSelect from '../Shared/CustomMultiSelect/CustomMultiSelect';
import CustomGroupMultiSelect from '../Shared/CustomGroupMultiSelect/CustomGroupMultiSelect';
import moment from "moment";
import CustomIconButton from '../Shared/CustomIconButton/CustomIconButton';
import CustomSelect from '../Shared/CustomSelect/CustomSelect';
import CustomDateTimePicker from '../Shared/CustomDateTimePicker/CustomDateTimePicker';
import CustomSubTabsTwo from '../Shared/CustomSubTabsTwo/CustomSubTabsTwo';
import CustomButtonWithIconTwo from '../Shared/CustomButtonWithIconTwo/CustomButtonWithIcon';

const StyledTypography = withStyles({
    root: {
        fontWeight: "700",
        fontSize: "16px",
        color: "#414C5B !important",
        marginBottom: "5px"
    }
})(Typography);

const StyledTypographyTwo = withStyles({
    root: {
        fontWeight: "700",
        fontSize: "18px",
        color: "#989898 !important",
    }
})(Typography);

const StyledTypographyThree = withStyles({
    root: {
        fontWeight: "400",
        fontSize: "16px",
        color: "#989898 !important",
    }
})(Typography);

const FilterSection = ({ fromDateFilter, toDateFilter, selectedDiseaseCategory, diseaseCategories, selectedStateCategory, stateCategories, selectedDistrictCategory, districtCategories, selectedLanguage, languageList, sourceCategories, selectedSourceCategory, content, countEvents, noOfPages, page, aiTranslationFlag, renderCards, collapseFlag, eventSubTypes, selectedEventSubType, sortTypeCategories, selectedSortType, totalEventsCount, showLoaderForTotalCount, stateUserFlag, handleFromDateChange = () => { }, handleToDateChange = () => { }, handleDiseaseCategoryChange = () => { }, handleStateCategoryChange = () => { }, handleDistrictCategoryChange = () => { }, handleLanguageChange = () => { }, handleSourceChange = () => { }, fetchEvents = () => { }, openCustomPublishDialog = () => { }, handlePageChange = () => { }, setAiTranslationFlag = () => { }, setCollapseFlag = () => { }, setSelectedEventSubType = () => { }, downloadReport = () => { }, setSelectedSortType = () => { }, setShowRejectedSourceList = () => { } }) => {
    function getAverageCount () {
        const lambda = 35000 * ((new Date(toDateFilter).getTime() - new Date(fromDateFilter).getTime()) / (1000 * 3600 * 24));
        return "AI tool has reviewed " + ((Math.random() * 1000) + lambda).toFixed(0) + " articles for given date range"
    }
    return (
        <Grid container className='filter-main-container'>
            <Grid item lg={11} md={11} sm={11} style={{ display: "flex", alignItems: "center", justifyContent: "start", cursor: "pointer" }} onClick={() => setCollapseFlag(false)}>
                <Typography
                    style={{
                        fontSize: "30px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "36.31px",
                        textAlign: "left",
                        marginTop: "15px",
                        marginBottom: "10px",
                        marginLeft: "25px"
                    }}
                >
                    <img src={filterIcon} style={{ marginRight: "1rem", height: "1.3rem" }} alt="filter-logo" />Filters</Typography>
                {collapseFlag && <Typography
                    style={{
                        fontSize: "30px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "36.31px",
                        textAlign: "left",
                        marginTop: "15px",
                        marginBottom: "10px",
                        marginLeft: "5px"
                    }}
                >/</Typography>}
                {collapseFlag && <div style={{
                    marginTop: "20px",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px"
                }}>
                    <StyledTypographyTwo>Date:</StyledTypographyTwo>
                    <StyledTypographyThree style={{ marginLeft: "5px" }}>{moment(fromDateFilter).format("MMM DD, yyyy")}</StyledTypographyThree>
                    <StyledTypographyThree style={{ marginLeft: "5px", marginRight: "5px" }}> - {moment(toDateFilter).format("MMM DD, yyyy")}</StyledTypographyThree>
                    <StyledTypographyTwo>| Time:</StyledTypographyTwo>
                    <StyledTypographyThree style={{ marginLeft: "5px" }}>{moment(fromDateFilter).format("hh:mm a")}</StyledTypographyThree>
                    <StyledTypographyThree style={{ marginLeft: "5px" }}> - {moment(toDateFilter).format("hh:mm a")}</StyledTypographyThree>
                </div>}
            </Grid>
            <Grid item lg={1} md={1} sm={1} style={{ display: "flex", alignItems: "center", justifyContent: "end", paddingRight: "1rem", cursor: "pointer" }} onClick={() => setCollapseFlag(!collapseFlag)}>
                <CustomCollapseButton collapseFlag={collapseFlag} setCollapseFlag={setCollapseFlag} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} className="filter-section-container" >
                {!collapseFlag && <Grid container spacing={3} >
                    <Grid item lg={3} md={6} sm={6} >
                        <Grid container>
                            <Grid item lg={12} md={12} sm={12} style={{ position: "relative", top: 0, width: "100%" }}>
                                <StyledTypography>Date From</StyledTypography>
                                <CustomDateTimePicker date={fromDateFilter} setDate={handleFromDateChange} maxDate={toDateFilter}/>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "10px", position: "relative", top: 0 }}>
                                <StyledTypography>Date To</StyledTypography>
                                <CustomDateTimePicker date={toDateFilter} setDate={handleToDateChange} minDate={fromDateFilter} maxDate={new Date()} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={3} md={6} sm={6} >
                        <Grid container>
                            <Grid item lg={12} md={12} sm={12}>
                                <StyledTypography>State</StyledTypography>
                                <CustomMultiSelect placeholder={"Select State"} icon={locationIcon} optionsArr={stateCategories} selectedOptions={selectedStateCategory} disabled={stateUserFlag} setSelectedOptions={handleStateCategoryChange} />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "10px" }}>
                                <StyledTypography>District</StyledTypography>
                                <CustomGroupMultiSelect placeholder={"Select District"} icon={locationIcon} optionsArr={districtCategories} selectedOptions={selectedDistrictCategory} selectAllPlaceholder={"Districts"} setSelectedOptions={handleDistrictCategoryChange} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={3} md={6} sm={6} >
                        <Grid container>
                            <Grid item lg={12} md={12} sm={12}>
                                <StyledTypography>Disease Type</StyledTypography>
                                <CustomGroupMultiSelect placeholder={"Select Disease"} icon={diseaseIcon} optionsArr={diseaseCategories} selectedOptions={selectedDiseaseCategory} selectAllPlaceholder={"Diseases"} setSelectedOptions={handleDiseaseCategoryChange} />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "10px" }}>
                                <StyledTypography>Source Type</StyledTypography>
                                <CustomGroupMultiSelect placeholder={"Select Source"} icon={newspaperIcon} optionsArr={sourceCategories} selectedOptions={selectedSourceCategory} selectAllPlaceholder={"Sources"} setSelectedOptions={handleSourceChange} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={3} md={6} sm={6} >
                        <Grid container >
                            <Grid item lg={12} md={12} sm={12}>
                                <StyledTypography>Language</StyledTypography>
                                <CustomMultiSelect placeholder={"Select Language"} icon={aiTranslatedIcon} optionsArr={languageList} selectedOptions={selectedLanguage} setSelectedOptions={handleLanguageChange} />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "30px", display: "flex", alignSelf: "flex-end" }}>
                                <CustomSearchButton icon={<SearchIcon />} text={"Save All Filter Results"} search={fetchEvents} setCollapseFlag={setCollapseFlag} collapseFlag={collapseFlag} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}
                <Divider className={ !collapseFlag ? 'filter-devider-1' : '' } style={{ marginTop: "1rem", marginBottom: "1rem" }} />
                <Grid container className='filter-subtype-container' style={{ marginBottom: "1rem", display: "flex" }}>
                    <Grid item lg={7} md={8} sm={8} style={{ display: "flex", alignItems: "center" }}>
                        {eventSubTypes.length > 0 && content !== "publishedEvents" && <CustomSubTabs items={eventSubTypes} selectedItem={selectedEventSubType} showCountFlag={true} saveSelection={setSelectedEventSubType} />}
                        {eventSubTypes.length > 0 && content === "publishedEvents" && <CustomSubTabsTwo items={eventSubTypes} selectedItem={selectedEventSubType} showCountFlag={true} saveSelection={setSelectedEventSubType} />}
                    </Grid>
                    <Grid item lg={1} />
                    <Grid item lg={4} md={4} sm={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <div style={{ display: "flex", alignItems: "center" }} className='filter-ai-translation-container' >
                            <div style={{ marginRight: "1rem" }}>
                                <CustomButtonWithIconTwo fontSize="16.5px" preIcon={true} iconSize="17px" fontWeight="500" iconClass="pi-ban" label="Blocked Sources" color="#008BCA" padding="12px 16px" borderColor="#008BCA" background="#FFFFFD" onClick={() => setShowRejectedSourceList((prev) => ({ ...prev, showRejectedSourceList: true }))} />
                            </div>
                            <div className='report-download-box' >
                                <CustomIconButton icon={"pi-file-pdf"} isIconClass={true} rounded={false} tooltipText={"Download Report"} clicked={downloadReport} />
                            </div>
                            <div className='ai-translation' >
                                <div className='report-icon' >
                                    <CustomToggleButton toggleValue={aiTranslationFlag} setToggleValue={setAiTranslationFlag} />
                                </div>
                                <div className='text' >
                                    <h3 style={{ color: "#413F40", margin: 0 }}>AI Translated Articles</h3>
                                    <p style={{ color: "#78828C", margin: 0, fontSize: "x-small" }}>All articles are translated to English</p>
                                </div>
                            </div>
                        </div>
                            {/* <Grid item xl={2} lg={1} md={1} ></Grid> */}

                            {/* <Grid item xl={2} lg={2} md={2} sm={2} style={{ padding: "0 0.5rem" }}>
                                <CustomIconButton icon={"pi-file-pdf"} isIconClass={true} rounded={false} tooltipText={"Download Report"} clicked={downloadReport} />
                            </Grid>
                            <Grid item xl={7} lg={8} md={9} sm={10} style={{ display: "flex", alignItems: "center" }}>
                                {selectedLanguage !== "English" && <Grid container style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item xl={3} lg={3} md={3} sm={3} style={{ padding: "0 5px", }}>
                                        <CustomToggleButton toggleValue={aiTranslationFlag} setToggleValue={setAiTranslationFlag} />
                                    </Grid>
                                    <Grid item xl={9} lg={9} md={9} sm={9} style={{ paddingLeft: "10px" }}>
                                        <h3 style={{ color: "#413F40", margin: 0 }}>AI Translated Articles</h3>
                                        <p style={{ color: "#78828C", margin: 0, fontSize: "x-small" }}>All articles are translated to English</p>
                                    </Grid>
                                </Grid>}
                            </Grid> */}

                        {/* </Grid> */}
                    </Grid>
                </Grid>
                <Grid container className='filter-footer' >
                    <Grid item lg={3} md={3} sm={3} style={{ display: "flex", alignItems: "center" }}>
                        <CustomEventsCounter count={totalEventsCount} showLoader={showLoaderForTotalCount} eventType={eventTypeMap[content]} tootTipFlag={content === "extractedEvents"} tooltipText={getAverageCount()} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CustomPaginator totalPages={noOfPages} currentPageValue={page} handlePageChange={handlePageChange} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
                        <div className='filter-sort-select' style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center", width: "20rem", minWidth: "15rem", maxWidth: "20rem" }}>
                            <StyledTypography>Sort</StyledTypography>
                            <CustomSelect placeholder={"Select Sort"} options={sortTypeCategories} selectedOption={selectedSortType} setSelectedOption={setSelectedSortType}/>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FilterSection;
