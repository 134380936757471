/* eslint-disable no-unneeded-ternary */
/* eslint-disable brace-style */
/* eslint-disable prefer-const */
/* eslint-disable keyword-spacing */
/* eslint-disable dot-notation */
/* eslint-disable space-before-function-paren */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable quote-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React from 'react';
import './SourceRejection.css'
import RejectedSourcListsWrapper from './RejectedSourcListsWrapper';
import { useDispatch } from 'react-redux';
import { blockSource, getBlockedSources as getBlockedSourcesList, unblockSource } from '../../actions/global';
import CustomDialogBox from '../Shared/CustomDialogBox/CustomDialogBox';
import successImg from '../../resources/successLogo.png'
import undoImg from '../../resources/returnLogo.png'
import CustomButtonWithIconTwo from '../Shared/CustomButtonWithIconTwo/CustomButtonWithIcon';
import { Checkbox } from "primereact/checkbox";

const Tooltip = ({ checkbox }) => {
    const [checked, setChecked] = React.useState(checkbox)
    const handleCheckboxClick = (e) => {
        setChecked(e.checked)
        if (e.checked) {
            localStorage.setItem("YmxvY2stc291cmNlLWNoZWNrYm94LXRpY2s", true)
        }
    }
    if (checkbox) return <></>
    return <>
        <div className='source-rejection-tooltip-dialog'>
            <div style={{ background: "#FFFFFD", borderRadius: "8px", padding: "1rem 1.5rem" }} >
                <div style={{ color: "#413F40", fontSize: "16px", marginBottom: "5px" }}>Click here to un-block sources</div>
                <div style={{ display: "flex", columnGap: "10px", alignItems: "center" }}>
                    <div>
                        <Checkbox onChange={handleCheckboxClick} checked={checked}></Checkbox>
                    </div>
                    <div style={{ color: "#979797", fontSize: "12px" }}>Don't show again</div>
                </div>
            </div>
            <div style={{ width: "4rem", padding: "2px 0", background: "#FFFFFD" }}></div>
            <div>
                <CustomButtonWithIconTwo preIcon={true} iconSize="17px" iconClass="pi-ban" label="Blocked Sources" color="#414C5B" background="#D4EAF0" borderColor="#414C5B" />
            </div>
        </div>
    </>
}

const SourceRejection = ({ showDialog, aiTranslationFlag, token, setShowDialog = () => { }, fetchEvents = () => { } }) => {
    const dispatch = useDispatch();
    const [sourceRejectionScreen, setSourceRejectionScreen] = React.useState(1)
    const [blockedSource, setBlockedSources] = React.useState({
        isFetching: false,
        data: [],
    })
    const tooltipCheckbox = localStorage.getItem("YmxvY2stc291cmNlLWNoZWNrYm94LXRpY2s") !== null

    function handleRejectedSourcesListClose () {
        // api call
        setSourceRejectionScreen(1)
        setShowDialog(prev => ({
            ...prev,
            showRejectedSourceList: !prev.showRejectedSourceList,
            url: "",
            eventId: ""
        }))
    }

    function handlePostSourceRejectionClose () {
        // api call
        handleBlockClick(showDialog.url, showDialog.eventId)
        setSourceRejectionScreen(1)
        setShowDialog(prev => ({
            ...prev,
            showPostRejectionClick: false,
            showRejectedSourceList: false,
            url: "",
            eventId: ""
        }))
    }

    function getBlockedSources () {
        setBlockedSources(prev => ({ ...prev, isFetching: true }))
        dispatch(getBlockedSourcesList(token)).then((res) => {
            if (res.success) {
                const formattedArray = [];
                res.data.sources.forEach(item => {
                    const existingGroup = formattedArray.find(group => group.date === item.blocked_date);
                    if (existingGroup) {
                        existingGroup.items.push(item);
                    } else {
                        formattedArray.push({
                            date: item.blocked_date,
                            items: [item]
                        });
                    }
                });
                setBlockedSources({
                    isFetching: false,
                    data: formattedArray
                })
            }
        })
    }

    function handleBlockClick (url, eventId) {
        const jsonObj = {
            source_name: url,
            event_id: eventId
        }
        dispatch(blockSource(jsonObj, token)).then((res) => {
            if (res) {
                fetchEvents()
            }
        })
    }

    function handleUnblockClick (tempObj) {
        setShowDialog(prev => ({
            ...prev,
            url: tempObj.source_name
        }))
        dispatch(unblockSource(tempObj, token)).then((res) => {
            if (res) {
                setSourceRejectionScreen(2)
                fetchEvents()
            }
        })
    }

    function handleUndoAction () {
        setSourceRejectionScreen(1)
        setShowDialog(prev => ({
            ...prev,
            showPostRejectionClick: false,
            url: ""
        }))
    }

    React.useEffect(() => {
        if (showDialog.showPostRejectionClick) {
            const timeout = setTimeout(() => {
                setSourceRejectionScreen(2)
            }, 2000);
            const timeout2 = setTimeout(() => {
                // handleAction("custom url blocking", showDialog.url)
                handlePostSourceRejectionClose()
            }, 5000);
            return () => {
                clearTimeout(timeout)
                clearTimeout(timeout2)
                setSourceRejectionScreen(1)
            }
        }
        if (showDialog.showRejectedSourceList) {
            getBlockedSources()
        }
    }, [])

    React.useEffect(() => {
        if (showDialog.showRejectedSourceList && sourceRejectionScreen == 2) {
            const timeout = setTimeout(() => {
                handleRejectedSourcesListClose()
            }, 5000);
        }
    }, [sourceRejectionScreen])

    return <>
        {showDialog.showRejectedSourceList && sourceRejectionScreen === 1 && <RejectedSourcListsWrapper showDialog={showDialog.showRejectedSourceList} blockedSourceData={blockedSource} aiTranslationFlag={aiTranslationFlag} token={token} handleUnblockSource={handleUnblockClick} setShowDialog={handleRejectedSourcesListClose} />}
        {showDialog.showRejectedSourceList && sourceRejectionScreen === 2 && <CustomDialogBox showDialog={showDialog.showRejectedSourceList} headerIcon={successImg} headerText={showDialog.url + " has been un-blocked"} setShowDialog={handleRejectedSourcesListClose} />}
        {showDialog.showPostRejectionClick && sourceRejectionScreen === 1 && <CustomDialogBox showDialog={showDialog.showPostRejectionClick} headerIcon={successImg} headerText={showDialog.url} buttonIcon={undoImg} buttonText="Undo Action" setShowDialog={handlePostSourceRejectionClose} handleButtonAction={handleUndoAction} />}
        {showDialog.showPostRejectionClick && sourceRejectionScreen === 2 && <CustomDialogBox showDialog={showDialog.showPostRejectionClick} headerIcon={successImg} headerText={showDialog.url} buttonIcon={undoImg} footer={<Tooltip checkbox={tooltipCheckbox} />} buttonText="Undo Action" setShowDialog={handlePostSourceRejectionClose} handleButtonAction={handleUndoAction} />}
    </>
}

export default SourceRejection;
