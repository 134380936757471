import { Grid } from '@material-ui/core';
import React from 'react';
import dateIcon from '../../resources/dateIcon.png'
import diseaseIcon from '../../resources/diseaseIcon.png'
import locationIcon from '../../resources/locationIcon.png'
import deathIcon from '../../resources/deathIcon.png'
import vectorIcon from '../../resources/vectorIcon.png'
import sourceIcon from '../../resources/sourceIcon.png'
import moment from "moment";

const TextField = ({ text, icon, iconHeight, iconWidth }) => {
    return <>
        <div style={{ padding: "8px 10px", display: "flex", borderRadius: "4px", background: "#F3F7F9", alignItems: "center", columnGap: "10px" }}>
            <img style={{ height: iconHeight || "14px", width: iconWidth || "" }} src={icon} alt="icon" />
            <div style={{ fontSize: "12px", lineHeight: "18px", color: "#414C5B", fontWeight: "400" }} >{text} </div>
        </div>
    </>
}

const TextFieldWithHeader = ({ heading, text, icon, iconHeight, iconWidth }) => {
    const textFieldHeadigStyle = {
        fontSize: "12px",
        fontWeight: "700",
        lineHeight: "18px",
        color: "#414C5B",
        marginBottom: "0.4rem"
    }
    return <>
        <div style={textFieldHeadigStyle} >{heading}</div>
        <div>
            <TextField icon={icon} text={text} />
        </div>
    </>
}

const RejectedEventCard = ({ eventData, aiTranslationFlag, sourceName }) => {
    console.log(eventData);
    return <>
        <Grid container style={{ background: "#daecf2", borderRadius: "3.5px", boxShadow: "#B1AEAE 2px 2px 6px", padding: "1rem", marginBottom: "1rem" }}>
            <Grid item xl={12} lg={12} md={12} sm={12} >
                <div style={{ fontSize: "calc(1.2vw + 6px)", fontWeight: "700", color: "#000", marginBottom: "0.8rem" }}>
                    {aiTranslationFlag ? eventData.translated_title : eventData.title}
                </div>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12}>
                <Grid container spacing={2} >
                    <Grid item xl={4} lg={4} md={4} sm={4}>
                        <TextFieldWithHeader heading="Extracted Date" icon={dateIcon} text={moment(eventData.extracted_date).format("MMM DD, yyyy")} />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4}>
                        <TextFieldWithHeader heading="Disease" icon={diseaseIcon} text={eventData.disease} />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4}>
                        <TextFieldWithHeader heading="Sources" icon={sourceIcon} text={sourceName} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} >
                    <Grid item xl={3} lg={3} md={6} sm={6}>
                        <Grid container spacing={2} >
                            <Grid item xl={6} lg={6} md={6} sm={6} >
                                <TextFieldWithHeader heading="Cases" icon={vectorIcon} text={eventData.number.case_new !== 0 ? eventData.number.case_new : eventData.number.case_total} />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6}>
                                <TextFieldWithHeader heading="Death" icon={deathIcon} text={eventData.number.death_new !== 0 ? eventData.number.death_new : eventData.number.death_total} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6}>
                        <TextFieldWithHeader heading="Location: State" icon={locationIcon} text={eventData.state} />
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6}>
                        <TextFieldWithHeader heading="Location: District" icon={locationIcon} text={eventData.district} />
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6}>
                        <TextFieldWithHeader heading="Location: Sub-District/Corporation" icon={locationIcon} text={eventData.sub_district} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
}

export default RejectedEventCard;
