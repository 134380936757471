import * as React from 'react';
import { Dropdown } from 'primereact/dropdown';
import "./CustomSelectTwo.css";

const selectedOptionTemplate = (option, props) => {
    if (option) {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                {!props.postIcon && <img alt={option.name} src={props.icon} className={`mr-2`} style={{ marginLeft: "5px", height: '1.2rem' }} />}
                <div style={{ marginLeft: "5px" }}>{option.name}</div>
                {props.postIcon && <img alt={option.name} src={props.icon} className={`mr-2`} style={{ marginLeft: "5px", height: '1.2rem' }} />}
            </div>
        );
    }

    return <span>{props.placeholder}</span>;
};

const CustomSelectTwo = ({ placeholder, filter, error, options, idx, showClear, selectedOption, postIcon, icon, disabled, setSelectedOption = () => { } }) => {
    return (
        <div style={{ border: error ? "1px solid red" : "", borderRadius: "10px", position: "relative" }} className='custom-select-two'>
        <Dropdown value={selectedOption} onChange={(e) => setSelectedOption(idx, e.value ? e.value : "")} options={options} optionLabel="name"
                placeholder={placeholder} filter={filter}
                valueTemplate={selectedOptionTemplate}
                appendTo="self"
                showFilterClear
                icon={icon}
                disabled={disabled}
                showClear={showClear && selectedOption}
                />
        </div>
    )
}

export default CustomSelectTwo;
