import React from 'react';
import bgImage from '../../resources/pattern.png';
// import shortlistPage from '../../resources/shortlistPage.png'
// import publishPage from '../../resources/publishPage.png'

import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useSetState } from 'react-use';
import './Main.css';
import JoyRideDialogBox from './components/JoyRideDialogBox';
import { FirstPageData, SecondPageData } from '../../constants/Onboarding-Constants/pageData';
import JoyrideCustomButton from './components/JoyRideCustomButton';
import { TutorialSteps } from '../../constants/Onboarding-Constants/TutorialSteps';

const JoyRideMain = ({ content, showTutorial, tutorialCurrIdx, setTutorialCurrIdx = () => {}, setFilterCollapseFlag = () => {}, clickExtractedEvents = () => {}, clickShortlistedEvents = () => {}, clickPublished = () => {}, tutorialCompleted = () => {}, setshowTutorial = () => {} }) => {
  const [tutorialPagesOrder, setTutorialPagesOrder] = React.useState(1);
  const [showTutorialJoyride, setShowTutorialJoyRide] = React.useState(false);
  const [joyRideCurrentIdx, setJoyRideCurrentIdx] = React.useState(-1);

  let started = false;

  const [{ stepIndex, steps }, setState] = useSetState({
    run: true,
    // stepIndex: 0,
    steps: TutorialSteps,
  });

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    setJoyRideCurrentIdx(index);
    setTutorialCurrIdx(index);
    if ([ACTIONS.FINISHED, ACTIONS.SKIPPED, ACTIONS.CLOSE].includes(action)) {
      tutorialCompleted();
      setshowTutorial(true);
    } else
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (index === 5 && action === ACTIONS.NEXT) {
        clickShortlistedEvents();
        setState({
          run: false,
          stepIndex: nextStepIndex,
        });
        setTimeout(() => {
          setState({ run: true });
        }, 400);
      } else if (index === 6 && action === ACTIONS.PREV) {
        clickExtractedEvents();
        setState({
          run: false,
          stepIndex: nextStepIndex,
        });
        setTimeout(() => {
          setState({ run: true });
        }, 400);
      } else if (index === 7 && action === ACTIONS.PREV) {
        clickShortlistedEvents();
        setState({
          run: false,
          stepIndex: nextStepIndex,
        });
        setTimeout(() => {
          setState({ run: true });
        }, 400);
      } else if (index === 6 && action === ACTIONS.NEXT) {
        clickPublished();
        setState({
          run: false,
          stepIndex: nextStepIndex,
        });
        setTimeout(() => {
          setState({ run: true });
        }, 400);
      } else {
        setState({
          stepIndex: nextStepIndex,
        });
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.CLOSE].includes(status)) {
      setState({ run: false });
      tutorialCompleted();
      setshowTutorial(true);
    }
  };

  const handlePageNextButtonClick = () => {
    if (tutorialPagesOrder === 1) {
      setTutorialPagesOrder(2);
    } else if (tutorialPagesOrder === 2) {
      setFilterCollapseFlag(false);
      setTutorialPagesOrder(3);
      setShowTutorialJoyRide(true);
    }
  };

  React.useEffect(() => {
    if (joyRideCurrentIdx === 0 && !started) {
      setFilterCollapseFlag(false);
      started = true;
    } else {
      setFilterCollapseFlag(true);
    }
  }, [joyRideCurrentIdx]);

  React.useEffect(() => {
    if (joyRideCurrentIdx === 6 || joyRideCurrentIdx === 7) {
      const elem = document.createElement('div');
      elem.className = 'sub-tabs-main-div';
      elem.id = 'subtab-joyride-container';
      elem.style.backgroundColor = 'rgb(212, 234, 240)';
      elem.style.border = '2px solid rgb(212, 234, 240)';
      elem.style.boxShadow = 'rgba(0, 0, 0, 0.5) 0px 0px 10px';
      elem.style.position = 'relative';
      elem.style.top = '-16rem';
      elem.style.zIndex = '100000';
      elem.style.margin = '0 0 0 1rem';
      elem.style.padding = '7px';
      elem.style.width = joyRideCurrentIdx === 6 ? '65rem' : joyRideCurrentIdx === 7 ? '49rem' : '';
      elem.style.borderRadius = '5px';
      elem.style.backgroundImage = `url('${bgImage}')`;
      elem.style.backgroundSize = 'cover';

      const subTabItems = [
        { text: 'All Shortlisted', count: '(5)' },
        { text: 'Health Event', count: '(4)' },
        { text: 'Health Information', count: '(1)' },
        { text: 'Rejected', count: '(0)' },
      ];

      const publishSubTabItems = [
        { text: 'All Published', count: '(3)' },
        { text: 'Published Events', count: '(3)' },
        { text: 'Health Information', count: '(0)' },
      ];

      (joyRideCurrentIdx === 6 ? subTabItems : joyRideCurrentIdx === 7 ? publishSubTabItems : []).forEach((itemData) => {
        const subTabItem = document.createElement('div');
        subTabItem.className = 'sub-tab-item text-wrap';
        if (itemData.text === 'All Shortlisted' || itemData.text === 'All Published') {
          subTabItem.className += ' sub-item-start';
          subTabItem.style.backgroundColor = 'rgb(163, 214, 238)';
        } else if (itemData.text === 'Rejected' || (itemData.text === 'Health Information' && itemData.count === '(0)')) {
          subTabItem.className += ' sub-item-end';
        } else {
          subTabItem.className += ' sub-item-middle';
        }

        const textWrap = document.createElement('div');
        textWrap.className = 'text-wrap';
        textWrap.textContent = itemData.text;

        const countElem = document.createElement('div');
        countElem.style.marginLeft = '10px';
        countElem.textContent = itemData.count;

        subTabItem.appendChild(textWrap);
        subTabItem.appendChild(countElem);
        elem.appendChild(subTabItem);
      });

      const joyRideContainer = document.getElementById('react-joyride-step-' + joyRideCurrentIdx);
      joyRideContainer.appendChild(elem);

      // // for setting image as background

      // const elem = document.createElement("div");
      // elem.className = "joyride-background-overlay-image-container";
      // elem.id = "joyride-background-overlay-image-container";
      // elem.style.position = "relative";
      // elem.style.top = "-22rem";
      // elem.style.zIndex = "100000";
      // elem.style.width = "100vw";
      // elem.style.height = "calc(102vh-84px)"

      // const image = document.createElement('img');
      // image.className = "joyride-background-overlay-image"
      // image.src = joyRideCurrentIdx === 6 ? shortlistPage : joyRideCurrentIdx === 7 ? publishPage : "";
      // image.style.height = "100%"
      // image.style.width = "100%"
      // elem.appendChild(image);

      // const joyRideContainer = document.getElementById("react-joyride-step-" + joyRideCurrentIdx);
      // joyRideContainer.appendChild(elem);
    }
  }, [joyRideCurrentIdx]);

  return (
    <div>
      {tutorialPagesOrder === 1 && <JoyRideDialogBox headerIcon={FirstPageData.headerIcon} preButtonIcon={true} heading={FirstPageData.heading} bodyText={FirstPageData.bodyText} preBtnTxtIconType={FirstPageData.preBtnTxtIcon.type} preBtnTxtIconIcon={FirstPageData.preBtnTxtIcon.icon} buttonText={FirstPageData.buttonText} currentPage={tutorialPagesOrder} clicked={handlePageNextButtonClick} color='#fff' background='#FF7A5A' />}
      {tutorialPagesOrder === 2 && <JoyRideDialogBox bodyImg={SecondPageData.bodyImg} postButtonIcon={true} postBtnTxtIconType={SecondPageData.postBtnTxtIcon.type} postBtnTxtIconIcon={SecondPageData.postBtnTxtIcon.icon} buttonText={SecondPageData.buttonText} currentPage={tutorialPagesOrder} clicked={handlePageNextButtonClick} color='#fff' background='#FF7A5A' />}

      {tutorialPagesOrder === 3 && (
        <Joyride
          continuous
          callback={handleJoyrideCallback}
          hideCloseButton
          run={showTutorialJoyride}
          stepIndex={stepIndex}
          scrollToFirstStep
          disableScrolling
          disableScrollParentFix
          steps={steps}
          locale={{
            back: (
              <div style={{ position: 'absolute', left: '40px', bottom: '35px' }}>
                <div style={{ backgroundColor: '#F8F8F8', borderRadius: '8px', padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{<i className='pi pi-angle-left' style={{ fontSize: '1.8rem', color: '#7A7A7A' }}></i>}</div>
              </div>
            ),
            // close: 'Close',
            last: <JoyrideCustomButton label="Let's get started" fontSize='1rem' iconSize='1.5rem' padding='16px 20px' background='#FEEDE9' color='#FF7A5A' />,
            next: <JoyrideCustomButton label='Next' iconClass='pi-angle-right' fontSize='rem' iconSize='1.5rem' padding='10px 20px' postIcon={true} color='#fff' background='#FF7A5A' />, // Use your custom button here
            // skip: 'Skip',
          }}
          styles={{
            options: {
              zIndex: 100000,
              primaryColor: 'transparent',
              // overlayColor: "#73838A"
            },
          }}
        />
      )}
    </div>
  );
};

export default JoyRideMain;
