import { Tooltip } from '@material-ui/core';
import * as React from 'react';
import "./CustomCardTag.css";

const CustomCardTag = ({ color, background, icon, text, tooltipText }) => {
    return (
        <Tooltip title={tooltipText} placement="top" arrow className='custom-card-tag' >
            <div className='label-div' style={{ background: background, color: color }}>
                <img className='label-icon' src={icon} alt="icon" />
                <div className='label-text'>
                    {text}
                </div>
            </div>
        </Tooltip>
    )
}

export default CustomCardTag;
