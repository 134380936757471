import * as React from 'react';
import { Tooltip } from '@material-ui/core';
import "./CustomTooltip.css";

const CustomTooltip = ({ color, background, iconClass, icon, text, tooltipText, fontSize, link, onClick = () => {} }) => {
    return (
        <Tooltip title={tooltipText} placement="top" arrow>
            <div className='tooltip-label-div' style={{ background: background || "transparent", color: color || "#000" }} onClick={() => onClick()}>
                {iconClass && <i className={"pi " + iconClass} style={{ color: color || 'slateblue', fontSize: fontSize || "1rem" }}></i> }
                {text && <div className='label-text'>
                    {text}
                </div>}
                {icon && <div className='label-image' onClick={() => link && window.open(link)}>
                    <img src={icon} alt="icon-logo" />
                </div>}
            </div>
        </Tooltip>
    )
}

export default CustomTooltip;
