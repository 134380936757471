import * as React from 'react';
import { Dropdown } from 'primereact/dropdown';
import sortIcon from "../../../resources/sortIcon.png"
import "./CustomSelect.css";

const selectedOptionTemplate = (option, props) => {
    if (option) {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <img alt={option.name} src={sortIcon} className={`mr-2`} style={{ width: '18px' }} />
                <div style={{ marginLeft: "5px" }}>{option.name}</div>
            </div>
        );
    }

    return <span>{props.placeholder}</span>;
};

const CustomSelect = ({ placeholder, options, showClear, selectedOption, setSelectedOption = () => { } }) => {
    return (
        <Dropdown value={selectedOption} onChange={(e) => setSelectedOption(e.value)} options={options} optionLabel="name" showClear={showClear}
                placeholder={placeholder} valueTemplate={selectedOptionTemplate} appendTo="self" className='custom-select-two' />
    )
}

export default CustomSelect;
