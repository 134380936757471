import React from 'react'
import { FormControl, Grid, InputAdornment, Paper } from '@material-ui/core';
import { CssTextField, CssTypography, actionTypeMap, useStyles } from '../../../constants/app-constants';
import CustomSelectTwo from '../../Shared/CustomSelectTwo/CustomSelectTwo';
import CustomGroupSelect from '../../Shared/CustomGroupSelect/CustomGroupSelect';
import CustomCardNumber from '../../Shared/CustomCardNumber/CustomCardNumber';
import CustomCardTag from '../../Shared/CustomCardTag/CustomCardTag';
import CustomActionButtonTwo from '../../Shared/CustomActionButtonTwo/CustomActionButtonTwo';
import { AddCircle } from '@material-ui/icons';
import MainEventIcon from "../../../resources/mainEventIcon.png";
import AITranslatedIcon from "../../../resources/aiTranslatedIcon.png";
import linkIcon from "../../../resources/linkIcon.png";
import deathIcon from "../../../resources/deathIcon.png";
import newspaperIcon from "../../../resources/newspaperIcon.png";
import diseaseIcon from "../../../resources/diseaseIcon.png";
import locationIcon from "../../../resources/locationIcon.png";
import vectorIcon from "../../../resources/vectorIcon.png";
import videoIcon from "../../../resources/videoIcon.png";
import CustomTooltip from '../../Shared/CustomTooltip/CustomTooltip';
import CustomCollapseButton from '../../Shared/CustomCollapseButton/CustomCollapseButton';
import JoyRideActionButton from './JoyRideCustomActionButton';
import JoyRideCustomStatusBar from './JoyRideCustomStatusBar';
import { onboardingSelectorIds } from '../../../constants/Onboarding-Constants/idSelector';
import { onboardingStyle } from '../../../constants/Onboarding-Constants/style';

const JoyRideSingleCard = ({ mainCardFlag, mainDataPoint, idx, showCluster, content, checkPublished, aiTranslationFlag, page, countEvents, buttonFreeze, clusterDialog, tutorialCurrIdx }) => {
    const classes = useStyles();
    const collapseCard = false

    return (
        <Paper key={idx} id={onboardingSelectorIds.singleCard} className={`customPaper ${(!mainCardFlag || (mainDataPoint.cluster_count > 1)) ? "customPaperLong" : "customPaperShort"}`} style={{ display: "Flex", marginBottom: "20px", backgroundColor: mainDataPoint.reject_value ? "#F8D5D5" : "white", boxShadow: "5px 5px 10px lightgray", borderRadius: "10px", opacity: collapseCard ? 0.5 : 1, zIndex: 1, margin: "2rem", width: !showCluster ? "77.5vw" : "" }}>
            <Grid container >
                <Grid item lg={12} md={12} sm={12} style={{ padding: "30px", paddingTop: "0px" }}>
                    <Grid container>
                        <Grid item lg={12} md={12} sm={12} >
                            <Grid container>
                                <Grid item lg={11} md={11} sm={11} style={{ paddingBottom: "1rem" }}>
                                    <h1>{aiTranslationFlag ? mainDataPoint.translated_article.title : mainDataPoint.article.title}</h1>
                                </Grid>
                                <Grid item lg={1} md={1} sm={1} style={{ padding: "0", display: 'flex', justifyContent: 'end', alignItems: 'center', columnGap: "10px" }}>
                                    <CustomTooltip tooltipText="Click to Copy Event ID" iconClass="pi-copy" fontSize="1.3rem" color="#78828C"/>
                                    {!clusterDialog && content === "extractedEvents" && <CustomCollapseButton collapseFlag={collapseCard} color="#e6f4f9" background="#2b87b5"/>}
                                </Grid>
                            </Grid>
                        </Grid>
                        {!collapseCard && <>
                            <Grid container id="card-details-container" >
                            <Grid item lg={3} md={3} sm={6} style={{ marginBottom: "20px" }}>
                                {content === "extractedEvents" && <CssTypography>Extraction Date</CssTypography>}
                                {content === "shortlistedEvents" && <CssTypography>Shortlist Date</CssTypography>}
                                {content === "publishedEvents" && <CssTypography>Publish Date</CssTypography>}
                                <CssTextField
                                    type="date"
                                    size="small"
                                    variant="outlined"
                                    value={mainDataPoint.extracted_date}
                                    className={classes.textField2}
                                    style={{ backgroundColor: "#F3FAFF" }}
                                    InputProps={{ readOnly: (checkPublished) }}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item lg={5} md={5} sm={6} style={{ marginBottom: "20px" }}>
                                <CssTypography>Disease Type</CssTypography>
                                <FormControl fullWidth size="small" style={{ width: "90%", backgroundColor: "#F3FAFF", border: tutorialCurrIdx === 3 ? onboardingStyle.border : "", borderRadius: "12px", boxShadow: tutorialCurrIdx === 3 ? onboardingStyle.boxShadow : "" }}>
                                    <CustomSelectTwo filter={true} icon={diseaseIcon} placeholder={"Select Disease"} options={[{ name: mainDataPoint.disease, value: mainDataPoint.disease }]} idx={idx} selectedOption={mainDataPoint.disease} />
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} style={{ marginBottom: "20px" }}>
                                <div>
                                    <CssTypography>Source</CssTypography>
                                </div>
                                <div id="source-filter-container" style={{ position: "relative", backgroundColor: "#F3FAFF", padding: "8.35px 14px", font: "inherit", fontSize: "15px", color: "currentColor", border: tutorialCurrIdx === 2 ? onboardingStyle.border : (mainDataPoint.url.trim().length === 0) ? " 1px solid red" : "1px solid #bbc0c3", borderRadius: "12px", boxShadow: tutorialCurrIdx === 2 ? onboardingStyle.boxShadow : "" }}>
                                    <div style={{ position: "absolute", top: "10px", left: "14px", background: "#F3FAFF", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <img src={newspaperIcon} style={{ height: "1rem" }} alt="newspaper-icon" />
                                    </div>
                                    <div className='text-wrap' style={{ paddingRight: "20px", paddingLeft: "30px" }}>{mainDataPoint.url ? new URL(mainDataPoint.url).hostname : "."}</div>
                                    <div style={{ position: "absolute", top: "10px", right: "10px", background: "#F3FAFF", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }} >
                                        <img src={linkIcon} style={{ height: "1rem" }} alt="link-icon" />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xl={3} lg={3} md={3} sm={6} style={{ marginBottom: "20px" }}>
                                <div style={{ display: "flex", width: "92%" }}>
                                    <div>
                                        <CssTypography>Reported Cases</CssTypography>
                                        <CssTextField
                                            type="text"
                                            size="small"
                                            variant="outlined"
                                            value={mainDataPoint.incident === "case" && parseInt(mainDataPoint.number) > 0 ? mainDataPoint.number : ""}
                                            disabled={mainDataPoint.incident === "death"}
                                            className={classes.textField2}
                                            style={{ backgroundColor: "#F3FAFF", border: tutorialCurrIdx === 3 ? onboardingStyle.border : "", borderRadius: "12px", boxShadow: tutorialCurrIdx === 3 ? onboardingStyle.boxShadow : "" }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><img className='card-field-icon' src={vectorIcon} alt="location-icon" /></InputAdornment>,
                                                readOnly: (checkPublished)
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <CssTypography>Death</CssTypography>
                                        <CssTextField
                                            type="text"
                                            size="small"
                                            variant="outlined"
                                            value={mainDataPoint.incident === "death" && parseInt(mainDataPoint.number) > 0 ? mainDataPoint.number : ""}
                                            disabled={mainDataPoint.incident === "case"}
                                            className={classes.textField2}
                                            style={{ backgroundColor: "#F3FAFF", border: tutorialCurrIdx === 3 ? onboardingStyle.border : "", borderRadius: "12px", boxShadow: tutorialCurrIdx === 3 ? onboardingStyle.boxShadow : "" }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><img className='card-field-icon' src={deathIcon} alt="location-icon" /></InputAdornment>,
                                                readOnly: (checkPublished)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item lg={3} md={3} sm={6} style={{ marginBottom: "20px" }} id="source-selector-joyride">
                                <CssTypography>State</CssTypography>
                                <FormControl fullWidth size="small" style={{ width: "90%", backgroundColor: "#F3FAFF", border: tutorialCurrIdx === 3 ? onboardingStyle.border : "", borderRadius: "12px", boxShadow: tutorialCurrIdx === 3 ? onboardingStyle.boxShadow : "" }}>
                                    <CustomSelectTwo filter={true} icon={locationIcon} placeholder={"Select State"} options={[{ name: mainDataPoint.state, value: mainDataPoint.state }]} idx={idx} selectedOption={mainDataPoint.state} />
                                </FormControl>
                            </Grid>
                            <Grid item lg={3} md={3} sm={6} style={{ marginBottom: "20px" }}>
                                <CssTypography>District</CssTypography>
                                <FormControl fullWidth size="small" style={{ width: "90%", backgroundColor: "#F3FAFF", border: tutorialCurrIdx === 3 ? onboardingStyle.border : "", borderRadius: "12px", boxShadow: tutorialCurrIdx === 3 ? onboardingStyle.boxShadow : "" }}>
                                    <CustomSelectTwo filter={true} showClear={true} error={mainDataPoint.district.trim().length === 0} icon={locationIcon} placeholder={"Select District"} options={[{ name: mainDataPoint.district, value: mainDataPoint.district }]} idx={idx} selectedOption={mainDataPoint.district} />
                                </FormControl>
                            </Grid>
                            <Grid item lg={3} md={3} sm={6} style={{ marginBottom: "20px" }}>
                                <CssTypography>Corporation / Sub District</CssTypography>
                                <FormControl fullWidth size="small" style={{ backgroundColor: "#F3FAFF", border: tutorialCurrIdx === 3 ? onboardingStyle.border : "", borderRadius: "12px", boxShadow: tutorialCurrIdx === 3 ? onboardingStyle.boxShadow : "" }}>
                                    <CustomGroupSelect
                                        placeholder="Select Sub District"
                                        options={[]}
                                        selectedValue={mainDataPoint.sub_district}
                                        idx={idx}
                                        labelKey="subdistrict_name"
                                        valueKey="local_body_code"
                                        icon={locationIcon}
                                        filter={true}
                                    />
                                </FormControl>
                            </Grid>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} style={{ marginBottom: "20px" }}>
                                <CssTypography>Original Message: </CssTypography>
                                <CssTextField
                                    multiline
                                    type="text"
                                    variant="outlined"
                                    value={mainDataPoint.article.title + " " + mainDataPoint.article.description}
                                    className={classes.textField2}
                                    style={{ backgroundColor: "#F3FAFF", width: "100%" }}
                                    InputProps={{ className: classes.textarea, readOnly: (checkPublished) }}
                                />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} style={{ marginBottom: "20px" }}>
                                <CssTypography>Translated Message: </CssTypography>
                                <CssTextField
                                    multiline
                                    type="text"
                                    variant="outlined"
                                    value={mainDataPoint.translated_article.title + " " + mainDataPoint.translated_article.description}
                                    className={classes.textField2}
                                    style={{ backgroundColor: "#F3FAFF", width: "100%" }}
                                    InputProps={{ className: classes.textarea, readOnly: true }}
                                />
                            </Grid>
                        </>}
                        <Grid item lg={12} md={12} sm={12}>
                            <Grid container>
                                <Grid item lg={6} md={6} sm={6} style={{ display: "flex", alignItems: "end" }}>
                                    {mainCardFlag && <CustomCardNumber index={(idx + 1) + (10 * (page - 1))} totalCount={countEvents} />}
                                    <Grid container style={{ marginLeft: "10px" }}>
                                        {content === "extractedEvents" && !mainDataPoint.health_information && <CustomCardTag color={"#476777"} background={"#E2EDF1"} icon={MainEventIcon} text={"Unusual Health Event"} tooltipText={"This article is unsual health event"} />}
                                        {content === "extractedEvents" && mainDataPoint.health_information && <CustomCardTag color={"#476777"} background={"#E2EDF1"} icon={MainEventIcon} text={"Health Information"} tooltipText={"This article is health information"} />}
                                        {(content === "shortlistedEvents" || content === "publishedEvents") && mainDataPoint.health_information && <CustomCardTag color={"#476777"} background={"#E2EDF1"} icon={MainEventIcon} text={"Health Information"} tooltipText={"This article is health information"} />}
                                        {(content === "shortlistedEvents" || content === "publishedEvents") && !mainDataPoint.health_information && <CustomCardTag color={"#476777"} background={"#E2EDF1"} icon={MainEventIcon} text={"Health Event "} tooltipText={"This article is health event"} />}
                                        {mainDataPoint.source_type === "newspaper" && <CustomCardTag color={"#468548"} background={"#E1F2E2"} icon={newspaperIcon} text={"Newspaper"} tooltipText={"This source of the event is newspaper"} />}
                                        {mainDataPoint.source_type === "video" && <CustomCardTag color={"#468548"} background={"#E1F2E2"} icon={videoIcon} text={"Video"} tooltipText={"This source of the event is video"} />}
                                        {aiTranslationFlag && mainDataPoint.language !== "en" && <CustomCardTag color={"#71608D"} background={"#EDE9F3"} icon={AITranslatedIcon} text={"AI Translated"} tooltipText={"This article is translated from " + mainDataPoint.language} />}
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} style={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                                    {(content === "extractedEvents" || content === "shortlistedEvents") && !clusterDialog && !mainDataPoint.reject_value && <JoyRideActionButton highlightBtn={tutorialCurrIdx === 5} actionType={actionTypeMap.reject} label={actionTypeMap.reject} eventData={mainDataPoint} disabled={buttonFreeze} />}
                                    <div style={{ width: "12px" }}></div>
                                    {content === "extractedEvents" && !mainDataPoint.reject_value && <JoyRideActionButton highlightBtn={tutorialCurrIdx === 5} actionType={actionTypeMap.shortlist} label={actionTypeMap.shortlist} eventData={mainDataPoint} hasParent={!!mainDataPoint.parent_type} clusterDialog={clusterDialog} />
                                    }
                                    {content === "shortlistedEvents" && !mainDataPoint.health_information && !mainDataPoint.reject_value && <JoyRideActionButton actionType={actionTypeMap.publish} label={actionTypeMap.publish} eventData={mainDataPoint} hasParent={!!mainDataPoint.parent_type} />
                                    }
                                    {content === "shortlistedEvents" && mainDataPoint.health_information && !mainDataPoint.reject_value && <CustomActionButtonTwo label={actionTypeMap.sendEmail} data={mainDataPoint} theme={"primary"} emailFlag={true} emailID={"idsp-msc@nic.in"} />
                                    }
                                    {content === "publishedEvents" && <JoyRideActionButton actionType={actionTypeMap.republish} label={actionTypeMap.republish} theme={"primary"} data={mainDataPoint} disabled={buttonFreeze} />
                                    }
                                    {content === "publishedEvents" && !clusterDialog && <>
                                        <div style={{ width: "12px" }}></div>
                                        <JoyRideActionButton cssId={onboardingSelectorIds.printPdf} actionType={actionTypeMap.printPDF} label={actionTypeMap.printPDF} theme={"outlined"} data={mainDataPoint} disabled={buttonFreeze} />
                                    </>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {content === "publishedEvents" && !clusterDialog && <Grid item lg={12} md={12} sm={12}>
                    <JoyRideCustomStatusBar cssId={onboardingSelectorIds.outbreakStatus} icon={<AddCircle />} data={mainDataPoint.outbreak_data} theme={mainDataPoint.outbreak_data.outbreak_status} />
                </Grid>}
            </Grid>
            {mainCardFlag && showCluster && (mainDataPoint.cluster_count > 1) && <Grid container item sm={3} md={3} lg={3} style={{ cursor: "pointer", border: tutorialCurrIdx === 4 ? onboardingStyle.border : "", borderRadius: "12px", boxShadow: tutorialCurrIdx === 4 ? onboardingStyle.boxShadow : "" }}>
                <div style={{ width: "100%", height: "100%", background: "#E3E4DB", color: "#413F40", borderRadius: "0px 10px 10px 0px" }}>
                    <div className='relative-events-div' style={{ display: "Flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly", height: "100%" }}>
                        <div style={{ display: "Flex", flexDirection: "column", alignItems: "center" }}>
                            <div style={{ margin: 0, fontSize: "5rem", fontWeight: "700" }}>+{mainDataPoint.cluster_count - 1}</div>
                            <div style={{ fontSize: " 1.2rem", fontWeight: "500", width: "80%", textAlign: "center", textWrap: 'nowrap' }}>Related Reports</div>
                        </div>
                        {!collapseCard && <button className='relative-events-button' >SEE RELATED REPORTS </button>}
                    </div>
                </div>
            </Grid>}
        </Paper>
    )
}

export default JoyRideSingleCard;
