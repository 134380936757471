/* eslint-disable camelcase */

import jwt_decode from 'jwt-decode';

export function getUserStateFromToken (token) {
    const decoded_token = jwt_decode(token);
    if (decoded_token.user_state) {
        return decoded_token.user_state
    }
    return null
}

export function getUserIdFromToken (token) {
    const decoded_token = jwt_decode(token);
    if (decoded_token.user_id) {
        return decoded_token.user_id
    }
    return null
}

export function getEventTitle (eventData) {
    if (eventData.translated_message.length > 0) {
        return eventData.translated_message.split('.')[0].split(' ').slice(0, 15).join(' ');
    } else {
        return eventData.translated_message;
    }
}

export function validateEventData (eventData) {
    return !(eventData.extracted_date.trim().length === 0 ||
                eventData.disease.trim().length === 0 ||
                eventData.url.trim().length === 0 ||
                eventData.state.trim().length === 0 ||
                eventData.district.trim().length === 0
                // || (eventData.article.title.trim() + eventData.article.description.trim()).length === 0
                );
}

export function convertStrObjToNumberObj (obj) {
    return {
        case_new: parseInt(obj.case_new, 10) || 0,
        case_total: parseInt(obj.case_total, 10) || 0,
        death_new: parseInt(obj.death_new, 10) || 0,
        death_total: parseInt(obj.death_total, 10) || 0
    }
}

export function capitalizeFirstLetter (string) {
    console.log(string);
    if (string.toLowerCase().includes("delhi") || string.toLowerCase().includes("sikkim")) {
        return string.split(' ').map(st => st.charAt(0).toUpperCase() + st.slice(1)).join(' ');
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
