import * as React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, Paper, Typography } from '@material-ui/core';
import { CssTextField, customThemes } from '../../constants/app-constants';
import bgImage from '../../resources/pattern.png';
import dateIcon from '../../resources/dateIcon.png';
import locationIcon from '../../resources/locationIcon.png';
import linkIcon from '../../resources/linkIconGreen.png';
import editIcon from '../../resources/editIcon.png';
import downloadIcon3 from '../../resources/downloadIcon3.png';
import { Close } from '@material-ui/icons';
import './GeneratePDFDIalog.css';
import CustomRichTextEditor from '../Shared/CustomRichTextEditor/CustomRichTextEditor'
import CustomTooltip from '../Shared/CustomTooltip/CustomTooltip';
import CustomButtonWithIcon from '../Shared/CustomButtonWithIcon/CustomButtonWithIcon';

const CustomTextBox = ({ text, icon, width }) => {
    return <div style={{ padding: "18.5px 14px", display: "flex", borderRadius: "4px", background: "#F3F7F9", alignItems: "center", columnGap: "12px" }}>
        <img style={{ height: "1rem", width: width || "", opacity: "0.5" }} src={icon} alt="icon" />
        <div style={{ fontSize: "1rem", lineHeight: "1.1876em", color: "#A0A5AD", cursor: "default", fontWeight: "400" }} >{text}</div>
    </div>
}

const GeneratePDFDialog = ({ eventData, dialogFlag, descriptionWordLimitFlag, closeDialogFun = () => { }, generatePDF = () => { } }) => {
    const [infoID, setInfoID] = React.useState('');
    const [title, setTitle] = React.useState(eventData.translated_article.title);
    const [description, setDescription] = React.useState(eventData.translated_article.title + " " + eventData.translated_article.description);
    const [descriptWordCount, setDescriptionWordCount] = React.useState((eventData.translated_article.title + " " + eventData.translated_article.description).split(" ").length)
    const [showDownloadingLoader, setShowDownloadingLoader] = React.useState(false)

    const MAX_WORDS = 300;

    function findWords (sentence) {
         return sentence.split(/\s+/);
    }

    function descriptionChangeHandler (e) {
        const text = findWords(e.textValue);
        if (text.length <= MAX_WORDS) {
            setDescriptionWordCount(text.length)
        } else {
            setDescriptionWordCount(MAX_WORDS)
        }
        setDescription(text.splice(0, MAX_WORDS).join(" "));
    }

    function submit () {
        setShowDownloadingLoader(true)
        generatePDF(infoID, eventData.published_date, eventData.extracted_date, eventData.location, eventData.url, eventData.language, title, JSON.stringify(description), setShowDownloadingLoader);
    }
    return (
        <div>
            <Dialog
                open={dialogFlag}
                onClose={closeDialogFun}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'xl'}
            >
                <DialogTitle id="alert-dialog-title" style={{ backgroundImage: `url(` + bgImage + `)`, backgroundColor: "#D4EAF0", backgroundSize: "cover", padding: "1rem" }}>
                    <Grid container>
                        <Grid item lg={11} md={11} sm={11} style={{ fontSize: "x-large", display: "flex", alignItems: "center", fontWeight: "600" }}>
                            Enter Details For PDF
                        </Grid>
                        <Grid item lg={1} md={1} sm={1} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                            <button className='custom-close-button' onClick={() => closeDialogFun()}>
                                <Close />
                            </button>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Paper style={{ margin: "20px", backgroundColor: "white", border: "1px solid black" }}>
                            <Grid container style={{ padding: "15px" }}>
                                <Grid item lg={12} sm={12} md={12}>
                                    <Grid container spacing={3} >
                                        <Grid item lg={4} sm={4} md={4} style={{ paddingBottom: "0.5rem" }}>
                                            <Typography style={{ fontWeight: 500, fontSize: "large" }}>Information ID :</Typography>
                                            <CssTextField
                                                type="number"
                                                variant="outlined"
                                                value={infoID}
                                                onChange={(e) => setInfoID(e.target.value)}
                                                placeholder="Enter Information ID"
                                                style={{ backgroundColor: "#F3FAFF", width: "100%" }}
                                            />
                                        </Grid>
                                        <Grid item lg={8} sm={8} md={8} style={{ paddingBottom: "0.5rem" }}>
                                            <Typography style={{ fontWeight: 500, fontSize: "large" }}>Title :</Typography>
                                            <CssTextField
                                                multiline
                                                type="text"
                                                variant="outlined"
                                                value={title}
                                                placeholder="Title"
                                                onChange={(e) => setTitle(e.target.value)}
                                                style={{ backgroundColor: "#F3FAFF", width: "100%" }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} >
                                        <Grid item lg={4} sm={8} md={8} style={{ paddingBottom: "0.5rem" }}>
                                            <Typography style={{ fontWeight: 500, fontSize: "large", color: "#8D949D" }}>Location :</Typography>
                                            <CustomTextBox text={eventData.district + ", " + eventData.state} icon={locationIcon} width="12px" />
                                        </Grid>
                                        <Grid item lg={2} sm={4} md={4} style={{ paddingBottom: "0.5rem" }}>
                                            <Typography style={{ fontWeight: 500, fontSize: "large", color: "#8D949D" }}>Reporting Date :</Typography>
                                            <CustomTextBox text={eventData.extracted_date} icon={dateIcon} />
                                        </Grid>
                                        <Grid item lg={2} sm={4} md={4} style={{ paddingBottom: "0.5rem" }}>
                                            <Typography style={{ fontWeight: 500, fontSize: "large", color: "#8D949D" }}>Publication Date :</Typography>
                                            <CustomTextBox text={eventData.published_date} icon={dateIcon} />
                                        </Grid>
                                        <Grid item lg={4} sm={8} md={8} style={{ paddingBottom: "0.5rem" }}>
                                            <Typography style={{ fontWeight: 500, fontSize: "large", color: "#8D949D" }}>Link :</Typography>
                                            {/* <CustomTextBox text={new URL(eventData.url).hostname} icon={linkIcon} /> */}
                                            <div style={{ padding: "18.5px 14px", display: "flex", borderRadius: "4px", background: "#F3F7F9", alignItems: "center", columnGap: "14px" }}>
                                                <img style={{ height: "1rem" }} src={linkIcon} alt="icon" />
                                                <div className='pdf-dialog-link-text' style={{ fontSize: "1rem", lineHeight: "1.1876em", color: "#414C5B", cursor: "default", fontWeight: "400", textDecoration: "underline" }} >{new URL(eventData.url).hostname}</div>
                                            </div>
                                            <div style={{ fontSize: "14px", color: "#A0A5AD" }}>Review the article here</div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={12} sm={12} md={12}>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                        <Typography style={{ fontWeight: 500, fontSize: "large" }}>Description:</Typography>
                                        <div style={{ color: (MAX_WORDS - descriptWordCount === 0) ? "#f06e67" : "#414C5B", fontSize: "18px" }}>{descriptWordCount + "/"}<span style={{ color: "#A0A5AD" }} >{MAX_WORDS + " word limit"}</span></div>
                                    </div>
                                    <CustomRichTextEditor disabled={descriptWordCount >= MAX_WORDS} onTextChange={descriptionChangeHandler} value={description}/>
                                    <div style={{ display: "flex", background: "#E7EFF9", padding: "0.6rem 1.1rem", columnGap: "10px", borderBottomRightRadius: "6px", borderBottomLeftRadius: "6px" }}>
                                        <img style={{ height: "1rem" }} src={editIcon} alt="edit" />
                                        <div style={{ color: "#008BCA", fontSize: "14px", fontWeight: "600" }}>Follow the given word limit, to fit it in the pdf to be printed.</div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </DialogContent>
                <DialogActions style={{ margin: "10px", paddingRight: "34px" }}>
                    <CustomButtonWithIcon height="1.6rem" tooltipText={(!infoID.trim().length > 0 || !title.trim().length > 0 || !description.trim().length > 0) ? "Information ID and Description cannot be empty" : null} disabled={!infoID.trim().length > 0 || !title.trim().length > 0 || !description.trim().length > 0 || showDownloadingLoader} label={!showDownloadingLoader ? "Download" : "Downloading"} theme={(!infoID.trim().length > 0 || !title.trim().length > 0 || !description.trim().length > 0 || showDownloadingLoader) ? customThemes.grey : customThemes.primary} icon={!showDownloadingLoader ? downloadIcon3 : null} loader={showDownloadingLoader} clicked={submit} />
                </DialogActions>
            </Dialog>
         </div >
    )
}

export default GeneratePDFDialog;
