import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import * as React from 'react';
import { subActionTypeMap } from '../../../constants/app-constants';
import "./CustomActionButton.css";
import { validateEventData } from '../../../actions/methods';

const CustomActionButton = ({ showFlagValue, actionType, label, eventData, hasParent, disabled, clusterDialog, actionPerformed = () => {}, setActionType = () => {}, setShowFlagValue = () => {}, setIsValidEventData = () => { }, setIsValidData = () => { } }) => {
    const menuRef = React.useRef(null);
    function getOptions () {
        let options = []
        if (actionType === "Shortlist") {
            if (clusterDialog) {
                options.push({
                    name: "Shortlist as Main Event",
                    value: subActionTypeMap.shortlistNewEvent
                })
            } else {
                options.push({
                        name: "Shortlist As New Event",
                        value: subActionTypeMap.shortlistNewEvent
                    })
            }
            if (hasParent) {
                options.push({
                        name: "Shortlist (Add to cluster)",
                        value: subActionTypeMap.addToShortlistCluster
                })
            }
            options.push({
                name: "Shortlist as Health Information",
                value: subActionTypeMap.shortlistAsHealthInfo
            })
            return options
        } else if (actionType === "Publish") {
                options.push({
                    name: "Publish As New Event",
                    value: subActionTypeMap.publishNewEvent
                })
            if (hasParent) {
                options.push({
                        name: "Publish (Add to cluster)",
                        value: subActionTypeMap.addToPublishCluster
                })
            }
            return options
        } else if (actionType === "Reject") {
            options = [
                {
                    name: "Reject due to Fake Source",
                    value: subActionTypeMap.rejectFakeSource
                },
                {
                    name: "Reject due to Old News",
                    value: subActionTypeMap.rejectOldNews
                },
                {
                    name: "Reject due to Irrelevant News",
                    value: subActionTypeMap.rejectIrrelevant
                },
                {
                    name: "Reject due to already Published",
                    value: subActionTypeMap.rejectPublished
                },
                {
                    name: "Reject due to International event",
                    value: subActionTypeMap.rejectInternationalEvent
                },
            ]
        return options
        }
    }

    function handleClickOutside (event) {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowFlagValue(null)
        }
    }

    function performAction (eventData, value) {
        actionPerformed(eventData, value)
        if (actionType === "Publish" || actionType === "Shortlist") {
            const validation = validateEventData(eventData);
            setIsValidData(validation);
            setIsValidEventData(validation);
        }
    }

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    // function singleActionPerformed (data) {
    //     console.log(data);
    //     if (actionType === "Shortlist") {
    //         actionPerformed(data, subActionTypeMap.shortlistNewEvent)
    //     } else if (actionType === "Publish") {
    //         actionPerformed(data, subActionTypeMap.publishNewEvent)
    //     }
    // }
    return (
        <div className='main-div'>
            {showFlagValue === actionType && <div className={actionType === "Reject" ? 'reject-options-div' : 'normal-options-div'} ref={menuRef}>
                {getOptions().map((option, idx) =>
                        <div key={idx} className={actionType === "Reject" ? 'reject-option-label' : 'option-label'} onClick={() => performAction(eventData, option.value)}>
                            <div className="options-label-text">{option.name}</div>
                            <i className="pi pi-check"></i>
                        </div>
                    )
                }
            </div>}
            <button className={actionType === "Reject" ? 'reject-action-button' : actionType === "Publish" ? 'publish-action-button' : 'action-button'} style={{ cursor: disabled ? "not-allowed" : "pointer" }} onClick={() => setActionType(actionType)} disabled={disabled}>{label} {!(showFlagValue === actionType) && <KeyboardArrowDown />} {(showFlagValue === actionType) && <KeyboardArrowUp />}</button>
        </div>
    )
}

export default CustomActionButton;
