import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import React from 'react';
import Lottie from 'react-lottie';
import JoyrideCustomButton from './JoyRideCustomButton';

const JoyRideDialogBox = ({ headerIcon, headerLottieIcon, heading, bodyText, subBodyText, bodyImg, preButtonIcon, postButtonIcon, buttonText, preBtnTxtIconType, preBtnTxtIconIcon, postBtnTxtIconType, postBtnTxtIconIcon, buttonWidth, currentPage, clicked = () => { } }) => {
    return (
        <Dialog
                open={currentPage === 1 || currentPage === 2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'lg'}
                style={{ zIndex: 10000 }}
            >
                <DialogTitle id="alert-dialog-title"
                    style={{ padding: "0px 40px 10px" }}>
                    <Grid container>
                        <Grid item lg={12} md={12} sm={12} style={{ fontSize: "x-large", display: "flex", alignItems: "center", fontWeight: "600", justifyContent: "center" }}>
                            {(headerIcon || headerLottieIcon || heading) && <div style={{ display: "flex", alignItems: "initial", marginTop: "52px", justifyContent: "center" }}>
                                {headerIcon && <img src={headerIcon} style={{ height: "50px", }} alt="onboarding icon" />}
                                {headerLottieIcon && <Lottie options={{ loop: true, autoplay: true, animationData: headerLottieIcon, rendererSettings: { preserveAspectRatio: 'xMidYMid slice', }, }} height={50} width={40} style={{ margin: 0 }}/>}
                                {heading && <p style={{ fontSize: "32px", fontWeight: 600, lineHeight: "39px", margin: "0", }}>{heading}</p>}
                            </div>}
                        </Grid>
                        {/* <Grid item lg={1} md={1} sm={1} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                            <IconButton onClick={() => closeDialogFun()}>
                                <Close />
                            </IconButton>
                        </Grid> */}
                    </Grid>
                </DialogTitle>
                <DialogContent >
                    <Box>
                        <Grid container style={{ boxSizing: "border-box", position: "relative" }}>
                            <Grid item lg={12} md={12} sm={12} style={{ margin: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {(bodyText || subBodyText) && <div style={{ textAlign: "center", padding: "0 20px", width: currentPage === 1 ? "600px" : "", marginBottom: currentPage === 1 ? "150px" : "20px" }}>
                                    {bodyText && <p style={{ fontSize: "20px", fontWeight: 400, color: "#434343", lineHeight: "24px", margin: "0" }}>{bodyText}</p>}
                                    {subBodyText && <p style={{ fontSize: "20px", fontWeight: 400, color: "#858585", lineHeight: "24px", margin: "0" }}>{subBodyText}</p>}
                                </div>}
                                {bodyImg && <div style={{ width: "800px", height: "515px" }}>
                                    <img style={{ width: "100%", height: "100%" }} src={bodyImg} alt="Tutorial"/>
                                </div>}
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "1rem" }}>
                                {/* <Grid container style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderTop: "1px solid #000", padding: "14px 2rem" }}>
                                    <Grid item lg={7} md={7} sm={7} >
                                        <Typography style={{ fontWeight: 500 }}>Please wait for a while, and kindly refrain from closing this.</Typography>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4} style={{display: "flex", justifyContent: "flex-end"}} >

                                    </Grid>
                                </Grid> */}
                                <div style={{ position: "absolute", bottom: "48px", left: 0, right: 0, display: "flex", justifyContent: "center" }}>
                                   <div>
                                        <JoyrideCustomButton preIcon={preButtonIcon} postIcon={postButtonIcon} iconSize="28px" iconType={preButtonIcon ? preBtnTxtIconType : postButtonIcon ? postBtnTxtIconType : ""} icon={preButtonIcon ? preBtnTxtIconIcon : postButtonIcon ? postBtnTxtIconIcon : ""} label={buttonText} color="#fff" background="#FF7A5A" onClick={clicked}/>
                                   </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>

    )
}

export default JoyRideDialogBox;
