import React from 'react'
import './JoyRideCustomActionButton.css'
import { KeyboardArrowDown } from '@material-ui/icons';
import { onboardingStyle } from '../../../constants/Onboarding-Constants/style';

const JoyRideActionButton = ({ showFlagValue, actionType, label, cssId, highlightBtn, eventData, hasParent, disabled, clusterDialog }) => {
    return (
        <div className='main-div' id={cssId} >
            <button className={actionType === "Reject" ? "reject-action-button" : 'action-button'} style={{ cursor: disabled ? "not-allowed" : "pointer", border: highlightBtn ? onboardingStyle.border : "", boxShadow: highlightBtn ? onboardingStyle.boxShadow : "" }} disabled={disabled}>{label} {actionType !== "Print PDF" && actionType !== "Republish" && <KeyboardArrowDown />} </button>
        </div>
    )
}

export default JoyRideActionButton;
