import * as React from 'react';
import { Tooltip } from '@material-ui/core';
import './CustomIconButton.css';

const CustomIconButton = ({ icon, onHoverIcon, isIconClass, rounded, tooltipText, height, width, heightIcon, clicked = () => { } }) => {
    const [isHovered, setIsHovered] = React.useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <Tooltip title={tooltipText} placement="top" arrow>
            <div>
                <button onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={`custom-icon-button${isIconClass ? "-double-img" : "-single-img"}`}style={{ background: isHovered ? "#056d9e" : "#fffffd", borderRadius: rounded ? "100%" : "12px", height: height || "3rem", width: width || "3rem" }} onClick={clicked} >
                {!isIconClass && <img style={{ height: heightIcon || "2rem" }} src={icon} alt="icon" />}
                {isIconClass && <i className={"pi " + icon} style={{ color: isHovered ? "white" : '#056d9e', fontSize: heightIcon || "2rem" }}></i>}
                </button>
            </div>
        </Tooltip>
    )
}

export default CustomIconButton;
