/* eslint-disable camelcase */
import * as React from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import distLookup from "./data/dist_lookup.json";
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import bgImage from '../resources/pattern.png';
import locationIcon from '../resources/locationIcon.png';
import CustomButton from './Shared/CustomButton/CustomButton';
import { actionTypeMap, customThemes } from '../constants/app-constants';
import CustomSelectTwo from './Shared/CustomSelectTwo/CustomSelectTwo';
import CustomGroupSelectTwo from './Shared/CustomGroupSelectTwo/CustomGroupSelectTwo';

const drawerWidth = 302;
const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#DADCE0",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#DADCE0",
            borderWidth: "1px",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#DADCE0",
            },
            "&:hover fieldset": {
                borderColor: "#DADCE0",
                borderWidth: "1px",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#DADCE0",
                borderWidth: "1px",
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: drawerWidth,
    },
    textField2: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "80%"
    },
    textarea: {
        resize: "vertical"
    }
}))

const RepublishDialog = ({ dialogFlag, stateDistrictList, eventData, districtCategories, subdistrictCategories, finalDiseases, closeDialog = () => { }, republishData = () => { } }) => {
    const [message, setMessage] = React.useState(eventData.article.title + " " + eventData.article.description)
    const customStateDistrictList = stateDistrictList;
    var eventObj = eventData;
    const classes = useStyles();
    const [stateUpdated, setStateUpdated] = React.useState(true)
    const [numberCases, setNumberCases] = React.useState({
        caseKey: eventObj.number.case_new > 0 ? "new" : eventObj.number.case_total > 0 ? "total" : "new",
        deathKey: eventObj.number.death_new > 0 ? "new" : eventObj.number.death_total > 0 ? "total" : "new",
        case: {
          caseKey: eventObj.number.case_new > 0 ? eventObj.number.case_new : eventObj.number.case_total > 0 ? eventObj.number.case_total : "",
        },
        death: {
          deathKey: eventObj.number.death_new > 0 ? eventObj.number.death_new : eventObj.number.death_total > 0 ? eventObj.number.death_total : "",
        }
    });

    const closeDialogFun = () => {
        closeDialog(false)
    }

    const republishFun = () => {
        // if (eventObj.sub_district) {
        //     const subdistricts = subDistLookup[eventObj.district !== (null || "") ? eventObj.district.toUpperCase() : ""] !== undefined ? subDistLookup[eventObj.district !== (null || "") ? eventObj.district.toUpperCase() : ""] : []
        //     const selectedSubdistrict = subdistricts.flatMap(category => category.options)
        //                      .find(option => option.subdistrict_name === eventObj.sub_district);
        //     if (selectedSubdistrict) {
        //         eventObj.local_body_code = selectedSubdistrict.local_body_code
        //     }
        // }
        eventObj.message = message
        republishData(eventObj)
    }

    function handleDateChange (event) {
        var changedDate = event.target.value;
        eventObj.extracted_date = changedDate;
        setStateUpdated(!stateUpdated);
    }

    function handleDiseaseChange (event) {
        var changedDisease = event.target.value;
        eventObj.disease = changedDisease;
        eventObj.disease_macro_category = finalDiseases.find(item => item.items.find(item => item.value === changedDisease))?.label;
        setStateUpdated(!stateUpdated);
    }

    function handleNumberChange (event, type) {
        const number = event.target.value.replace(/[^0-9]/g, '');
        const numberObj = {};

        if (type === "case") {
          setNumberCases((prevCases) => ({
            ...prevCases,
            case: {
              caseKey: number,
            }
          }));
          numberObj[type + "_" + numberCases.caseKey] = number
        } else {
          setNumberCases((prevCases) => ({
            ...prevCases,
            death: {
              deathKey: number,
            }
          }));
          numberObj[type + "_" + numberCases.deathKey] = number
        }
        eventObj.number = { ...eventObj.number, ...numberObj };
        setStateUpdated(!stateUpdated);
    }

    function handleStateChange (idx, event) {
        var changedState = event
        var changeDistrict = distLookup[changedState][0]
        eventObj.state = changedState
        eventObj.district = changeDistrict
        eventObj.sub_district = ""
        setStateUpdated(!stateUpdated);
    }

    function handleDistrictChange (idx, event) {
        var changeDistrict = event;
        eventObj.district = changeDistrict;
        eventObj.sub_district = ""
        // const changedSubDistrict = subdistrictCategories[eventObj.state][changeDistrict][0]["value"]
        // eventObj.sub_district = capitalizeFirstLetter(changedSubDistrict)
        setStateUpdated(!stateUpdated);
    }

    function handleSubdistrictChange (idx, event) {
        eventObj.sub_district = event
        setStateUpdated(!stateUpdated);
    }

    function handleMessageChange (event) {
        setMessage(event.target.value)
        setStateUpdated(!stateUpdated);
    }

    function transformArray (originalArray) {
        if (!originalArray.length) return [];
        const transformedArray = originalArray
           .filter(item => item !== 'Other' || item !== "")
           .map(item => ({ name: item, value: item }));
        transformedArray.sort((a, b) => a.value.localeCompare(b.value));
        return transformedArray;
    }

    function subDistrictProvider () {
        if (eventObj.state && eventObj.district) {
            const stateSubdistricts = subdistrictCategories[eventObj.state];
            if (stateSubdistricts && eventObj.district in stateSubdistricts) {
                return stateSubdistricts[eventObj.district];
            }
        }
        return [];
    }

    return (
        <div>
            <Dialog
                open={dialogFlag}
                onClose={closeDialogFun}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'lg'}
            >
                <DialogTitle id="alert-dialog-title" style={{ backgroundImage: `url(` + bgImage + `)`, backgroundColor: "#D4EAF0", backgroundSize: "cover", padding: "1rem" }}>
                    <Grid container>
                        <Grid item lg={11} md={11} sm={11} style={{ fontSize: "x-large", display: "flex", alignItems: "center", fontWeight: "600" }}>
                            Republish Event
                        </Grid>
                        <Grid item lg={1} md={1} sm={1} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                            <IconButton onClick={() => closeDialogFun()}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Grid container style={{ padding: "15px", border: "1px solid #968c8c", borderRadius: "5px" }} spacing={2} >
                            <Grid item lg={3} md={3} sm={3} >
                                <Typography>Date</Typography>
                                <CssTextField
                                    type="date"
                                    size="small"
                                    variant="outlined"
                                    value={eventObj.extracted_date}
                                    onChange={(e) => handleDateChange(e)}
                                    className={classes.textField2}
                                    style={{ backgroundColor: "#F3FAFF", width: "100%" }}
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} >
                                <Typography>Disease Type</Typography>
                                <FormControl fullWidth size="small" style={{ width: "100%", backgroundColor: "#F3FAFF" }}>
                                    <Select
                                        variant="outlined"
                                        labelId="disease-label"
                                        defaultValue={eventObj.disease}
                                        onChange={(e) => handleDiseaseChange(e)}
                                    >
                                        {
                                            finalDiseases.flatMap(disease => disease.items).map(disease => disease.value).map((disease, j) =>
                                                <MenuItem key={disease} value={disease} >{disease}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={3} md={3} sm={3}>
                                <div>
                                    <Typography>Source: </Typography>
                                </div>
                                <Typography className='text-wrap'><a href={eventObj.url} target="_blank" rel="noopener noreferrer" style={{ color: "black" }}> {eventObj.url} </a></Typography>
                                {/* <Button onClick={() => setLoadedItems(eventObj.url, eventObj.id, i)} >Load Website</Button> */}
                            </Grid>
                            <Grid item lg={3} md={6} sm={6} >
                                <Grid container spacing={2} >
                                    <Grid item xl={6} lg={6} md={6} sm={6} >
                                        <Typography style={{ width: "116px", textWrap: "nowrap", overflow: "hidden", }}>Reported Cases</Typography>
                                        <CssTextField
                                            type="text"
                                            size="small"
                                            variant="outlined"
                                            value={numberCases.case.caseKey}
                                            onChange={(e) => handleNumberChange(e, 'case')}
                                            className={classes.textField2}
                                            style={{ backgroundColor: "#F3FAFF", width: "100%" }}
                                        />
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} >
                                        <Typography>Death</Typography>
                                        <CssTextField
                                            type="text"
                                            size="small"
                                            variant="outlined"
                                            value={numberCases.death.deathKey}
                                            onChange={(e) => handleNumberChange(e, 'death')}
                                            error={eventObj.incident === "death" && eventObj.number <= 0}
                                            className={classes.textField2}
                                            style={{ backgroundColor: "#F3FAFF", width: "100%" }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={3} md={6} sm={6} >
                                <Typography>Location: State</Typography>
                                <FormControl fullWidth size="small" style={{ width: "100%", backgroundColor: "#F3FAFF" }}>
                                    <CustomSelectTwo filter={true} icon={locationIcon} idx={0} placeholder={"Select State"} options={transformArray(Object.keys(districtCategories))} selectedOption={eventObj.state} setSelectedOption={handleStateChange} />
                                </FormControl>
                            </Grid>
                            <Grid item lg={3} md={6} sm={6} >
                                <Typography>Location: District</Typography>
                                <FormControl fullWidth size="small" style={{ width: "100%", backgroundColor: "#F3FAFF" }}>
                                    <CustomSelectTwo filter={true} showClear={true} icon={locationIcon} idx={0} placeholder={"Select District"} options={districtCategories[eventObj.state]} selectedOption={eventObj.district} setSelectedOption={handleDistrictChange} />
                                </FormControl>
                            </Grid>
                            <Grid item lg={3} md={6} sm={6} style={{ marginBottom: "20px" }}>
                                <Typography>Corporation / Sub District</Typography>
                                <FormControl fullWidth size="small" style={{ width: "100%", backgroundColor: "#F3FAFF" }}>
                                    <CustomGroupSelectTwo
                                        placeholder="Select Sub District / ULB"
                                        options={subDistrictProvider()}
                                        selectedValue={eventObj?.sub_district}
                                        setSelectedOption={handleSubdistrictChange}
                                        idx={0}
                                        icon={locationIcon}
                                        filter={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} >
                                <Typography>Message:</Typography>
                                <CssTextField
                                    multiline
                                    type="text"
                                    variant="outlined"
                                    value={message}
                                    onChange={(e) => handleMessageChange(e)}
                                    className={classes.textField2}
                                    style={{ backgroundColor: "#F3FAFF", width: "100%" }}
                                    inputProps={{ className: classes.textarea }}
                                />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} justifyContent="flex-end" style={{ marginTop: "1rem" }}>
                                <CustomButton label={actionTypeMap.publish} theme={customThemes.primary} clicked={republishFun} />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default RepublishDialog;
