import * as React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import "./CustomGroupMultiSelect.css";
import CustomActionButtonFour from '../CustomActionButtonFour/CustomActionButtonFour';

const groupedItemTemplate = (option) => {
    return (
        <div className="flex align-items-center">
            <div>{"name" in option ? option.name : option.label}</div>
        </div>
    );
};

const CustomGroupMultiSelect = ({ placeholder, optionsArr, icon, selectedOptions, selectAllPlaceholder, setSelectedOptions = () => { } }) => {
    const [someState, setSomeState] = React.useState(false);

    function getSelectedItemLabel () {
        const isContainEmptyValue = selectedOptions.indexOf("") !== -1;
        const finalSelectedItemLabel = (isContainEmptyValue ? selectedOptions.length - 1 : selectedOptions.length) + " " + selectAllPlaceholder + " selected";
        return finalSelectedItemLabel;
    }

    React.useEffect(() => {
        const pTag = document.createElement('p');
        pTag.textContent = 'Select All ';
        pTag.classList.add('appended-p-tag-class');
        const myElement = document.querySelector('.custom-multiselect .p-multiselect-header .p-checkbox');
        if (myElement) {
            const myPElement = document.querySelector('.appended-p-tag-class');
            if (!myPElement) {
                myElement.appendChild(pTag);
                return () => {
                    myElement.removeChild(pTag);
                };
            }
        }
    }, [someState]);

    function handletoggle () {
        setSomeState(!someState)
    }

    function saveAllSelects () {
        document.querySelector('body').click();
    }

    function getSaveButton () {
        return (
            <CustomActionButtonFour color="#FFFFFF" background="#78828C" disabled={false} label="Save" fontSize="14.473px" onClick={saveAllSelects}/>
        );
    }

    return (
        <div className="multiselect-wrapper">
            <div className="multiselect-icon-wrapper">
                <img src={icon} className="multiselect-icon" style={{ width: placeholder.includes("District") ? "0.9rem" : "" }} alt="icon" />
            </div>
            <MultiSelect value={selectedOptions} onChange={setSelectedOptions} options={optionsArr} optionLabel="name"
                filter placeholder={placeholder} optionGroupLabel="label" optionGroupChildren="items" optionGroupTemplate={groupedItemTemplate} maxSelectedLabels={2} className="w-full"
                appendTo="self" onBlur={handletoggle} panelClassName="custom-multiselect" selectedItemsLabel={getSelectedItemLabel()} panelFooterTemplate={getSaveButton()}
            />
        </div>
    );
}

export default CustomGroupMultiSelect;
