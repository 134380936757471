import React from 'react';
import './CustomActionButtonFour.css';

const CustomActionButtonFour = ({ color, background, disabled, fontSize, label, onClick = () => {} }) => {
    return (
        <div className="custom-action-button-four-wrapper">
            <button className='custom-action-button-four' onClick={onClick} style={{ color: color, background: background, border: `1px solid ` + background, fontSize: fontSize }} disabled={disabled}>{label}</button>
        </div>
    )
}

export default CustomActionButtonFour;
