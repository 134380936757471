import { Dialog, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import bgImage from '../../resources/pattern.png';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const PDFCompletedDialog = ({ showDialog, infoId, setShowDialog = () => {} }) => {
    const closeDialogFun = () => {
        setShowDialog(false)
    }

    return (
        <div>
            <Dialog
                open={showDialog}
                onClose={closeDialogFun}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ backgroundImage: `url(` + bgImage + `)`, backgroundColor: "#D4EAF0", backgroundSize: "cover", padding: "2rem", width: "35rem", maxWidth: "35rem", }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                        <div style={ { display: "flex", alignItems: "center", gap: "5px" }}>
                            <CheckCircleIcon style={{ color: "#16754C" }} />
                            <div style={{ fontSize: "x-large", display: "flex", alignItems: "center", fontWeight: "600", color: "#16754C" }}>
                                PDF Downloaded
                            </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "end", borderRadius: "50%" }}>
                            <button className='custom-close-button' onClick={() => closeDialogFun()}>
                                <Close />
                            </button>
                        </div>
                    </div>
                    <div style={{ background: "#fff", fontSize: "14px", padding: "8px 16px", borderRadius: "4px", marginTop: "20px", marginBottom: "1rem" }}>
                        <p>&lt;Report {infoId}&gt; has been saved in your computer.</p>
                    </div>
                </DialogTitle>
            </Dialog>
        </div>
    )
}

export default PDFCompletedDialog;
