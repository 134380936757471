import * as React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Close, } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import bgImage from '../resources/pattern.png';
import SingleCard from './Cards/SingleCard/SingleCard';
import { capitalizeFirstLetter } from '../actions/methods';

const ClusterDialog = ({ dialogFlag, token, stateUserFlag, districtCategories, subdistrictCategories, eventsList, content, aiTranslationFlag, selectedEventSubType, isValidEventData, diseaseCategories, setExtractedEventsState = () => { }, setShortlistedEventsState = () => { }, setPublishedEventsState = () => { }, setImportantNoEventArticles = () => { }, setRenderCards = () => { }, setPublishEventIndexState = () => { }, setShortlistEventIndexState = () => { }, setRepublishDialogBoxState = () => { }, setRejectEventIndexState = () => { }, setRemoveEventIndexState = () => { }, setSelectedEventData = () => { }, setSummaryDialogBoxState = () => { }, setSelectedSummaryData = () => { }, closeDialog = () => { }, sendClusterForApproval = () => { }, approveActionForCluster = () => { }, sendEmailForCluster = () => { }, printPublishPdf = () => { }, updateEventAsChecked = () => { }, unRejectCluster = () => { }, setIsValidEventData = () => { }, setShowRejectSourceDialog = () => { } }) => {
    const [stateUpdated, setStateUpdated] = React.useState(true);

    const isClusterDialog = true;

    var checkPublished = false
    var mainDataPoints = []
    if (content === "extractedEvents") {
        mainDataPoints = eventsList
        checkPublished = false
    } else if (content === "importatnNoEventArticles") {
        mainDataPoints = eventsList
        checkPublished = false
    } else if (content === "shortlistedEvents") {
        mainDataPoints = eventsList
        checkPublished = false
    } else if (content === "publishedEvents") {
        mainDataPoints = eventsList
        checkPublished = true
    }
    const handleFinalDiseaseChange = (index, event, category) => {
        var changedDisease = event
        var data = mainDataPoints
        data[index].disease = changedDisease
        data[index].disease_macro_category = category
        if (content === "extractedEvents") {
            setExtractedEventsState(data)
        } else if (content === "importatnNoEventArticles") {
            setImportantNoEventArticles(data)
        } else if (content === "shortlistedEvents") {
            setShortlistedEventsState(data)
        } else if (content === "publishedEvents") {
            setPublishedEventsState(data)
        }
        setStateUpdated(!stateUpdated)
    }

    const handleDistrictChange = (index, event) => {
        var changeDistrict = event
        var data = mainDataPoints
        data[index].district = changeDistrict
        data[index].sub_district = ""
        // const changedSubDistrict = subdistrictCategories[mainDataPoints[index].state][changeDistrict].length > 0 ? subdistrictCategories[mainDataPoints[index].state][changeDistrict][0]["value"] : ""
        // data[index].sub_district = capitalizeFirstLetter(changedSubDistrict)
        setStateUpdated(!stateUpdated)
    }
    const handleDateChange = (index, event) => {
        var changedDate = event.target.value
        var data = mainDataPoints
        data[index].extracted_date = changedDate
        if (content === "extractedEvents") {
            setExtractedEventsState(data)
        } else if (content === "importatnNoEventArticles") {
            setImportantNoEventArticles(data)
        } else if (content === "shortlistedEvents") {
            setShortlistedEventsState(data)
        } else if (content === "publishedEvents") {
            setPublishedEventsState(data)
        }
        setStateUpdated(!stateUpdated)
    }

    const handleNumberChange = (index, numberObj) => {
        var data = mainDataPoints
        data[index].number = { ...data[index].number, ...numberObj }
        if (content === "extractedEvents") {
            setExtractedEventsState(data)
        } else if (content === "importatnNoEventArticles") {
            setImportantNoEventArticles(data)
        } else if (content === "shortlistedEvents") {
            setShortlistedEventsState(data)
        } else if (content === "publishedEvents") {
            setPublishedEventsState(data)
        }
        setStateUpdated(!stateUpdated)
    }

    const handleStateChange = (index, event) => {
        var changedState = event
        var changeDistrict = districtCategories[changedState][0].value
        var data = mainDataPoints
        data[index].state = changedState
        data[index].district = changeDistrict
        data[index].sub_district = ""
        if (content === "extractedEvents") {
            setExtractedEventsState(data)
        } else if (content === "importatnNoEventArticles") {
            setImportantNoEventArticles(data)
        } else if (content === "shortlistedEvents") {
            setShortlistedEventsState(data)
        } else if (content === "publishedEvents") {
            setPublishedEventsState(data)
        }
        setStateUpdated(!stateUpdated)
    }

    const handleSubdistrictChange = (index, event) => {
        var data = mainDataPoints
        data[index].sub_district = event
        setStateUpdated(!stateUpdated)
    }

    const handleMessageChange = (index, event) => {
        // var changedMessage = event.target.value
        // var data = mainDataPoints
        // data[index].message = changedMessage
        // if (content === "extractedEvents") {
        //     setExtractedEventsState(data)
        // } else if (content === "importatnNoEventArticles") {
        //     setImportantNoEventArticles(data)
        // } else if (content === "shortlistedEvents") {
        //     setShortlistedEventsState(data)
        // } else if (content === "publishedEvents") {
        //     setPublishedEventsState(data)
        // }
        // setStateUpdated(!stateUpdated)
    }

    function onPublishSetIndex (data, type) {
        const category = diseaseCategories.find(item => item.items.find(item => item.value === data.disease))?.label;
        data.disease_macro_category = category;
        setPublishEventIndexState([data, type])
    }
    function onShortistSetIndex (data, type) {
        const category = diseaseCategories.find(item => item.items.find(item => item.value === data.disease))?.label;
        data.disease_macro_category = category;
        setShortlistEventIndexState([data, type])
    }

    function onRejectSetIndex (index) {
        setRejectEventIndexState(index)
    }

    function onRemoveSetIndex (data) {
        setRemoveEventIndexState(data)
    }

    function openPublishDialog (flag, data) {
        setRepublishDialogBoxState(flag)
        setSelectedEventData(data)
    }

    function openSummaryDialog (flag, data) {
        setSelectedSummaryData(data)
        setSummaryDialogBoxState(flag)
    }

    const closeDialogFun = () => {
        closeDialog(false)
    }

    function getCardTitle (eventData) {
        if (eventData.district !== "") {
            return `${eventData.number > 0 ? eventData.number : ""} ${eventData.incident} of ${eventData.disease} disease found in ${eventData.district} of ${eventData.state} state`
        } else if (eventData.state !== "") {
            return `${eventData.number > 0 ? eventData.number : ""} ${eventData.incident} of ${eventData.disease} disease found in ${eventData.state} state`
        } else {
            return `${eventData.number > 0 ? eventData.number : ""} ${eventData.incident} of ${eventData.disease} disease found`
        }
    }
    function getDialogTitle (eventData) {
        if (eventData.district !== "") {
            return `${eventData.number > 0 ? eventData.number : ""} ${eventData.incident} of ${eventData.disease} disease found in ${eventData.district} of ${eventData.state} state :`
        } else if (eventData.state !== "") {
            return `${eventData.number > 0 ? eventData.number : ""} ${eventData.incident} of ${eventData.disease} disease found in ${eventData.state} state :`
        } else {
            return `${eventData.number > 0 ? eventData.number : ""} ${eventData.incident} of ${eventData.disease} disease found :`
        }
    }

    return (
        <div>
            <Dialog
                open={dialogFlag}
                onClose={closeDialogFun}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'lg'}
            >
                <DialogTitle id="alert-dialog-title" style={{ backgroundImage: `url(` + bgImage + `)`, backgroundColor: "#D4EAF0", backgroundSize: "cover", padding: "1rem" }}>
                    <Grid container>
                        <Grid item lg={11} md={11} sm={11} style={{ display: "flex", alignItems: "center", color: "#008BCA", fontSize: "x-large", fontWeight: "700" }}>
                            <div style={{ textTransform: "capitalize" }}>
                                <span>{getDialogTitle(mainDataPoints[0]) + " "}</span>
                                <span style={{ color: "#413F40" }}>
                                    reports found in {mainDataPoints.length} Sources
                                </span>
                            </div>
                        </Grid>
                        <Grid item lg={1} md={1} sm={1} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                            <IconButton onClick={() => closeDialogFun()}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div>
                        {
                            mainDataPoints.length === 0 && content !== "main" &&
                            <Typography>No Events </Typography>
                        }
                        {
                            mainDataPoints.length > 0 &&
                            <div>
                                <div>
                                    {
                                        mainDataPoints.map((mainDataPoint, idx) =>
                                            <SingleCard mainCardFlag={false} key={idx} mainDataPoint={mainDataPoint} idx={idx} content={content} districtCategories={districtCategories} subdistrictCategories={subdistrictCategories} checkPublished={checkPublished} stateUserFlag={stateUserFlag} stateUpdated={stateUpdated} aiTranslationFlag={aiTranslationFlag} countEvents={mainDataPoints.length} selectedEventSubType={selectedEventSubType} clusterDialog={isClusterDialog} isValidEventData={isValidEventData} diseaseCategories={diseaseCategories} getTitle={getCardTitle} handleDateChange={handleDateChange} handleFinalDiseaseChange={handleFinalDiseaseChange} handleNumberChange={handleNumberChange} handleStateChange={handleStateChange} handleDistrictChange={handleDistrictChange} handleSubdistrictChange={handleSubdistrictChange} handleMessageChange={handleMessageChange} capitalizeFirstLetter={capitalizeFirstLetter} onRejectSetIndex={onRejectSetIndex} onRemoveSetIndex={onRemoveSetIndex} onShortistSetIndex={onShortistSetIndex} onPublishSetIndex={onPublishSetIndex} openPublishDialog={openPublishDialog} openSummaryDialog={openSummaryDialog} sendClusterForApproval={sendClusterForApproval} approveActionForCluster={approveActionForCluster} sendEmailForCluster={sendEmailForCluster} printPublishPdf={printPublishPdf} updateEventAsChecked={updateEventAsChecked} unRejectCluster={unRejectCluster} setIsValidEventData={setIsValidEventData} setShowRejectSourceDialog={setShowRejectSourceDialog} />
                                        )
                                    }
                                </div>
                                <Divider />
                            </div>
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
export default ClusterDialog;
