import moment from "moment";

export const mediaAlertDummyData = {
        id: "64e08259e63e90e6ab0aca50",
        username: "",
        extracted_date: moment(new Date()).format("YYYY-MM-DD"),
        shortlist_date: moment(new Date()).format("YYYY-MM-DD"),
        published_date: moment(new Date()).format("YYYY-MM-DD"),
        location: "Jalandhar, Punjab",
        state: "Punjab",
        district: "Jalandhar",
        sub_district: "Jalandhar - I",
        disease: "Animal Bite - Snake Bite",
        incident: "death",
        incident_type: "",
        number: 2,
        url: "http://encounterindia.in/Jalandhar:-One-died-due-to-snake-bite-on-father-and-son-watch-video",
        article: {
            title: "जालंधरः पिता और बेटे को सांप के डंसने से एक की मौत, देखें वीडियो - Encounter India",
            description: " "
        },
        message: "",
        cluster_count: 3,
        cluster_id: "kMz8DIoBjuOAFaZ2IRoU?sub_cluster?0",
        parent_type: null,
        translated_article: {
            title: "Jalandhar: Father, son die of snakebite, video goes viral Encounter India",
            description: ""
        },
        language: "Hindi",
        source_type: "newspaper",
        health_information: false,
        approve_value: null,
        reject_value: null,
        read_by: null,
        outbreak_data: {
            id: "64e7149735d5d6ad754f7b57",
            title: "See Status of Investigation Requirement",
            event_timeline: {
                event_date: "2023-08-24T13:35:03",
                extracted_date: "2023-08-24T13:35:03",
                shortlist_date: "2023-08-25T10:45:41.100000",
                published_date: "2023-08-25T10:46:36.440000"
            },
            investigation_required: null,
            preliminary_investigation: null,
            outbreak_reported_date: null,
            outbreak_start_date: null,
            cases: null,
            deaths: null,
            final_health_condition_name: null,
            sso_verified_status: null,
            sso_verified_date: null,
            outbreak_status: "Pending"
        }
    }
