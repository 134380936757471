import * as React from 'react';

import './CustomSubTabsTwo.css';
import CustomLoader from '../CustomLoader/CustomLoader';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

const CustomSubTabsTwo = ({ items, selectedItem, cssId, showCountFlag, width, saveSelection = () => {} }) => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [tabDropdownSelection, setTabDropdownSelection] = React.useState({
    type: 'All Event Stages',
    code: '9',
    count: -1,
  });
  const menuRef = React.useRef(null);

  const getDropdownItems = React.useCallback(() => {
    return items.filter((item) => item !== items[0] && item !== items[items.length - 1]);
  }, [items]);

  React.useEffect(() => {
    if (items[1].type === 'All Event Stages' && items[1].count >= 0) {
      setTabDropdownSelection(items[1]);
    }
  }, [items]);

  React.useEffect(() => {
    if (getDropdownItems().includes(selectedItem)) {
      setTabDropdownSelection(selectedItem);
    }
  }, [getDropdownItems, selectedItem]);

  const renderCountElement = (count) => {
    if (showCountFlag && count >= 0) {
      return <div style={{ marginLeft: '10px' }}>({count})</div>;
    } else if (showCountFlag && count < 0) {
      return (
        <div style={{ marginLeft: '10px' }}>
          <CustomLoader color='#414c5b' />
        </div>
      );
    }
  };

  function handleClickOutside (event) {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowDropdown(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div className='sub-tabs-two-main-div' id={cssId} ref={menuRef}>
      <div
        className={`sub-tab-item-two text-wrap sub-item-start-two`}
        style={{
          background: items[0].type === selectedItem.type ? '#A3D6EE' : '',
        }}
        onClick={() => {
          setShowDropdown(false)
          saveSelection(items[0])
          }
        }
      >
        <div className={'text-wrap'}>{items[0].type}</div>
        {renderCountElement(items[0].count)}
      </div>
      <div
        className={`sub-tab-item-two text-wrap sub-item-middle-two`}
        style={{
          background: getDropdownItems().includes(selectedItem) ? '#A3D6EE' : '',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => {
            setShowDropdown(false)
            saveSelection(tabDropdownSelection)
          }}
        >
          <div className={'text-wrap'} >
            {tabDropdownSelection.type}
          </div>
          {renderCountElement(items.filter(itm => itm.code === tabDropdownSelection.code)[0]?.count)}
        </div>
        <div>
          <CustomDropdown items={getDropdownItems()} showCountFlag={showCountFlag} selectedItem={tabDropdownSelection} setSelectedItem={saveSelection} setTabDropdownSelection={setTabDropdownSelection} showDropdown={showDropdown} setShowDropdown={setShowDropdown} />
        </div>
      </div>
      <div
        className={`sub-tab-item-two text-wrap sub-item-end-two`}
        style={{
          background: items[items.length - 1].type === selectedItem.type ? '#A3D6EE' : '',
        }}
        onClick={() => {
          setShowDropdown(false)
          saveSelection(items[items.length - 1])
}
        }
      >
        <div className={'text-wrap'}>{items[items.length - 1].type}</div>
        {renderCountElement(items[items.length - 1].count)}
      </div>
    </div>
  );
};

const CustomDropdown = ({ items, showDropdown, selectedItem, showCountFlag, setTabDropdownSelection = () => {}, setSelectedItem = () => {}, setShowDropdown = () => {} }) => {
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setTabDropdownSelection(item);
    setShowDropdown(false);
  };

  return (
    <div className='sub-tabs-two-dropdown'>
      <button
        onClick={toggleDropdown}
        style={{
          background: 'inherit',
          display: 'flex',
          alignItems: 'center',
          padding: 0,
        }}
      >
        {!showDropdown && <KeyboardArrowDown />}
        {showDropdown && <KeyboardArrowUp />}
      </button>
      {showDropdown && (
        <ul className='sub-tabs-two-dropdown-menu'>
          {items.map((item, index) => (
            <li key={index} onClick={() => handleItemClick(item)}>
              <div className={item.code === selectedItem.code ? 'sub-tab-dropdwon-select-item' : 'sub-tab-dropdwon-item'}>
                <div style={{ display: 'flex' }}>
                  {item.type}
                  {showCountFlag && item.count >= 0 && <div style={{ marginLeft: '10px' }}>({item.count})</div>}
                  {showCountFlag && item.count < 0 && (
                    <div style={{ marginLeft: '10px' }}>
                      <CustomLoader color='#414c5b' />
                    </div>
                  )}
                </div>
                {item.code === selectedItem.code && <i style={{ color: '#008BCA', fontWeight: 1000 }} className='pi pi-check'></i>}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSubTabsTwo;
