
export const skeletonData = {
    type1: [
        { id: 0, content: "Title" }
    ],
    type2: [
        { id: 0, content: "Event Date" },
        { id: 1, content: "Date" },
        { id: 2, content: "Disease Type" },
        { id: 3, content: "State" },
        { id: 4, content: "Numer of Cases" },
        { id: 5, content: "Death" },
        { id: 6, content: "Source" },
        { id: 7, content: "District" },
    ],
    type3: [
        { id: 0, content: "Original Message" },
    ],
    type4: [
        { id: 0, content: "" },
        { id: 1, content: "" },
        { id: 2, content: "" },
    ],
    type5: [
        { id: 0, content: "" },
        { id: 1, content: "" },
    ],
    type6: [
        { id: 0, content: "Date" },
        { id: 1, content: "Disease Type" },
        { id: 2, content: "Source" },
        { id: 3, content: "Reported Cases" },
        { id: 4, content: "State" },
        { id: 5, content: "District" },
    ]
}
