export const FilterDummyData = {
    extractedNoOfPages: 8,
    shortlistedNoOfPages: 1,
    publishedNoOfPages: 1,
    page: 1,
    extractedEventSubTypes: [
        {
            type: "All Media List",
            code: "0",
            count: 75
        },
        {
            type: "Unusual Health Events",
            code: "1",
            count: 75
        },
        {
            type: "Rejected",
            code: "3",
            count: 0
        }
    ],
    shortlistedEventSubTypes: [
        {
            type: "All Shortlisted",
            code: "0",
            count: 4
        },
        {
            type: "Health Event",
            code: "4",
            count: 1
        },
        {
            type: "Health Information",
            code: "2",
            count: 0
        },
        {
            type: "Rejected",
            code: "3",
            count: 0
        }
    ],
    publishedEventSubTypes: [
        {
            type: "All Published",
            code: "0",
            count: 3
        },
        {
            type: "Published Events",
            code: "7",
            count: 3
        },
        {
            type: "Health Information",
            code: "2",
            count: 0
        }
    ],
    extractedTotalEventsCount: 75,
    shortlistedTotalEventsCount: 5,
    publishedTotalEventsCount: 3,
}
