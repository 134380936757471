import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@material-ui/icons';
import * as React from 'react';
import downExpandButton from "../../../resources/downExpandButton.png";
import upExpandButton from "../../../resources/upExpandButton.png";
import "./CustomSeeDetailsButton.css";

const CustomSeeDetailsButton = ({ theme, expandFlag, setExpandFlag }) => {
    return (
        <button className='custom-expand-button' style={{ background: theme === "primary" ? "#D4EAF0" : "transparent" }} onClick={() => setExpandFlag(!expandFlag)}>
            See Details
            {!expandFlag && theme === "primary" && <img src={downExpandButton} style={{ marginLeft: "5px", height: "1rem" }} alt="expand-icon" />}
            {expandFlag && theme === "primary" && <img src={upExpandButton} style={{ marginLeft: "5px", height: "1rem" }} alt="expand-icon" />}
            {!expandFlag && theme !== "primary" && <KeyboardArrowDownOutlined />}
            {expandFlag && theme !== "primary" && <KeyboardArrowUpOutlined />}
        </button>
    )
}

export default CustomSeeDetailsButton;
