export const onboardingSelectorIds = {
    filter: "dashboard-filter-container",
    aiTranslation: "ai-translate-article-container",
    singleCard: "single-card-joyride-container",
    filterSubTab: "subtab-joyride-container",
    shortListTab: "shortlist-tab-joyride-container",
    publishTab: "publish-tab-joyride-container",
    printPdf: "print-pdf-button-joyride-container",
    outbreakStatus: "outbreak-status-joyride-container",
    helpButton: "help-button-container",
    secondTargetContainer: "joyride-tooltip-second-target-container",
    secondTarget: "joyride-tooltip-second-target",
    filterContaner: "joyride-filter-container",
}
