import * as React from 'react';
import { IconButton, Snackbar } from '@material-ui/core';
import { useStyles } from '../../../constants/app-constants';
import CloseIcon from '@material-ui/icons/Close';

import "./CustomSnackBar.css";

const CustomSnackBar = ({ openFlag, successFlag, successText, failedText, closeFun = () => { } }) => {
    const classes = useStyles();
    return (
        <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={openFlag}
              autoHideDuration={6000}
              onClose={closeFun}
              message={successFlag === true ? successText : failedText}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  style={{ padding: "1px" }}
                  onClick={closeFun}
                >
                <CloseIcon />
                </IconButton>
              }
              ContentProps={{
                classes: {
                  root: successFlag === true ? classes.snackbar : classes.failedsnackbar
                }
              }}
            />
    )
}

export default CustomSnackBar;
