import * as React from 'react';

import "./CustomSubTabs.css";
import CustomLoader from '../CustomLoader/CustomLoader';

const CustomSubTabs = ({ items, selectedItem, cssId, showCountFlag, width, saveSelection = () => { } }) => {
    return (
        <div className='sub-tabs-main-div' id={cssId}>
            {items.map((item, idx) => {
                return (
                    <div key={idx} className={`sub-tab-item text-wrap ${(idx === 0) ? 'sub-item-start' : (idx === items.length - 1) ? 'sub-item-end' : 'sub-item-middle'}`} style={{ background: item.type === selectedItem.type ? "#A3D6EE" : "" }} onClick={() => saveSelection(item)}>
                        <div className={'text-wrap'}>{item.type}</div>
                        {showCountFlag && item.count >= 0 && <div style={{ marginLeft: "10px" }}>({item.count})</div>}
                        {showCountFlag && item.count < 0 && <div style={{ marginLeft: "10px" }}><CustomLoader color="#414c5b" /></div>}
                    </div>
                )
            })}
        </div>
    )
}

export default CustomSubTabs;
