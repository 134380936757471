import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@material-ui/icons';
import * as React from 'react';
import "./CustomCollapseButton.css";

const CustomCollapseButton = ({ collapseFlag, setCollapseFlag = () => { } }) => {
    return (
        <button className='custom-collpase-button' style={{ background: !collapseFlag ? "#2b87b5" : "", color: !collapseFlag ? "#e6f4f9" : "" }} onClick={() => setCollapseFlag(!collapseFlag)}>{collapseFlag && <KeyboardArrowDownOutlined />} {!collapseFlag && <KeyboardArrowUpOutlined />}</button>
    )
}

export default CustomCollapseButton;
