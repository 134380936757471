import { Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import * as React from 'react';
import CustomLoader from '../CustomLoader/CustomLoader';
import "./CustomEventsCounter.css";

const CustomEventsCounter = ({ count, eventType, tootTipFlag, tooltipText, showLoader, }) => {
    const getCountLabel = (function () {
        if (count > 1000 && count < 10000) {
            return (count / 1000).toFixed(1) + "K"
        } else if (count > 10000) {
            return (count / 1000).toFixed(0) + "K"
        }
        return count
    })();

    return (
        <div className='count-div'>
            {!showLoader && <div className='count-number'>{getCountLabel}</div>}
            {showLoader && <CustomLoader color="#414c5b" size={30} />}
            <div className='count-label'>
                <span>{eventType}</span>
                <span>Events</span>
            </div>
            {tootTipFlag && count > 0 && <Tooltip title={tooltipText} placement="top" arrow>
                <InfoOutlined className='count-info-icon' />
            </Tooltip>}
        </div>
    )
}

export default CustomEventsCounter;
