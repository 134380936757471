import * as React from 'react';
import "./CustomActionButtonTwo.css";

export const CustomActionButtonTwo = ({ label, data, theme, emailFlag, emailID, disabled, actionPerformed = () => {} }) => {
    return (
        <div>
        {
            emailFlag && theme === "primary" && <button className={'action-button-primary'} style={{ cursor: disabled ? "not-allowed" : "pointer" }} onClick={() => actionPerformed(true, data)} disabled={disabled}><a style={{ textDecoration: "none", color: "inherit", pointerEvents: "none" }} href={"mailto: " + emailID}>{label}</a></button>
        }
        {
            emailFlag && theme === "outlined" && <button className={'action-button-outlined'} style={{ cursor: disabled ? "not-allowed" : "pointer" }} onClick={() => actionPerformed(true, data)} disabled={disabled}><a style={{ textDecoration: "none", color: "inherit", pointerEvents: "none" }} href={"mailto: " + emailID}>{label}</a></button>
        }
        {
            !emailFlag && theme === "primary" && <button className={'action-button-primary'} style={{ cursor: disabled ? "not-allowed" : "pointer" }} onClick={() => actionPerformed(true, data)} disabled={disabled}>{label}</button>
        }
        {
            !emailFlag && theme === "outlined" && <button className={'action-button-outlined'} style={{ cursor: disabled ? "not-allowed" : "pointer" }} onClick={() => actionPerformed(true, data)} disabled={disabled}>{label}</button>
        }
        </div>
    )
}

export default CustomActionButtonTwo;
