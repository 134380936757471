import React from 'react';
import { Skeleton } from 'primereact/skeleton';

import "./CustomSkeleton.css";
import { Paper } from '@material-ui/core';
import { CssTypography } from '../../../constants/app-constants';

const CustomSkeleton = ({ type1, type2, type3, type4, type5, type6, hasOutOfCount, background, width }) => {
    return (
        <Paper className={`customPaper customPaperShort`} style={{ marginBottom: "20px", backgroundColor: background || "white", boxShadow: "5px 5px 10px lightgray", borderRadius: "10px", width: width || "100%" }}>
            <div className="card" style={{ padding: "10px 30px 15px" }}>
                <div className="w-full lg:w-12 md:w-12 sm::w-12">
                    {type1 && type1.map(type => <h1 key={type.id} style={{ textTransform: "capitalize", color: "transparent" }}>{type.content}</h1>)}
                </div>
                <div className="flex flex-wrap">
                    {type6 && type6.map(type => (
                        type.id % 3 === 0 ? <div key={type.id} className="w-full lg:w-3 md:w-3 sm:w-6 py-2">
                            <CssTypography>{type.content}</CssTypography>
                            <Skeleton key={type.id} height="40px" width="85%" className="mb-1" borderRadius="12px"></Skeleton>
                        </div>
                        : type.id % 3 === 1 ? <div key={type.id} className="w-full lg:w-5 md:w-5 sm:w-6 py-2">
                            <CssTypography>{type.content}</CssTypography>
                            <Skeleton key={type.id} height="40px" width="90%" className="mb-1" borderRadius="12px"></Skeleton>
                        </div>
                        : type.id % 3 === 2 && <div key={type.id} className="w-full lg:w-4 md:w-4 sm:w-6 py-2">
                            <CssTypography>{type.content}</CssTypography>
                            <Skeleton key={type.id} height="40px" width="100%" className="mb-1" borderRadius="12px"></Skeleton>
                        </div>
                        )
                    )}
                </div>
                <div className="flex flex-wrap">
                    {type2 && type2.map(type => (
                        type.id % 4 === 0 ? <div key={type.id} className="w-full lg:w-2 md:w-2 sm:w-4 py-2">
                            <CssTypography>{type.content}</CssTypography>
                            <Skeleton key={type.id} height="40px" width="90%" className="mb-1" borderRadius="12px"></Skeleton>
                        </div>
                        : type.id % 4 === 1 ? <div key={type.id} className="w-full lg:w-2 md:w-2 sm:w-4 py-2">
                            <CssTypography>{type.content}</CssTypography>
                            <Skeleton key={type.id} height="40px" width="90%" className="mb-1" borderRadius="12px"></Skeleton>
                        </div>
                        : type.id % 4 === 2 ? <div key={type.id} className="w-full lg:w-4 md:w-4 sm:w-4 py-2">
                            <CssTypography>{type.content}</CssTypography>
                            <Skeleton key={type.id} height="40px" width="95%" className="mb-1" borderRadius="12px"></Skeleton>
                        </div>
                        : type.id % 4 === 3 && <div key={type.id} className="w-full lg:w-4 md:w-4 sm:w-4 py-2">
                            <CssTypography>{type.content}</CssTypography>
                            <Skeleton key={type.id} height="40px" width="100%" className="mb-1" borderRadius="12px"></Skeleton>                            </div>
                        )
                    )}
                </div>
                {type3 && type3.map(type =>
                    <div key={type.id} className="w-full lg:w-12 md:w-12 sm:w-12 py-2">
                        <CssTypography>{type.content}: </CssTypography>
                        <Skeleton height="4rem" width="100%" className="mb-2" borderRadius="12px"></Skeleton>
                    </div>
                )}
                <div className="flex flex-wrap mt-3">
                    <div className="w-full lg:w-6 md:w-6 sm:w-6 py-2">
                        <div className="flex align-items-center justify-content-start">
                            {hasOutOfCount && <p style={{ textAlign: "center", width: "40px", fontSize: "large", fontWeight: "400", margin: "0 10px 0 0" }}> </p>}
                            {type4 && type4.map(type => <Skeleton key={type.id} height="27.5px" width="140px" className="mr-2" borderRadius='5px'></Skeleton>)}
                        </div>
                    </div>
                    <div className="w-full lg:w-6 md:w-6 sm:w-6 py-2">
                    <div className="flex align-items-center justify-content-end">
                        {type5 && type5.map(type => <Skeleton key={type.id} height="41px" width="134px" className="mr-2" borderRadius="50px"></Skeleton>)}
                        </div>
                    </div>
                </div>
            </div>
        </Paper>
    );
}

export default CustomSkeleton;
