import handGifIcon from '../../resources/handGif.gif'
import lottifyExploreIcon from '../../resources/lottifyExploreIcon.json'
import nextArrowGif from '../../resources/nextArrowGif.gif'
import bodyImg1 from '../../resources/tutorial2ndPgBody.png'

export const FirstPageData = {
    headerIcon: handGifIcon,
    heading: "Welcome to IDSP!",
    bodyText: "Let us take you through the AI powered Event based Surveillance system that assists you in media scanning for early warning signals.",
    buttonText: "Let’s Explore",
    preBtnTxtIcon: {
        type: "lottify",
        icon: lottifyExploreIcon
    },
}

export const SecondPageData = {
    bodyImg: bodyImg1,
    buttonText: "Next",
    postBtnTxtIcon: {
        type: "gif",
        icon: nextArrowGif
    },
}

// export const ThirdPageData = {
//     headerIcon,
//     heading,
//     bodyText,
//     subBodyText,
//     bodyImg,
//     buttonText,
//     preBtnTxtIcon: {
//         type,
//         icon
//     },
//     postBtnTxtIcon,
// }
