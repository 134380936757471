import * as React from 'react';
import "./CustomSearchButton.css";

const CustomSearchButton = ({ icon, text, collapseFlag, search = () => {}, setCollapseFlag = () => {} }) => {
    const clickHandler = () => {
        search();
        setCollapseFlag(!collapseFlag)
    }

    return (
        // <button className='custom-search-button' onClick={() => { search() }}>{icon} <span style={{marginLeft: "5px"}}>{text}</span></button>
        <button className='custom-search-button' onClick={clickHandler}>{text}</button>
    )
}

export default CustomSearchButton;
