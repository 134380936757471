import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import filterIcon from "../../../resources/filterIcon.png";
import locationIcon from "../../../resources/locationIcon.png";
import diseaseIcon from "../../../resources/diseaseIcon.png";
import aiTranslatedIcon from "../../../resources/aiTranslatedIcon.png";
import newspaperIcon from "../../../resources/newspaperIcon.png";
import CustomPaginator from '../../../components/Shared/CustomPaginator/CustomPaginator';
import SearchIcon from '@material-ui/icons/Search';
import CustomMultiSelect from '../../Shared/CustomMultiSelect/CustomMultiSelect';
import CustomGroupMultiSelect from '../../Shared/CustomGroupMultiSelect/CustomGroupMultiSelect';
import moment from "moment";
import CustomIconButton from '../../Shared/CustomIconButton/CustomIconButton';
import CustomSelect from '../../Shared/CustomSelect/CustomSelect';
import CustomDateTimePicker from '../../Shared/CustomDateTimePicker/CustomDateTimePicker';
import { onboardingSelectorIds } from '../../../constants/Onboarding-Constants/idSelector';
import { eventTypeMap } from '../../../constants/app-constants';
import CustomCollapseButton from '../../Shared/CustomCollapseButton/CustomCollapseButton';
import CustomEventsCounter from '../../Shared/CustomEventsCounter/CustomEventsCounter';
import CustomSearchButton from '../../Shared/CustomSearchButton/CustomSearchButton';
import CustomToggleButton from '../../Shared/CustomToggleButton/CustomToggleButton';
import CustomSubTabs from '../../Shared/CustomSubTabs/CustomSubTabs';
import { FilterDummyData } from '../../../constants/Onboarding-Constants/filterDummyData';

const StyledTypography = withStyles({
    root: {
        fontWeight: "700",
        fontSize: "16px",
        color: "#414C5B !important",
        marginBottom: "5px"
    }
})(Typography);

const StyledTypographyTwo = withStyles({
    root: {
        fontWeight: "700",
        fontSize: "18px",
        color: "#989898 !important",
    }
})(Typography);

const StyledTypographyThree = withStyles({
    root: {
        fontWeight: "400",
        fontSize: "16px",
        color: "#989898 !important",
    }
})(Typography);

const JoyRideFilterSection = ({ fromDateFilter, toDateFilter, selectedDiseaseCategory, diseaseCategories, selectedStateCategory, stateCategories, selectedDistrictCategory, districtCategories, selectedLanguage, languageList, sourceCategories, selectedSourceCategory, content, countEvents, noOfPages, page, aiTranslationFlag, renderCards, collapseFlag, eventSubTypes, selectedEventSubType, sortTypeCategories, selectedSortType, totalEventsCount, handleFromDateChange = () => { }, handleToDateChange = () => { }, handleDiseaseCategoryChange = () => { }, handleStateCategoryChange = () => { }, handleDistrictCategoryChange = () => { }, handleLanguageChange = () => { }, handleSourceChange = () => { }, fetchEvents = () => { }, openCustomPublishDialog = () => { }, handlePageChange = () => { }, setAiTranslationFlag = () => { }, setCollapseFlag = () => { }, setSelectedEventSubType = () => { }, downloadReport = () => { }, setSelectedSortType = () => { } }) => {
    function getAverageCount () {
        const lambda = 35000 * ((new Date(toDateFilter).getTime() - new Date(fromDateFilter).getTime()) / (1000 * 3600 * 24));
        return "AI tool has reviewed " + ((Math.random() * 1000) + lambda).toFixed(0) + " articles for given date range"
    }

    return (
        <Grid container id={onboardingSelectorIds.filter}>
            <Grid item lg={11} md={11} sm={11} style={{ display: "flex", alignItems: "center", justifyContent: "start", cursor: "pointer" }} onClick={() => setCollapseFlag(false)}>
                <Typography
                    style={{
                        fontSize: "30px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "36.31px",
                        textAlign: "left",
                        marginTop: "15px",
                        marginBottom: "10px",
                        marginLeft: "25px"
                    }}
                >
                    <img src={filterIcon} style={{ marginRight: "1rem", height: "1.3rem" }} alt="filter-logo" />Filters</Typography>
                {collapseFlag && <Typography
                    style={{
                        fontSize: "30px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "36.31px",
                        textAlign: "left",
                        marginTop: "15px",
                        marginBottom: "10px",
                        marginLeft: "5px"
                    }}
                >/</Typography>}
                {collapseFlag && <div style={{
                    marginTop: "20px",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px"
                }}>
                    <StyledTypographyTwo>Date:</StyledTypographyTwo>
                    <StyledTypographyThree style={{ marginLeft: "5px" }}>{moment(fromDateFilter).format("MMM DD, yyyy")}</StyledTypographyThree>
                    <StyledTypographyThree style={{ marginLeft: "5px", marginRight: "5px" }}> - {moment(toDateFilter).format("MMM DD, yyyy")}</StyledTypographyThree>
                    <StyledTypographyTwo>| Time:</StyledTypographyTwo>
                    <StyledTypographyThree style={{ marginLeft: "5px" }}>{moment(fromDateFilter).format("hh:mm a")}</StyledTypographyThree>
                    <StyledTypographyThree style={{ marginLeft: "5px" }}> - {moment(toDateFilter).format("hh:mm a")}</StyledTypographyThree>
                </div>}
            </Grid>
            <Grid item lg={1} md={1} sm={1} style={{ display: "flex", alignItems: "center", justifyContent: "end", paddingRight: "1rem", cursor: "pointer" }} onClick={() => setCollapseFlag(!collapseFlag)}>
                <CustomCollapseButton collapseFlag={collapseFlag} setCollapseFlag={setCollapseFlag} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                {!collapseFlag && <Grid container>
                    <Grid item lg={3} md={6} sm={12} >
                        <Grid container>
                            <Grid item lg={12} md={12} sm={12} style={{ position: "relative", top: 0 }}>
                                <StyledTypography>Date From</StyledTypography>
                                <CustomDateTimePicker date={fromDateFilter} setDate={handleFromDateChange} maxDate={toDateFilter}/>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "10px", position: "relative", top: 0 }}>
                                <StyledTypography>Date To</StyledTypography>
                                <CustomDateTimePicker date={toDateFilter} setDate={handleToDateChange} minDate={fromDateFilter} maxDate={new Date()} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={3} md={6} sm={12} style={{ paddingLeft: "2rem" }}>
                        <Grid container>
                            <Grid item lg={12} md={12} sm={12}>
                                <StyledTypography>State</StyledTypography>
                                <CustomMultiSelect placeholder={"Select State"} icon={locationIcon} optionsArr={stateCategories} selectedOptions={selectedStateCategory} setSelectedOptions={handleStateCategoryChange} />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "10px" }}>
                                <StyledTypography>District</StyledTypography>
                                <CustomGroupMultiSelect placeholder={"Select District"} icon={locationIcon} optionsArr={districtCategories} selectedOptions={selectedDistrictCategory} selectAllPlaceholder={"Districts"} setSelectedOptions={handleDistrictCategoryChange} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={3} md={6} sm={12} style={{ paddingLeft: "2rem" }}>
                        <Grid container>
                            <Grid item lg={12} md={12} sm={12}>
                                <StyledTypography>Disease Type</StyledTypography>
                                <CustomMultiSelect placeholder={"Select Disease"} icon={diseaseIcon} optionsArr={diseaseCategories} selectedOptions={selectedDiseaseCategory} setSelectedOptions={handleDiseaseCategoryChange} />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "10px" }}>
                                <StyledTypography>Source Type</StyledTypography>
                                <CustomGroupMultiSelect placeholder={"Select Source"} icon={newspaperIcon} optionsArr={sourceCategories} selectedOptions={selectedSourceCategory} selectAllPlaceholder={"Sources"} setSelectedOptions={handleSourceChange} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={3} md={6} sm={12} style={{ paddingLeft: "2rem", display: "flex" }}>
                        <Grid container>
                            <Grid item lg={12} md={12} sm={12}>
                                <StyledTypography>Language</StyledTypography>
                                <CustomMultiSelect placeholder={"Select Language"} icon={aiTranslatedIcon} optionsArr={languageList} selectedOptions={selectedLanguage} setSelectedOptions={handleLanguageChange} />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "30px", display: "flex", alignSelf: "flex-end" }}>
                                {/* <CustomSearchButton icon={<SearchIcon />} text={"Search"} search={fetchEvents} /> */}
                                <CustomSearchButton icon={<SearchIcon />} text={"Save All Filter Results"} search={fetchEvents} setCollapseFlag={setCollapseFlag} collapseFlag={collapseFlag} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}
                <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />
                <Grid container style={{ marginBottom: "1rem" }}>
                    <Grid item lg={8} md={10} sm={12} style={{ display: "flex", alignItems: "center" }}>
                        <CustomSubTabs cssId="subtab-joyride-container" items={content === "extractedEvents" ? FilterDummyData.extractedEventSubTypes : content === "shortlistedEvents" ? FilterDummyData.shortlistedEventSubTypes : FilterDummyData.publishedEventSubTypes} selectedItem={selectedEventSubType} showCountFlag={true} saveSelection={setSelectedEventSubType} />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                        <Grid container>
                            <Grid item lg={2} md={3} sm={4}></Grid>
                            <Grid item lg={2} md={3} sm={4} style={{ paddingLeft: "0.5rem" }}>
                                {/* {content === "publishedEvents" && <CustomIconButton icon={CustomPublishIcon} rounded={true} tooltipText={"Publish Custom Event"} clicked={openCustomPublishDialog} />} */}
                            </Grid>
                            <Grid item lg={2} md={3} sm={4} style={{ paddingLeft: "0.5rem", display: "flex", justifyContent: "center" }}>
                                <CustomIconButton icon={"pi-file-pdf"} isIconClass={true} rounded={false} tooltipText={"Download Report"} clicked={downloadReport} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} style={{ display: "flex", alignItems: "center" }} id={onboardingSelectorIds.aiTranslation}>
                                {selectedLanguage !== "English" && <Grid container style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                                    <Grid item lg={3} md={3} sm={3}>
                                        <CustomToggleButton toggleValue={aiTranslationFlag} setToggleValue={setAiTranslationFlag} />
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={9} style={{ paddingLeft: "10px" }}>
                                        <h3 style={{ color: "#413F40", margin: 0 }}>AI Translated Articles</h3>
                                        <p style={{ color: "#78828C", margin: 0, fontSize: "x-small" }}>All articles are translated to English</p>
                                    </Grid>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item lg={4} md={6} sm={12} style={{ display: "flex", alignItems: "center" }}>
                    <CustomEventsCounter count={content === "extractedEvents" ? FilterDummyData.extractedTotalEventsCount : content === "shortlistedEvents" ? FilterDummyData.shortlistedTotalEventsCount : FilterDummyData.publishedTotalEventsCount} eventType={eventTypeMap[content]} tootTipFlag={content === "extractedEvents"} tooltipText={getAverageCount()} />
                </Grid>
                <Grid item lg={4} md={6} sm={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CustomPaginator totalPages={content === "extractedEvents" ? FilterDummyData.extractedNoOfPages : content === "shortlistedEvents" ? FilterDummyData.shortlistedNoOfPages : FilterDummyData.publishedNoOfPages} currentPageValue={FilterDummyData.page} handlePageChange={handlePageChange} />
                </Grid>
                <Grid item lg={4} md={6} sm={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center" }}>
                    <StyledTypography>Sort</StyledTypography>
                    <CustomSelect placeholder={"Select Sort"} options={sortTypeCategories} selectedOption={selectedSortType} setSelectedOption={setSelectedSortType}/>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default JoyRideFilterSection;
