import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import './CustomGroupSelect.css';

const CustomGroupSelect = ({ placeholder, selectedValue, idx, options, labelKey, valueKey, icon, filter, setSelectedOption = () => { } }) => {
  const groupOptions = options.map((group) => ({
    label: group.label,
    options: group.options.map((option) => ({
      label: option[labelKey],
      value: option[valueKey]
    })),
  }));

  const handleSelectedChange = (e) => {
    if (e.value) {
      const selectedObject = options[0].options.find(option => option[valueKey] === e.value);
      setSelectedOption(idx, selectedObject);
    } else {
      setSelectedOption(idx, "");
    }
};

const selectedOptionTemplate = (option, icon, placeholder) => {
      return (
          <div style={{ display: "flex", alignItems: "center" }}>
              {icon && <img src={icon} className={`mr-2`} style={{ marginLeft: "5px", height: '1.2rem' }} alt="icon" />}
              {option && <div style={{ marginLeft: "5px" }}>{option}</div>}
              {!option && placeholder && <span>{placeholder}</span>}
          </div>
      );
};

const defaultSelectedOption = options.find(option => option.options.some(subOption => subOption?.[labelKey] === selectedValue))?.options.find(subOption => subOption?.[labelKey] === selectedValue);

  return (
    <div >
      <Dropdown
        value={defaultSelectedOption?.[valueKey]}
        options={groupOptions}
        onChange={handleSelectedChange}
        optionLabel="label"
        optionGroupLabel="label"
        optionGroupChildren="options"
        placeholder={placeholder}
        appendTo="self"
        filter={filter}
        showFilterClear
        icon={icon}
        showClear
        valueTemplate={() => selectedOptionTemplate(defaultSelectedOption?.[labelKey], icon, placeholder)}
      />
    </div>
    );
};

export default CustomGroupSelect;
