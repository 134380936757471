import * as React from 'react';
import { customThemes } from '../../../constants/app-constants';
import "./CustomButton.css";

const CustomButton = ({ label, theme, disabled, clicked = () => { } }) => {
    const themedButton = (function () {
        if (theme === customThemes.primary) {
            return (<button className='primary-custom-button' disabled={disabled} style={{ cursor: disabled ? "not-allowed" : "pointer" }} onClick={() => clicked()}>{label}</button>)
        } else if (theme === customThemes.secondary) {
            return (<button className='secondary-custom-button' disabled={disabled} style={{ cursor: disabled ? "not-allowed" : "pointer" }} onClick={() => clicked()}>{label}</button>)
        } else if (theme === customThemes.grey) {
            return (<button className='grey-custom-button' disabled={disabled} style={{ cursor: disabled ? "not-allowed" : "pointer" }} onClick={() => clicked()}>{label}</button>)
        } else {
            return (<button className='tertiary-custom-button' disabled={disabled} style={{ cursor: disabled ? "not-allowed" : "pointer" }} onClick={() => clicked()}>{label}</button>)
        }
    })();

    return (
        <div>{themedButton}</div>
    )
}

export default CustomButton;
