import React from 'react';

const JoyRideRadioButton = ({ id, size, flag, color, background }) => {
    return (
        <div key={id} style={{ width: size || "20px", height: size || "20px", background: color || "#000", borderRadius: "50%", position: "relative", marginRight: "5px" }}>
        </div>
    )
};

export default JoyRideRadioButton;
