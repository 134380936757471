import React from 'react'
import { CssBaseline, Divider, Drawer, Grid } from '@material-ui/core';
import CustomHeaderButton from '../../Shared/CustomHeaderTab/CustomHeaderTab';
import { onboardingSelectorIds } from '../../../constants/Onboarding-Constants/idSelector';
import bgImage from '../../../resources/pattern.png';
import JoyRideFilterSection from './JoyRideFilterSection';
import { mediaAlertDummyData } from '../../../constants/Onboarding-Constants/eventData';
import JoyRideSingleCard from './JoyRideSingleCard';
import JoyRideMain from '../Main';
import { FilterDummyData } from '../../../constants/Onboarding-Constants/filterDummyData';
import CustomHelpButton from '../../Shared/CustomHelpButton/CustomHelpButton';

const JoyRideSolutionPage = ({ fromDateFilter, toDateFilter, selectedDiseaseCategory, diseaseCategories, selectedStateCategory, stateCategories, selectedDistrictCategory, districtCategories, selectedLanguage, languageList, sourceCategories, selectedSourceCategory, countEvents, noOfPages, page, aiTranslationFlag, renderCards, collapseFlag, eventSubTypes, selectedEventSubType, sortTypeCategories, selectedSortType, totalEventsCount, showTutorial, tutorialCompleted = () => { }, setShowTutorial = () => {} }) => {
    const [content, setContent] = React.useState("extractedEvents")
    const [filterCollapseFlag, setFilterCollapseFlag] = React.useState(true)
    const [tutorialCurrIdx, setTutorialCurrIdx] = React.useState(0)

    const clickExtractedEvents = () => {
        if (content !== "extractedEvents") {
            setContent("extractedEvents")
        }
    }

    const clickShortlistedEvents = () => {
        if (content !== "shortlistedEvents") {
        setContent("shortlistedEvents")
        }
    }

    const clickPublished = () => {
        if (content !== "publishedEvents") {
          setContent("publishedEvents")
        }
    }

    return <div style={{ zIndex: 9999, position: "absolute", top: 0, width: "100vw", height: "100vh", background: "#fafafa" }}>
      <JoyRideMain content={content} setFilterCollapseFlag={setFilterCollapseFlag} clickExtractedEvents={clickExtractedEvents} clickShortlistedEvents={clickShortlistedEvents} clickPublished={clickPublished}
        tutorialCompleted={tutorialCompleted} showTutorial={showTutorial} setshowTutorial={setShowTutorial}
        tutorialCurrIdx={tutorialCurrIdx} setTutorialCurrIdx={setTutorialCurrIdx}
      />
        <CssBaseline />
          <Drawer variant="persistent" anchor="top" open={true} >
            <Divider />
            <div>
              <Grid container >
                <Grid item lg={3} md={3} sm={3} style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", paddingTop: "25px", paddingBottom: "25px" }} >
                  <CustomHeaderButton heading={"Dashboard"} content={content} />
                </Grid>
                <Grid item lg={3} md={3} sm={3} style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", paddingTop: "25px", paddingBottom: "25px" }} onClick={clickExtractedEvents}>
                  <CustomHeaderButton heading={"Media Alerts List"} content={content} />
                </Grid>
                <Grid id={onboardingSelectorIds.shortListTab} item lg={3} md={3} sm={3} style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", paddingTop: "25px", paddingBottom: "25px" }} onClick={clickShortlistedEvents}>
                  <CustomHeaderButton heading={"Shortlisted"} content={content} />
                </Grid>
                <Grid id={onboardingSelectorIds.publishTab} item lg={3} md={3} sm={3} style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", paddingTop: "25px", paddingBottom: "25px" }} onClick={clickPublished}>
                  <CustomHeaderButton heading={"Published"} content={content} />
                </Grid>
              </Grid>
            </div>
          </Drawer>
          <div style={{ paddingTop: "80px", width: "100%" }}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} style={{ position: "sticky", top: "80px", zIndex: 999, backgroundImage: `url(` + bgImage + `)`, backgroundColor: "#D4EAF0", backgroundSize: "cover", padding: "1rem", boxShadow: "0px 5px 10px lightgrey" }}>
                <JoyRideFilterSection fromDateFilter={fromDateFilter} toDateFilter={toDateFilter} selectedDiseaseCategory={selectedDiseaseCategory} diseaseCategories={diseaseCategories} selectedStateCategory={selectedStateCategory} stateCategories={stateCategories} selectedDistrictCategory={selectedDistrictCategory} districtCategories={districtCategories} selectedLanguage={selectedLanguage} languageList={languageList} sourceCategories={sourceCategories} selectedSourceCategory={selectedSourceCategory} content={content} countEvents={countEvents} noOfPages={noOfPages} page={page} aiTranslationFlag={aiTranslationFlag} renderCards={renderCards} collapseFlag={filterCollapseFlag} eventSubTypes={eventSubTypes} selectedEventSubType={selectedEventSubType} sortTypeCategories={sortTypeCategories} selectedSortType={selectedSortType} totalEventsCount={totalEventsCount} setCollapseFlag={setFilterCollapseFlag} />
              </Grid>
              <Grid container key={renderCards} >
                <Grid item lg={12} md={12} sm={12} style={{ transform: tutorialCurrIdx === 8 ? "translateY(-2rem)" : tutorialCurrIdx === 9 ? "translateY(-5rem)" : "none" }}>
                  {showTutorial && <JoyRideSingleCard mainCardFlag={true} mainDataPoint={mediaAlertDummyData}
                    showCluster={tutorialCurrIdx === 4}
                    countEvents={content === "extractedEvents" ? FilterDummyData.extractedTotalEventsCount : content === "shortlistedEvents" ? FilterDummyData.shortlistedTotalEventsCount : FilterDummyData.publishedTotalEventsCount}
                    idx={0} content={content} checkPublished={false} aiTranslationFlag={true} page={1} buttonFreeze={false} clusterDialog={undefined}
                    tutorialCurrIdx={tutorialCurrIdx}
                     />}
                </Grid>
              </Grid>
            </Grid>
          </div>
          {tutorialCurrIdx !== 4 && <CustomHelpButton shiftPosition={collapseFlag}/>}
    </div>
}

export default JoyRideSolutionPage;
