import "./App.css";

import { refreshState, saveState } from "./store/localStorage";

import { Provider } from "react-redux";
import React from "react";
import Routes from "./routes";
import { ThemeProvider } from "@material-ui/core/styles";
import configureStore from "./store/configureStore";
import theme from "./theme/materialTheme";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"

const persistedstate = refreshState();
const store = configureStore(persistedstate);

store.subscribe(() => {
  saveState(store.getState());
});

// const store = configureStore({});
function App () {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Routes/>
        </ThemeProvider>
    </Provider>
  );
}

export default App;
