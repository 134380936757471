import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import '../CustomGroupSelect/CustomGroupSelect.css';

const CustomGroupSelectTwo = ({ placeholder, selectedValue, idx, options, icon, filter, showClear, setSelectedOption = () => { } }) => {
    function findLabelName (data, value) {
        return data.find(item => item.items.find(item => item.value === value))?.label;
    }

    const handleSelectedChange = (e) => {
        const label = findLabelName(options, e.value)
        setSelectedOption(idx, e.value, label)
    };

    const selectedOptionTemplate = (option, icon, placeholder) => {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <img src={icon} className={`mr-2`} style={{ marginLeft: "5px", height: '1.2rem' }} alt="icon" />
                {option && <div style={{ marginLeft: "5px" }}>{option}</div>}
                {!option && placeholder && <span>{placeholder}</span>}
            </div>
        );
    };

    return (
        <div >
            <Dropdown
                value={selectedValue}
                options={options}
                onChange={handleSelectedChange}
                optionLabel="name"
                optionGroupLabel="label"
                optionGroupChildren="items"
                placeholder={placeholder}
                appendTo="self"
                filter={filter}
                showFilterClear
                icon={icon}
                showClear={showClear && selectedValue}
                valueTemplate={() => selectedOptionTemplate(selectedValue, icon, placeholder)}
            />
        </div>
        );
};

export default CustomGroupSelectTwo;
