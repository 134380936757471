import * as React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import bgImage from '../../resources/pattern.png';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { actionTypeMap, customThemes } from '../../constants/app-constants';
import './DownloadReportDialog.css';
import CustomProgressBar from '../Shared/CustomProgressBar/CustomProgressBar';
import CustomButtonWithIcon from '../Shared/CustomButtonWithIcon/CustomButtonWithIcon';

const DownloadReportDialog = ({ dialogFlag, downloadReportFlag, jobId, timer, timerCompleteFlag, closeDialog = () => {}, downloadReport = () => {} }) => {
    const [readyToDownloadReport, setReadytoDownloadReport] = React.useState(false);

    React.useEffect(() => {
        if (downloadReportFlag && timerCompleteFlag) {
            setReadytoDownloadReport(true);
        } else {
            setReadytoDownloadReport(false);
        }
    }, [downloadReportFlag, timerCompleteFlag])

    const closeDialogFun = () => {
        closeDialog(false);
    }

    const downloadPdfFun = () => {
        downloadReport();
    }

    return (
        <div >
            <Dialog
                open={dialogFlag}
                onClose={closeDialogFun}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'lg'}
            >
                <DialogTitle id="alert-dialog-title" style={{ backgroundImage: `url(` + bgImage + `)`, backgroundColor: "#D4EAF0", backgroundSize: "cover", padding: "1rem" }}>
                    <Grid container>
                        <Grid item lg={11} md={11} sm={11} style={{ fontSize: "x-large", display: "flex", alignItems: "center", fontWeight: "600" }}>
                            Download Report
                        </Grid>
                        <Grid item lg={1} md={1} sm={1} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                            <IconButton onClick={() => closeDialogFun()}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className='download-report-dialog'>
                    <Box>
                        <Grid container style={{ boxSizing: "border-box" }}>
                            <Grid item lg={12} md={12} sm={12} style={{ margin: "5rem 2rem 5rem" }}>
                                <div>
                                    {(!timerCompleteFlag || !downloadReportFlag) && <Typography style={{ fontWeight: 500 }}>Your data is being preparing...</Typography>}
                                    {timerCompleteFlag && downloadReportFlag && <Typography style={{ fontWeight: 500 }}>Your data is ready to download.</Typography>}
                                </div>
                                <div>
                                    <CustomProgressBar time={timer} color="#008BCA" />
                                </div>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "1rem" }}>
                                <Grid container style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderTop: "1px solid #000", padding: "14px 2rem" }}>
                                    <Grid item lg={7} md={7} sm={7} >
                                        <Typography style={{ fontWeight: 500 }}>Please wait for a while, and kindly refrain from closing this.</Typography>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4} style={{ display: "flex", justifyContent: "flex-end" }} >
                                        <CustomButtonWithIcon label={actionTypeMap.downloadReport} theme={readyToDownloadReport ? customThemes.primary : customThemes.grey} disabled={!readyToDownloadReport} loader={!readyToDownloadReport} clicked={downloadPdfFun} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default DownloadReportDialog;
