import React from 'react';
import "./CustomProgressBar.css";
import { LinearProgress } from '@material-ui/core';

const CustomProgressBar = ({ time, color }) => {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(timer);
                    return 100;
                }
                return prevProgress + 1;
            });
        }, time / 100);

        return () => {
            clearInterval(timer);
        };
    }, [time]);

    return (
        <div className='custom-progressbar-wrapper'>
            <div className='linear-progress-bar'>
                <LinearProgress variant="determinate" value={progress} />
            </div>
            <span>{progress}%</span>
        </div>
    )
}

export default CustomProgressBar;
