import * as React from 'react';
import { statusValues } from '../../../constants/app-constants';
import "./CustomStatusBar.css";

const CustomStatusBar = ({ icon, theme, eventId, clicked = () => { } }) => {
    const themeValues = (function () {
        if (theme === statusValues.pending) {
            return {
                background: "#E8F4F0",
                color: "#16754C",
                text: "See Status of Investigation Requirement"
            }
        } else if (theme === statusValues.ongoing) {
            return {
                background: "#E0D1D6",
                color: "#74082B",
                text: "See Ongoing Outbreak Status"
            }
        } else if (theme === statusValues.completed) {
            return {
                background: "#F8ECC6",
                color: "#413F40",
                text: "See Closed Outbreak Details"
            }
        } else if (theme === statusValues.closed) {
            return {
                background: "#D9D9D9",
                color: "#414C5B",
                text: "Closed Events"
            }
        } else {
            return {
                background: "#979797",
                color: "#ced2d5",
                text: "See Eevents Status Details"
            }
        }
    })();
    return (
        <div className='status-bar' onClick={() => clicked(true, eventId)} style={{ background: themeValues.background }}>
                <div style={{ color: themeValues.color, fontWeight: 700 }}>
                    {themeValues.text}
                </div>
                <div className='status-icon' style={{ color: themeValues.color }}>
                    {icon}
                </div>
        </div>
    )
}

export default CustomStatusBar;
