import * as React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import "./CustomMultiSelect.css";
import CustomActionButtonFour from '../CustomActionButtonFour/CustomActionButtonFour';

const CustomMultiSelect = ({ placeholder, icon, optionsArr, selectedOptions, disabled, setSelectedOptions = () => { } }) => {
    const [someState, setSomeState] = React.useState(false);

    function getSelectedItemLabel () {
        const multiselectType = placeholder.split(" ").splice(-1)[0]
        let heading = "";
        let length = selectedOptions.length;
        if (multiselectType === "State") {
            if (selectedOptions.indexOf("") !== -1) {
                length--;
                heading = "States + UT";
            } else {
                heading = "States"
            }
        } else if (multiselectType === "Disease") {
            heading = "Diseases";
            if (selectedOptions.length + 1 === optionsArr.length && !selectedOptions.includes("covid-19")) {
                return "All Except Covid";
            }
        } else if (multiselectType === "Source") {
            heading = "Sources";
        } else if (multiselectType === "Language") {
            heading = "Languages";
        }
        return length + " " + heading + " selected";
    }

    React.useEffect(() => {
        const pTag = document.createElement('p');
        pTag.textContent = 'Select All ';
        pTag.classList.add('appended-p-tag-class');
        const myElement = document.querySelector('.custom-multiselect .p-multiselect-header .p-checkbox');
        if (myElement) {
            const myPElement = document.querySelector('.appended-p-tag-class');
            if (!myPElement) {
                myElement.appendChild(pTag);
                return () => {
                    myElement.removeChild(pTag);
                };
            }
        }
    }, [someState]);

    function handletoggle () {
        setSomeState(!someState)
    }

    function saveAllSelects () {
        document.querySelector('body').click();
    }

    function getSaveButton () {
        return (
            <CustomActionButtonFour color="#FFFFFF" background="#78828C" disabled={false} label="Save" fontSize="14.473px" onClick={saveAllSelects}/>
        );
    }

    return (
        <div className={`multiselect-wrapper${disabled ? ' custom-multiselect-disabled' : ''}`}>
            <div className="multiselect-icon-wrapper">
                <img src={icon} className="multiselect-icon" style={{ width: placeholder.includes("State") ? "0.9rem" : "" }} alt="icon" />
            </div>
            <MultiSelect value={selectedOptions} onChange={setSelectedOptions} options={optionsArr} optionLabel="name"
                filter placeholder={placeholder} maxSelectedLabels={2} className="w-full" appendTo="self" disabled={disabled}
                panelClassName="custom-multiselect" onBlur={handletoggle} selectedItemsLabel={getSelectedItemLabel()} panelFooterTemplate={getSaveButton()}
            />
        </div>
    );
}

export default CustomMultiSelect;
