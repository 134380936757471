import { ChatBubbleOutlineOutlined } from '@material-ui/icons';
import * as React from 'react';
import './CustomHelpButton.css';
import { onboardingSelectorIds } from '../../../constants/Onboarding-Constants/idSelector';

const CustomHelpButton = ({ shiftPosition }) => {
    return (
        <button className='help-button' id={onboardingSelectorIds.helpButton} style={{ top: !shiftPosition ? "640px" : "440px", }} onClick={() => window.open('https://forms.gle/f2PfbfW4enCZgQSJ8', '_blank')}><ChatBubbleOutlineOutlined style={{ marginRight: "10px" }}/> Help</button>
    )
}

export default CustomHelpButton;
