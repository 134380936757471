import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import React from "react";

// import { getSolutionsOptions } from "../actions/global"
// import { useDispatch } from "react-redux";

const App = () => {
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(getSolutionsOptions())
  // }, [dispatch])

  return (
    <div>
    <Router>
      <Switch>
        <Route exact path="/">
          <Dashboard />
        </Route>
      </Switch>
    </Router>
    </div>
  );
}
export default App;
