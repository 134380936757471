// import { SET_DATA_TYPE_SUPPORTED, SET_SOLUTIONS_OPTIONS, SET_TOOL_SUPPORTED } from "./types";

import { SET_DISEASE_CATEGORIES, SET_EVENTS, SET_EVENTS_COUNT, SET_STATE_CATEGORIES, SET_EVENT_SUMMARY, SET_EVENT_SUB_TYPES, SET_SOURCE_CATEGORIES, SET_DISTRICT_CATEGORIES, SET_LANGUAGE_CATEGORIES, SET_SORT_CATEGORIES, SET_GENRATE_PDF, SET_DASHBOARD_DATA, SET_REPORT_DOWNLOAD, SET_JOB_ID } from "./types";
import { dashboardApi, diseaseCategoryApi, districtCategoryApi, eventReadApi, eventsApi, eventsApiDecluster, langaugeCategoryApi, loginApi, reportDownloadApi, sortCategoryApi, sourceCategoryApi, stateCategoryApi, onboardingCompletedApi, sourceRejection, subdistrictCategoryApi } from "../env"

import fetchApi from "./fetchApi"
import moment from "moment";
import { capitalizeFirstLetter } from "./methods";

export function setEvents (data) {
  return {
    type: SET_EVENTS,
    data
  };
}

export function setEventsCount (data) {
  return {
    type: SET_EVENTS_COUNT,
    data
  };
}

export function setDiseaseCategories (data) {
  return {
    type: SET_DISEASE_CATEGORIES,
    data
  };
}

export function setStateCategories (data) {
  return {
    type: SET_STATE_CATEGORIES,
    data
  };
}

export function setDistrictCategories (data) {
  return {
    type: SET_DISTRICT_CATEGORIES,
    data
  };
}

export function setlanguageCategories (data) {
  return {
    type: SET_LANGUAGE_CATEGORIES,
    data
  };
}

export function setSortCategories (data) {
  return {
    type: SET_SORT_CATEGORIES,
    data
  };
}

export function setEventSubTypes (data) {
  return {
    type: SET_EVENT_SUB_TYPES,
    data
  };
}

export function setSourcesCategories (data) {
  return {
    type: SET_SOURCE_CATEGORIES,
    data
  };
}

export function setEventSummary (data) {
  return {
    type: SET_EVENT_SUMMARY,
    data
  };
}

export function setGenratePDF (data) {
  return {
    type: SET_GENRATE_PDF,
    data
  }
}

export function setDashboardData (data) {
  return {
    type: SET_DASHBOARD_DATA,
    data
  }
}

export function setJobId (data) {
  return {
    type: SET_JOB_ID,
    data
  }
}

export function setReportDownload (data) {
  return {
    type: SET_REPORT_DOWNLOAD,
    data
  }
}

export function getEvents (startDate, endDate, eventType, stateCat, districtCat, diseaseCat, languageCat, pageFunc, itemsPerPageFunc, eventSubTypeCat, sourceCat, sortCat, tokenFunc) {
  var paramsDic = {
    event_type: eventType,
    from_date: moment(startDate).format('yyyy-MM-DDTHH:mm'),
    to_date: moment(endDate).format('yyyy-MM-DDTHH:mm'),
    offset: (pageFunc - 1) * itemsPerPageFunc,
    size: itemsPerPageFunc,
  }

  if (sortCat) {
    paramsDic.sort_type = sortCat
  }

  if (languageCat.length > 0) {
    paramsDic.language = languageCat.toString()
  }
  if (stateCat.length > 0) {
    paramsDic.state = stateCat
  }
  if (districtCat.length > 0) {
    paramsDic.district = districtCat
  }
  if (diseaseCat.length > 0) {
    paramsDic.disease = diseaseCat
  }
  if (eventSubTypeCat.type && eventSubTypeCat.code !== "0") {
    paramsDic.event_sub_type = eventSubTypeCat.code
  }
  if (sourceCat.length > 0) {
    paramsDic.source_type = sourceCat
  }
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(eventsApi, "GET", {}, headerValue, paramsDic)
      .then(res => {
        dispatch(setEvents(res));
        return res.data
      });
  };
}

export function getEventsCount (startDate, endDate, eventType, stateCat, districtCat, diseaseCat, languageCat, eventSubTypeCat, sourceCat, tokenFunc) {
  var paramsDic = {
    event_type: eventType,
    from_date: moment(startDate).format('yyyy-MM-DDTHH:mm'),
    to_date: moment(endDate).format('yyyy-MM-DDTHH:mm')
  }
  if (languageCat.length > 0) {
    paramsDic.language = languageCat.toString()
  }
  if (stateCat.length > 0) {
    paramsDic.state = stateCat
  }
  if (districtCat.length > 0) {
    paramsDic.district = districtCat
  }
  if (diseaseCat.length > 0) {
    paramsDic.disease = diseaseCat
  }
  if (eventSubTypeCat.type && eventSubTypeCat.code !== "0") {
    paramsDic.event_sub_type = eventSubTypeCat.code
  }
  if (sourceCat.length > 0) {
    paramsDic.source_type = sourceCat
  }
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(eventsApi + "count", "GET", {}, headerValue, paramsDic)
      .then(res => {
        dispatch(setEventsCount(res));
        return res.data
      });
  };
}

export function getDiseaseCategories (tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(diseaseCategoryApi, "GET", {}, headerValue, {})
      .then(res => {
        dispatch(setDiseaseCategories(res));
        return res.data
      });
  };
}

export function getStateCategories (tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(stateCategoryApi, "GET", {}, headerValue, {})
      .then(res => {
        dispatch(setStateCategories(res));
        return res.data
      });
  };
}

export function getDistrictCategories (tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(districtCategoryApi, "GET", {}, headerValue, {})
      .then(res => {
        dispatch(setDistrictCategories(res));
        return res.data
      });
  };
}

export function getLanguageCategories (tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(langaugeCategoryApi, "GET", {}, headerValue, {})
      .then(res => {
        dispatch(setlanguageCategories(res));
        return res.data
      });
  };
}

export function getSortCategories (eventType, tokenFunc) {
  const paramsDic = {
    event_type: eventType
  }
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(sortCategoryApi, "GET", {}, headerValue, paramsDic)
      .then(res => {
        dispatch(setSortCategories(res));
        return res.data
      });
  };
}

export function getEventSubTypes (startDate, endDate, eventType, stateCat, districtCat, languageCat, diseaseCat, sourceCat, tokenFunc) {
  var paramsDic = {
    event_type: eventType,
    from_date: moment(startDate).format('yyyy-MM-DDTHH:mm'),
    to_date: moment(endDate).format('yyyy-MM-DDTHH:mm'),
  }
  if (languageCat.length > 0) {
    paramsDic.language = languageCat.toString()
  }
  if (stateCat.length > 0) {
    paramsDic.state = stateCat
  }
  if (districtCat.length > 0) {
    paramsDic.district = districtCat
  }
  if (diseaseCat.length > 0) {
    paramsDic.disease = diseaseCat
  }
  if (sourceCat.length > 0) {
    paramsDic.source_type = sourceCat
  }
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(eventsApi + "sub_types", "GET", {}, headerValue, paramsDic)
      .then(res => {
        dispatch(setEventSubTypes(res));
        return res.data
      });
  };
}

export function getSourceCategories (tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(sourceCategoryApi, "GET", {}, headerValue, {})
      .then(res => {
        dispatch(setSourcesCategories(res));
        return res.data
      });
  };
}

export function postShortlistEvent (eventJSON, tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(eventsApi + "shortlist", "POST", JSON.stringify(eventJSON), headerValue, {}).then(res => { return res?.success });
  };
}

export function postCustomPublishEvent (eventJSON, tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(eventsApi + "customPublish", "POST", JSON.stringify(eventJSON), headerValue, {}).then(res => { return res?.success });
  };
}

export function postPublishEvent (eventJSON, tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(eventsApi + "publish", "POST", JSON.stringify(eventJSON), headerValue, {}).then(res => { return res?.success });
  };
}

export function postRepublishEvent (eventJSON, tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(eventsApi + "republish", "POST", JSON.stringify(eventJSON), headerValue, {}).then(res => { return res?.success });
  };
}

export function postLogin (encodedString) {
  var headerValue = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  var bodyValue = {
    encoded_basic: encodedString
  }
  return fetchApi(loginApi, "POST", JSON.stringify(bodyValue), headerValue, {}).then(res => { return res });
  // return dispatch => {
  //   fetchApi(loginApi, "POST", JSON.stringify(bodyValue), headerValue, {}).then(res => { console.log(res) });
  // };
}
// import { solutionsOptionsAPI } from "../env";

// export function setSolutionsOptions (data) {
//   return {
//     type: SET_SOLUTIONS_OPTIONS,
//     data
//   };
// }
// export function getSolutionsOptions () {
//   return dispatch => {
//     return fetchApi(solutionsOptionsAPI)
//       .then(res => {
//         dispatch(setSolutionsOptions(res));
//       });
//   };
// }
// export function setToolSupported (data) {
//   return {
//     type: SET_TOOL_SUPPORTED,
//     data
//   };
// }

// export function setDataTypeSupported (data) {
//   return {
//     type: SET_DATA_TYPE_SUPPORTED,
//     data
//   };
// }

export function getYesterDayDateTime () {
  const date = new Date()
  date.setDate(date.getDate() - 1)
  return date
}

export function getCurrentTime () {
  return new Date()
}

export function getEventSummaryData (eventId, tokenFunc) {
  var paramsDic = {
    id: eventId,
  }
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(eventsApi + "summary", "GET", {}, headerValue, paramsDic)
      .then(res => {
        dispatch(setEventSummary(res));
        return res.data
      });
  };
}

export function getRelatedEventsData (startDate, endDate, eventType, stateCat, diseaseCat, languageCat, sourceCat, eventSubTypeCat, tokenFunc, clusterId, eventId) {
  var paramsDic = {
    event_type: eventType,
    from_date: moment(startDate).format('yyyy-MM-DDTHH:mm'),
    to_date: moment(endDate).format('yyyy-MM-DDTHH:mm'),
    cluster_id: clusterId,
    event_id: eventId
  }
  if (languageCat.length > 0) {
    paramsDic.language = languageCat
  }
  if (stateCat.length > 0) {
    paramsDic.state = stateCat
  }
  if (diseaseCat.length > 0) {
    paramsDic.disease = diseaseCat
  }
  if (sourceCat.length > 0) {
    paramsDic.source_type = sourceCat
  }
  if (eventSubTypeCat.type && eventSubTypeCat.code !== "0") {
    paramsDic.event_sub_type = eventSubTypeCat.code
  }
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(eventsApi + "clustered_events", "GET", {}, headerValue, paramsDic)
      .then(res => {
        dispatch(setEvents(res));
        return res.data
      });
  };
}

export function postRejectEvent (eventJSON, tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(eventsApi + "reject", "POST", JSON.stringify(eventJSON), headerValue, {}).then(res => { return res?.success });
  };
}

export function postRemoveEvent (eventJSON, tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(eventsApiDecluster, "POST", JSON.stringify(eventJSON), headerValue, {}).then(res => { return res?.success });
  };
}

export function postSendEventForApproval (eventJSON, tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(eventsApi + "send_approval", "POST", JSON.stringify(eventJSON), headerValue, {}).then(res => { return res?.success });
  };
}

export function postUnrejectEvent (eventJSON, tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(eventsApi + "unreject", "POST", JSON.stringify(eventJSON), headerValue, {}).then(res => { return res?.success });
  };
}

export function getGeneratePDF (eventJSON, tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }

  return dispatch => {
    return fetchApi(eventsApi + "save_pdf_data", "POST", JSON.stringify(eventJSON), headerValue, {})
      .then(res => {
        return res
      });
  };
}

export function getGuestToken (dashboardType, token) {
  var paramDic = {
    dashboard_type: dashboardType
  }
  var headerValue = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  }
  return dispatch => {
    return fetchApi(dashboardApi, "GET", {}, headerValue, paramDic)
    .then(res => {
      dispatch(setDashboardData(res));
      return res
    });
  };
}

export function getJobId (eventType, startDate, endDate, diseaseCat, stateCat, districtCat, languageCat, sourceCat, eventSubTypeCat, token) {
  var paramsDic = {
    event_type: eventType,
    from_date: moment(startDate).format('yyyy-MM-DDTHH:mm'),
    to_date: moment(endDate).format('yyyy-MM-DDTHH:mm'),
  }
  if (diseaseCat.length > 0) {
    paramsDic.disease = diseaseCat.toString();
  }
  if (stateCat.length > 0) {
    paramsDic.state = stateCat.toString();
  }
  if (districtCat.length > 0) {
    paramsDic.district = districtCat.toString();
  }
  if (languageCat.length > 0) {
    paramsDic.language = languageCat.toString()
  }
  if (sourceCat.length > 0) {
    paramsDic.source_type = sourceCat.toString();
  }
  if (eventSubTypeCat.type && eventSubTypeCat.code !== "0") {
    paramsDic.event_sub_type = eventSubTypeCat.code.toString();
  }

  var headerValue = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  }
  return dispatch => {
    return fetchApi(reportDownloadApi + "create_job", "POST", JSON.stringify(paramsDic), headerValue, {})
    .then(res => {
      dispatch(setJobId(res));
      return res
    });
  };
}

export function getReportDownload (jobId, token) {
  var paramDic = {
    job_id: jobId,
  }
  var headerValue = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  }
  return dispatch => {
    return fetchApi(reportDownloadApi + "get_job_data", "GET", {}, headerValue, paramDic)
    .then(res => {
      dispatch(setReportDownload(res));
      return res
    });
  };
}

export function eventChecked (eventJSON, tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(eventReadApi, "POST", JSON.stringify(eventJSON), headerValue, {});
  };
}

export function onboardingCompleted (userId, tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(onboardingCompletedApi, "POST", JSON.stringify({ user_id: userId }), headerValue, {});
  };
}

export function getBlockedSources (tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(sourceRejection, "GET", {}, headerValue, {});
  };
}

export function blockSource (jsonObj, tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(sourceRejection + "block", "POST", JSON.stringify(jsonObj), headerValue, {});
  };
}

export function unblockSource (jsonObj, tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(sourceRejection + "unblock", "POST", JSON.stringify(jsonObj), headerValue, {});
  };
}

export function getBlockedEvent (jsonObj, tokenFunc) {
  var paramDic = {
    event_id: jsonObj.eventID
  }
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(sourceRejection + "event", "GET", {}, headerValue, paramDic);
  };
}

export function getSubistrictCategories (tokenFunc) {
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: "Bearer " + tokenFunc
  }
  return dispatch => {
    return fetchApi(subdistrictCategoryApi, "GET", {}, headerValue, {})
      .then(res => {
        return res.data
      });
  };
}
