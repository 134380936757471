
import React from "react";
import { Editor } from "primereact/editor";
import './CustomRichTextEditor.css';

const CustomRichTextEditor = ({ value, disabled, onTextChange = () => {} }) => {
    const handleKeyPress = (e) => {
        if (disabled) {
            e.preventDefault();
        }
    };
    const handlePaste = (e) => {
        if (disabled) {
            e.preventDefault();
        }
    };

    return (
        <div className="card">
            <Editor value={value} onTextChange={onTextChange} onKeyPress={handleKeyPress} onPaste={handlePaste} style={{ height: '320px' }} />
        </div>
    )
}

export default CustomRichTextEditor;
