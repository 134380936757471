/* eslint-disable camelcase */
import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { AddCircle } from '@material-ui/icons';
import { InputAdornment } from '@material-ui/core';
import { actionTypeMap, CssTextField, CssTypography, customThemes, langaugeAbbreMap, useStyles } from '../../../constants/app-constants';
import CustomActionButtonTwo from '../../Shared/CustomActionButtonTwo/CustomActionButtonTwo';
import CustomActionButton from '../../Shared/CustomActionButton/CustomActionButton';
import CustomStatusBar from '../../Shared/CustomStatusBar/CustomStatusBar';
import CustomCardNumber from '../../Shared/CustomCardNumber/CustomCardNumber';
import CustomCardTag from '../../Shared/CustomCardTag/CustomCardTag';
import MainEventIcon from "../../../resources/mainEventIcon.png";
import AITranslatedIcon from "../../../resources/aiTranslatedIcon.png";
import deathIcon from "../../../resources/deathIcon.png";
import newspaperIcon from "../../../resources/sourceIcon.png";
import diseaseIcon from "../../../resources/diseaseIcon.png";
import locationIcon from "../../../resources/locationIcon.png";
import vectorIcon from "../../../resources/vectorIcon.png";
import videoIcon from "../../../resources/videoIcon.png";
import "./SingleCard.css";
import CustomTooltip from '../../Shared/CustomTooltip/CustomTooltip';
import CustomActionButtonFive from '../../Shared/CustomActionButtonFive/CustomActionButtonFive';
import CustomSelectTwo from '../../Shared/CustomSelectTwo/CustomSelectTwo';
import CustomCollapseButton from '../../Shared/CustomCollapseButton/CustomCollapseButton';
import CustomGroupSelectTwo from '../../Shared/CustomGroupSelectTwo/CustomGroupSelectTwo';
import CustomButtonWithIconTwo from '../../Shared/CustomButtonWithIconTwo/CustomButtonWithIcon';

const SingleCard = ({ mainCardFlag, mainDataPoint, idx, content, districtCategories, subdistrictCategories, checkPublished, stateUserFlag, stateUpdated, aiTranslationFlag, page, countEvents, buttonFreeze, clusterDialog, isValidEventData, diseaseCategories, handleDateChange = () => { }, handleFinalDiseaseChange = () => { }, handleNumberChange = () => { }, handleStateChange = () => { }, handleDistrictChange = () => { }, handleSubdistrictChange = () => { }, handleMessageChange = () => { }, capitalizeFirstLetter = () => { }, onRejectSetIndex = () => { }, onRemoveSetIndex = () => { }, onShortistSetIndex = () => { }, onPublishSetIndex = () => { }, openPublishDialog = () => { }, openSummaryDialog = () => { }, openRelatedEventsDialog = () => { }, sendClusterForApproval = () => { }, approveActionForCluster = () => { }, sendEmailForCluster = () => { }, openPDFDialog = () => { }, unRejectCluster = () => { }, setIsValidEventData = () => { }, updateEventAsChecked = () => { }, setShowRejectSourceDialog = () => { } }) => {
    const classes = useStyles();
    const [showActiveButton, setShowActiveButton] = React.useState(null);
    const [isValidData, setIsValidData] = React.useState(true);
    const [collapseCard, setCollapseCard] = React.useState(!!(content === "extractedEvents" && mainDataPoint.read_by));

    const [numberCases, setNumberCases] = React.useState({
        caseKey: mainDataPoint.number.case_new > 0 ? "new" : mainDataPoint.number.case_total > 0 ? "total" : "new",
        deathKey: mainDataPoint.number.death_new > 0 ? "new" : mainDataPoint.number.death_total > 0 ? "total" : "new",
        case: {
          caseKey: mainDataPoint.number.case_new > 0 ? mainDataPoint.number.case_new : mainDataPoint.number.case_total > 0 ? mainDataPoint.number.case_total : "",
        },
        death: {
          deathKey: mainDataPoint.number.death_new > 0 ? mainDataPoint.number.death_new : mainDataPoint.number.death_total > 0 ? mainDataPoint.number.death_total : "",
        }
    });

    function toggleButtons (actionType) {
        if (actionType === showActiveButton) {
            setShowActiveButton(null);
        } else {
            setShowActiveButton(actionType);
        }
    }

    function handleCardNumberChange (e, type) {
        const number = e.target.value.replace(/[^0-9]/g, '')
        const numberObj = {};

        if (type === "case") {
          setNumberCases((prevCases) => ({
            ...prevCases,
            case: {
              caseKey: number,
            }
          }));
          numberObj[type + "_" + numberCases.caseKey] = number
          handleNumberChange(idx, numberObj)
        } else {
          setNumberCases((prevCases) => ({
            ...prevCases,
            death: {
              deathKey: number,
            }
          }));
          numberObj[type + "_" + numberCases.deathKey] = number
          handleNumberChange(idx, numberObj)
        }
    }

    function handleCopyExtractedEventId () {
        navigator.clipboard.writeText(mainDataPoint.id);
    }

    function handleSourceClick () {
        window.open(mainDataPoint.url);
        if (!mainDataPoint.read_by) {
            updateEventAsChecked({ event_id: mainDataPoint.id, cluster_id: mainDataPoint.cluster_id })
        }
        if (!clusterDialog) {
            if (content === "extractedEvents") {
                setCollapseCard(true)
            }
        }
    }

    function transformArray (originalArray) {
        if (!originalArray.length) return [];
        const transformedArray = originalArray
            .filter(item => item !== 'Other' || item !== "")
            .map(item => ({ name: item, value: item }));
            transformedArray.sort((a, b) => a.value.localeCompare(b.value));
        return transformedArray;
    }

    function subDistrictProvider () {
        if (mainDataPoint.state && mainDataPoint.district) {
            const stateSubdistricts = subdistrictCategories[mainDataPoint.state];
            if (stateSubdistricts && mainDataPoint.district in stateSubdistricts) {
                return stateSubdistricts[mainDataPoint.district];
            }
        }
        return [];
    }

    function getFormatedDate (date) {
        return date?.split('-').reverse().join('/');
    }

    function handlePostSourceRejectionClick () {
        setShowRejectSourceDialog(prev => ({
            ...prev,
            showPostRejectionClick: true,
            url: mainDataPoint.url ? new URL(mainDataPoint.url).hostname : ".",
            eventId: mainDataPoint.id
        }))
    }

    return (
        <Paper key={idx} className={`customPaper ${(!mainCardFlag || (mainDataPoint.cluster_count > 1)) ? "customPaperLong" : "customPaperShort"}`} style={{ display: "Flex", marginBottom: "20px", backgroundColor: mainDataPoint.reject_value ? "#F8D5D5" : collapseCard ? "#f6f6f6" : "white", boxShadow: "5px 5px 10px lightgray", borderRadius: "10px" }}>
            <Grid container className='single-card-container'>
                <Grid item lg={12} md={12} sm={12} style={{ padding: "30px", paddingTop: "0px" }}>
                    <Grid container spacing={2} >
                        {/* {mainDataPoint.parent_type && <Grid item lg={12} md={12} sm={12} style={{ marginTop: "10px" }}>
                            <CustomNotificationBar text={notificationBarText[mainDataPoint.parent_type]} icon={<InfoOutlined />} />
                        </Grid>} */}
                        <Grid item lg={12} md={12} sm={12} >
                            <Grid container className='header-container'>
                                <Grid item lg={11} md={11} sm={11} >
                                    <h1 className='card-title' >{aiTranslationFlag ? mainDataPoint.translated_article.title : mainDataPoint.article.title}</h1>
                                </Grid>
                                <Grid item lg={1} md={1} sm={1} style={{ padding: "0", display: 'flex', justifyContent: 'end', alignItems: 'center', columnGap: "10px" }}>
                                    <CustomTooltip tooltipText="Click to Copy Event ID" iconClass="pi-copy" fontSize="1.3rem" color="#78828C" onClick={handleCopyExtractedEventId} />
                                    {!clusterDialog && content === "extractedEvents" && <CustomCollapseButton collapseFlag={collapseCard} color="#e6f4f9" background="#2b87b5" setCollapseFlag={setCollapseCard} />}
                                </Grid>
                            </Grid>
                        </Grid>
                        {!collapseCard && <>
                            <Grid item lg={3} md={3} sm={3}>
                                {content === "extractedEvents" && <CssTypography>Extraction Date</CssTypography>}
                                {content === "shortlistedEvents" && <CssTypography>Shortlist Date</CssTypography>}
                                {content === "publishedEvents" && <CssTypography>Publish Date</CssTypography>}
                                <CssTextField
                                    type="text"
                                    size="small"
                                    variant="outlined"
                                    value={content === "extractedEvents" ? getFormatedDate(mainDataPoint.extracted_date) : content === "shortlistedEvents" ? getFormatedDate(mainDataPoint.shortlist_date) : getFormatedDate(mainDataPoint.published_date) }
                                    onChange={(e) => handleDateChange(idx, e)}
                                    className={classes.textField2}
                                    style={{ backgroundColor: "#F3FAFF", width: "100%" }}
                                    InputProps={{ readOnly: (checkPublished) }}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item lg={5} md={3} sm={3}>
                                <CssTypography>Disease Type</CssTypography>
                                <FormControl fullWidth size="small" style={{ width: "100%", backgroundColor: "#F3FAFF" }}>
                                    <CustomGroupSelectTwo
                                        placeholder="Select Disease"
                                        options={diseaseCategories}
                                        selectedValue={mainDataPoint.disease}
                                        setSelectedOption={handleFinalDiseaseChange}
                                        idx={idx}
                                        icon={diseaseIcon}
                                        filter={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6}>
                                <div>
                                    <CssTypography>Source</CssTypography>
                                </div>
                                <div className='source-wrapper' style={{ display: 'flex', columnGap: "10px" }}>
                                    <div style={{ position: "relative", display: "flex", backgroundColor: "#F3FAFF", padding: "8.35px 14px", width: "100%", font: "inherit", fontSize: "15px", color: "currentColor", border: (!isValidData && mainDataPoint.url.trim().length === 0) ? " 1px solid red" : "1px solid #bbc0c3", borderRadius: "12px" }}>
                                        <div style={{ position: "absolute", top: "10px", left: "14px", background: "#F3FAFF", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <img src={newspaperIcon} style={{ height: "1rem" }} alt="newspaper-icon" />
                                        </div>
                                        <div className='text-wrap' style={{ paddingRight: "20px", paddingLeft: "30px", textDecoration: "underline", color: "#0038FF" }} onClick={handleSourceClick} >{mainDataPoint.url ? new URL(mainDataPoint.url).hostname : "."}</div>
                                        {/* <div style={{ position: "absolute", top: "10px", right: "10px", background: "#F3FAFF", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }} onClick={handleSourceClick}>
                                            <img src={linkIcon} style={{ height: "1rem" }} alt="source-link-icon" />
                                        </div> */}
                                    </div>
                                    <div>
                                        <CustomButtonWithIconTwo tooltipText="Block this source from showing events in the future" preIcon={true} iconSize="13px" iconClass="pi-ban" label="Block" color="#414C5B" background="#D6EFFF" padding="8px 15px" onClick={handlePostSourceRejectionClick} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xl={3} lg={3} md={6} sm={6}>
                                <Grid container spacing={2} >
                                    <Grid item xl={6} lg={6} md={6} sm={6} >
                                        <CssTypography style={{ width: "116px", textWrap: "nowrap", overflow: "hidden", }}>Reported Cases</CssTypography>
                                        <CssTextField
                                            type="text"
                                            size="small"
                                            variant="outlined"
                                            value={numberCases.case.caseKey}
                                            onChange={(e) => handleCardNumberChange(e, 'case')}
                                            className={classes.textField2}
                                            style={{ backgroundColor: "#F3FAFF", width: "100%" }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><img className='card-field-icon' src={vectorIcon} alt="location-icon" /></InputAdornment>,
                                                readOnly: (checkPublished)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} >
                                        <CssTypography>Death</CssTypography>
                                        <CssTextField
                                            type="text"
                                            size="small"
                                            variant="outlined"
                                            value={numberCases.death.deathKey}
                                            onChange={(e) => handleCardNumberChange(e, 'death')}
                                            className={classes.textField2}
                                            style={{ backgroundColor: "#F3FAFF", width: "100%" }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><img className='card-field-icon' src={deathIcon} alt="location-icon" /></InputAdornment>,
                                                readOnly: (checkPublished)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={3} md={6} sm={6} className='single-card-state-container' >
                                <CssTypography>State</CssTypography>
                                <FormControl fullWidth size="small" style={{ width: "100%", backgroundColor: "#F3FAFF" }}>
                                    <CustomSelectTwo disabled={stateUserFlag} filter={true} error={!isValidData && mainDataPoint.state.trim().length === 0} icon={locationIcon} placeholder={"Select State"} options={transformArray(Object.keys(districtCategories))} idx={idx} selectedOption={mainDataPoint.state} setSelectedOption={handleStateChange} />
                                </FormControl>
                            </Grid>
                            <Grid item lg={3} md={6} sm={6}>
                                <CssTypography>District</CssTypography>
                                <FormControl fullWidth size="small" style={{ width: "100%", backgroundColor: "#F3FAFF", border: (!isValidData && mainDataPoint.district.trim().length === 0) ? "1px solid red !important" : "" }}>
                                    <CustomSelectTwo filter={true} showClear={true} error={!isValidData && mainDataPoint.district.trim().length === 0} icon={locationIcon} placeholder={"Select District"} options={mainDataPoint.state !== "" ? districtCategories[mainDataPoint.state] : []} idx={idx} selectedOption={mainDataPoint.district} setSelectedOption={handleDistrictChange} />
                                </FormControl>
                            </Grid>
                            <Grid item lg={3} md={6} sm={6}>
                                <CssTypography>Sub District / ULB</CssTypography>
                                <FormControl fullWidth size="small" style={{ backgroundColor: "#F3FAFF", border: (!isValidData && mainDataPoint.district.trim().length === 0) ? "1px solid red !important" : "" }}>
                                    <CustomGroupSelectTwo
                                        placeholder="Select Sub District / ULB"
                                        options={subDistrictProvider()}
                                        selectedValue={mainDataPoint?.sub_district}
                                        setSelectedOption={handleSubdistrictChange}
                                        idx={idx}
                                        icon={locationIcon}
                                        filter={true}
                                        showClear={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12}>
                                <CssTypography>Original Message: </CssTypography>
                                <CssTextField
                                    multiline
                                    type="text"
                                    variant="outlined"
                                    value={mainDataPoint.article.title + " " + mainDataPoint.article.description}
                                    onChange={(e) => handleMessageChange(idx, e)}
                                    className={classes.textField2}
                                    style={{ backgroundColor: "#F3FAFF", width: "100%" }}
                                    InputProps={{ className: classes.textarea, readOnly: (checkPublished) }}
                                />
                            </Grid>
                            {
                                aiTranslationFlag && mainDataPoint.language !== "en" && <Grid item lg={12} md={12} sm={12} >
                                    <CssTypography>Translated Message: </CssTypography>
                                    <CssTextField
                                        multiline
                                        type="text"
                                        variant="outlined"
                                        value={mainDataPoint.translated_article.title + " " + mainDataPoint.translated_article.description}
                                        className={classes.textField2}
                                        style={{ backgroundColor: "#F3FAFF", width: "100%" }}
                                        InputProps={{ className: classes.textarea, readOnly: true }}
                                    />
                                </Grid>
                            }
                        </>}
                        <Grid item lg={12} md={12} sm={12}>
                            <Grid container>
                                <Grid item lg={8} md={7} sm={6} style={{ display: "flex", alignItems: "end" }}>
                                    {mainCardFlag && <CustomCardNumber index={(idx + 1) + (10 * (page - 1))} totalCount={countEvents} />}
                                    {!mainCardFlag && <CustomCardNumber index={idx + 1} totalCount={countEvents} />}
                                    <Grid container style={{ marginLeft: "10px", rowGap: "10px" }}>
                                        {content === "extractedEvents" && !mainDataPoint.health_information && <CustomCardTag color={"#476777"} background={"#E2EDF1"} icon={MainEventIcon} text={"Unusual Health Event"} tooltipText={"This article is unsual health event"} />}
                                        {content === "extractedEvents" && mainDataPoint.health_information && <CustomCardTag color={"#476777"} background={"#E2EDF1"} icon={MainEventIcon} text={"Health Information"} tooltipText={"This article is health information"} />}
                                        {(content === "shortlistedEvents" || content === "publishedEvents") && mainDataPoint.health_information && <CustomCardTag color={"#476777"} background={"#E2EDF1"} icon={MainEventIcon} text={"Health Information"} tooltipText={"This article is health information"} />}
                                        {(content === "shortlistedEvents" || content === "publishedEvents") && !mainDataPoint.health_information && <CustomCardTag color={"#476777"} background={"#E2EDF1"} icon={MainEventIcon} text={"Health Event "} tooltipText={"This article is health event"} />}
                                        {mainDataPoint.source_type === "newspaper" && <CustomCardTag color={"#468548"} background={"#E1F2E2"} icon={newspaperIcon} text={"Newspaper"} tooltipText={"This source of the event is newspaper"} />}
                                        {mainDataPoint.source_type === "video" && <CustomCardTag color={"#468548"} background={"#E1F2E2"} icon={videoIcon} text={"Video"} tooltipText={"This source of the event is video"} />}
                                        {aiTranslationFlag && mainDataPoint.language !== "en" && <CustomCardTag color={"#71608D"} background={"#EDE9F3"} icon={AITranslatedIcon} text={"AI Translated"} tooltipText={"This article is translated from " + langaugeAbbreMap[mainDataPoint.language]} />}
                                    </Grid>
                                </Grid>
                                <Grid item lg={4} md={5} sm={6} style={{ display: "flex", justifyContent: "end", alignItems: "end" }} className={collapseCard && idx == 0 ? "make-dropdown-below-to-the-button" : ""} >
                                    {(content === "extractedEvents" || content === "shortlistedEvents") && !clusterDialog && !mainDataPoint.reject_value && <CustomActionButton showFlagValue={showActiveButton} actionType={actionTypeMap.reject} label={actionTypeMap.reject} eventData={mainDataPoint} actionPerformed={onRejectSetIndex} setActionType={toggleButtons} setShowFlagValue={setShowActiveButton} disabled={buttonFreeze} />}
                                    {(content === "extractedEvents" || content === "shortlistedEvents") && clusterDialog && !mainDataPoint.reject_value && <CustomActionButtonFive actionType={actionTypeMap.remove} label={actionTypeMap.remove} eventData={mainDataPoint} idx={idx} actionPerformed={onRemoveSetIndex} theme={customThemes.secondary} disabled={buttonFreeze} />}
                                    {(content === "extractedEvents" || content === "shortlistedEvents") && mainDataPoint.reject_value && <CustomActionButtonTwo label={actionTypeMap.unreject} data={mainDataPoint} theme={"primary"} actionPerformed={unRejectCluster} disabled={buttonFreeze} />}
                                    <div style={{ width: "12px" }}></div>
                                    {content === "extractedEvents" && !mainDataPoint.reject_value && <CustomActionButton showFlagValue={showActiveButton} actionType={actionTypeMap.shortlist} label={actionTypeMap.shortlist} eventData={mainDataPoint} hasParent={!!mainDataPoint.parent_type} clusterDialog={clusterDialog} actionPerformed={onShortistSetIndex} setActionType={toggleButtons} setShowFlagValue={setShowActiveButton} disabled={buttonFreeze} setIsValidEventData={setIsValidEventData} setIsValidData={setIsValidData} />
                                    }
                                    {content === "shortlistedEvents" && !mainDataPoint.health_information && !mainDataPoint.reject_value && <CustomActionButton showFlagValue={showActiveButton} actionType={actionTypeMap.publish} label={actionTypeMap.publish} eventData={mainDataPoint} hasParent={!!mainDataPoint.parent_type} actionPerformed={onPublishSetIndex} setActionType={toggleButtons} setShowFlagValue={setShowActiveButton} disabled={buttonFreeze} setIsValidEventData={setIsValidEventData} setIsValidData={setIsValidData} />
                                    }
                                    {content === "shortlistedEvents" && mainDataPoint.health_information && !mainDataPoint.reject_value && <CustomActionButtonTwo label={actionTypeMap.sendEmail} data={mainDataPoint} theme={"primary"} emailFlag={true} emailID={"idsp-msc@nic.in"} actionPerformed={sendEmailForCluster} disabled={true} setIsValidEventData={setIsValidEventData} />
                                    }
                                    {content === "publishedEvents" && <CustomActionButtonTwo label={actionTypeMap.republish} theme={"primary"} data={mainDataPoint} actionPerformed={openPublishDialog} disabled={buttonFreeze} setIsValidEventData={setIsValidEventData} setIsValidData={setIsValidData} />
                                    }
                                    {content === "publishedEvents" && !clusterDialog && <>
                                        <div style={{ width: "12px" }}></div>
                                        <CustomActionButtonTwo label={actionTypeMap.printPDF} theme={"outlined"} data={mainDataPoint} actionPerformed={openPDFDialog} disabled={buttonFreeze} />
                                    </>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {content === "publishedEvents" && !clusterDialog && <Grid item lg={12} md={12} sm={12}>
                    <CustomStatusBar icon={<AddCircle />} eventId={mainDataPoint.id} theme={mainDataPoint.outbreak_status} clicked={openSummaryDialog} />
                </Grid>}
            </Grid>
            {mainCardFlag && (mainDataPoint.cluster_count > 1) && <Grid container item sm={3} md={3} lg={3} style={{ cursor: "pointer" }} onClick={() => openRelatedEventsDialog(mainDataPoint.cluster_id, mainDataPoint.id)}>
                <div style={{ width: "100%", height: "100%", background: "#E3E4DB", color: "#413F40", borderRadius: "0px 10px 10px 0px" }}>
                    <div className='relative-events-div' style={{ display: "Flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly", height: "100%" }}>
                        <div style={{ display: "Flex", flexDirection: "column", alignItems: "center" }}>
                            <div style={{ margin: 0, fontSize: "5rem", fontWeight: "700" }}>+{mainDataPoint.cluster_count - 1}</div>
                            <div style={{ fontSize: " 1.2rem", fontWeight: "500", width: "80%", textAlign: "center", textWrap: 'nowrap' }}>Related Reports</div>
                        </div>
                        {!collapseCard && <button className='relative-events-button' >SEE RELATED REPORTS </button>}
                    </div>
                </div>
            </Grid>}
        </Paper>
    )
}

export default SingleCard;
