import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./CustomLoader.css";

export default function CircularIndeterminate ({ color, size, }) {
  return (
    <div className='loader-div'>
      <CircularProgress size={size || 22} style={{ color: color || "#fff" }}/>
    </div>
  );
}
