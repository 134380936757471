import React from 'react';
import Lottie from 'react-lottie';
import JoyRideRadioButton from './JoyRideRadioButton'

const JoyRideBox = ({ currIdx, totalSteps, icon, iconSize, lottieIcon, heading, subHeading, text, hideSteps }) => {
    return (
        <div style={{ width: "100%", boxShadow: "borderBox" }}>
            {!hideSteps && <div style={{ display: "flex", margin: "12px 0px 8px" }}>
                {Array.from({ length: totalSteps }, (_, index) => index).map(idx => <JoyRideRadioButton key={idx} size="10px" color={idx === currIdx ? "#008BCA" : "#B4E7FF"}/>)}
            </div>}
            {(icon || lottieIcon || heading) && <div style={{ display: "flex", alignItems: "center", justifyContent: "flext-start", margin: "12px 0px 8px" }}>
                {icon && <img src={icon} style={{ height: iconSize || "30px", }} alt="onboarding icon" />}
                {lottieIcon && <Lottie options={{ loop: true, autoplay: true, animationData: lottieIcon, rendererSettings: { preserveAspectRatio: 'xMidYMid slice', }, }} height={iconSize || 30} width={iconSize || 30} style={{ margin: 0 }}/>}
                {heading && <p style={{ fontSize: "24px", fontWeight: 600, lineHeight: "30px", margin: "0 0 0 8px", }}>{heading}</p>}
            </div>}
            {(subHeading || text) && <div style={{ textAlign: "left", margin: "12px 0 25px" }}>
                {subHeading && <p style={{ fontSize: "16px", fontWeight: 400, color: "#434343", lineHeight: "19px", margin: "0", orphans: 3, widows: 3 }}>{subHeading}</p>}
                {text && <p style={{ fontSize: "16px", fontWeight: 400, color: "#858585", lineHeight: "19px", margin: "8px 0 0", orphans: 3, widows: 3 }}>{text}</p>}
            </div>}
        </div>
    )
};

export default JoyRideBox;
