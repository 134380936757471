import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import * as React from 'react';
import { CssTextField, customThemes } from '../../constants/app-constants';
import CustomButton from '../Shared/CustomButton/CustomButton';
import bgImage from '../../resources/pattern.png';
import { Close } from '@material-ui/icons';
import "./CustomPublishDialog.css";

const CustomPublishDialog = ({ dialogFlag, customEventDate, customEventDisease, finalDiseases, customEventNumberOfCases, customEventState, customStateDistrictList, customEventDistrict, customEventURL, customEventLanguage, languageList, customEventIncident, incidentEnums, customEventMessage, setCustomEventDate = () => { }, setCustomEventDisease = () => { }, setCustomEventNumberOfCases = () => { }, setCustomEventState = () => { }, setCustomEventDistrict = () => { }, capitalizeFirstLetter = () => { }, setCustomEventURL = () => { }, setCustomEventLanguage = () => { }, setCustomEventIncident = () => { }, setCustomEventMessage = () => { }, closeDialog = () => { }, customPublishEvent = () => { } }) => {
  const closeDialogFun = () => {
    closeDialog(false)
  }

  return (
    <div>
      <Dialog
        open={dialogFlag}
        onClose={closeDialogFun}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'lg'}
      >
        <DialogTitle id="alert-dialog-title" style={{ backgroundImage: `url(` + bgImage + `)`, backgroundColor: "#D4EAF0", backgroundSize: "cover", padding: "1rem" }}>
                    <Grid container>
                        <Grid item lg={11} md={11} sm={11} style={{ fontSize: "x-large", display: "flex", alignItems: "center", fontWeight: "600" }}>
                            Custom Publish Event
                        </Grid>
                        <Grid item lg={1} md={1} sm={1} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                            <IconButton onClick={() => closeDialogFun()}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
        <DialogContent>
          <Box>
            <Paper style={{ margin: "20px", backgroundColor: "white", border: "1px solid black" }}>
              <Grid container style={{ padding: "15px" }}>
                <Grid item lg={4} sm={12} md={6}>
                  <Typography>Date</Typography>
                  <CssTextField
                    type="datetime-local"
                    size="small"
                    variant="outlined"
                    value={customEventDate}
                    onChange={(e) => setCustomEventDate(e.target.value)}
                    style={{ backgroundColor: "#F3FAFF" }}
                  />
                </Grid>
                <Grid item lg={4} sm={12} md={6}>
                  <Typography>Disease Type</Typography>
                  <FormControl fullWidth size="small" style={{ width: "80%", backgroundColor: "#F3FAFF" }}>
                    <Select
                      variant="outlined"
                      labelId="disease-label"
                      defaultValue={customEventDisease}
                      onChange={(e) => setCustomEventDisease(e.target.value)}
                    >
                      {
                        finalDiseases.flatMap(disease => disease.items).map(disease => disease.value).map((disease, j) =>
                          <MenuItem key={disease} value={disease} >{disease}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={4} sm={12} md={6}>
                  <Typography>Reported Cases</Typography>
                  <CssTextField
                    type="text"
                    size="small"
                    variant="outlined"
                    value={customEventNumberOfCases}
                    onChange={(e) => setCustomEventNumberOfCases(e.target.value)}
                    style={{ backgroundColor: "#F3FAFF" }}
                  />
                </Grid>
                <Grid item lg={4} sm={12} md={6}>
                  <Typography>State</Typography>
                  <FormControl fullWidth size="small" style={{ width: "80%", backgroundColor: "#F3FAFF" }}>
                    <Select
                      variant="outlined"
                      labelId="state-label"
                      defaultValue={customEventState}
                      onChange={(e) => setCustomEventState(e.target.value)}
                    >
                      {
                        Object.keys(customStateDistrictList).map((state, j) =>
                          <MenuItem key={state} value={state} >{state}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={4} sm={12} md={6}>
                  <Typography>District</Typography>
                  <FormControl fullWidth size="small" style={{ width: "80%", backgroundColor: "#F3FAFF" }}>
                    <Select
                      variant="outlined"
                      labelId="district-label"
                      defaultValue={customEventDistrict}
                      onChange={(e) => setCustomEventDistrict(e.target.value)}
                    >
                      {
                        (customStateDistrictList[customEventState !== null ? customEventState : ""] !== undefined ? customStateDistrictList[customEventState !== null ? customEventState : ""] : []).map((district, j) =>
                          <MenuItem key={capitalizeFirstLetter(district)} value={capitalizeFirstLetter(district)} >{capitalizeFirstLetter(district)}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={4} sm={12} md={6}>
                  <Typography>Source (URL):</Typography>
                  <CssTextField
                    type="text"
                    size="small"
                    variant="outlined"
                    value={customEventURL}
                    onChange={(e) => setCustomEventURL(e.target.value)}
                    style={{ backgroundColor: "#F3FAFF" }}
                  />
                </Grid>
                <Grid item lg={4} sm={12} md={6}>
                  <Typography>Language</Typography>
                  <FormControl fullWidth size="small" style={{ width: "80%", backgroundColor: "#F3FAFF" }}>
                    <Select
                      variant="outlined"
                      labelId="language-label"
                      defaultValue={customEventLanguage}
                      onChange={(e) => setCustomEventLanguage(e.target.value)}
                    >
                      {
                        languageList.map((lang, j) =>
                          <MenuItem key={lang} value={lang} >{lang}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={4} sm={12} md={6}>
                  <Typography>Incident</Typography>
                  <FormControl fullWidth size="small" style={{ width: "80%", backgroundColor: "#F3FAFF" }}>
                    <Select
                      variant="outlined"
                      labelId="language-label"
                      defaultValue={customEventIncident}
                      onChange={(e) => setCustomEventIncident(e.target.value)}
                    >
                      {
                        incidentEnums.map((incident, j) =>
                          <MenuItem key={incident} value={incident} >{incident}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={12} sm={12} md={12}>
                  <Typography>Message:</Typography>
                  <CssTextField
                    multiline
                    type="text"
                    variant="outlined"
                    value={customEventMessage}
                    onChange={(e) => setCustomEventMessage(e.target.value)}
                    style={{ backgroundColor: "#F3FAFF", width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions style={{ margin: "10px" }}>
          <CustomButton label={"Publish"} theme={customThemes.primary} clicked={customPublishEvent} />
        </DialogActions>
      </Dialog>
    </div >
  )
}

export default CustomPublishDialog;
