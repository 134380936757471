import * as React from 'react';
import { customThemes } from '../../../constants/app-constants';
import './CustomActionButtonFive.css';

const CustomActionButtonFive = ({ label, theme, actionType, eventData, idx, disabled, actionPerformed = () => { } }) => {
    const themedButton = (function () {
        if (theme === customThemes.primary) {
            return (<button className='primary-custom-button-five' disabled={disabled} onClick={() => actionPerformed(eventData)}>{label}</button>)
        } else if (theme === customThemes.secondary) {
            return (<button className='secondary-custom-button-five' disabled={disabled} onClick={() => actionPerformed(eventData)}>{label}</button>)
        } else {
            return (<button className='tertiary-custom-button-five' disabled={disabled} onClick={() => actionPerformed(eventData)}>{label}</button>)
        }
    })();

    return (
       <div>{themedButton}</div>
    )
}

export default CustomActionButtonFive;
