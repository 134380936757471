import React from 'react';
import Lottie from 'react-lottie';

const JoyrideCustomButton = ({ airalLabel, color, fontSize, iconSize, iconClass, iconType, icon, preIcon, postIcon, label, padding, background, hideNextButton, onClick = () => { } }) => {
    return <div onClick={onClick} style={{ backgroundColor: background || "#FF7A5A", borderRadius: "8px", padding: padding || "14px 20px", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }} aria-label={label}>
        {preIcon && iconType === "lottify" && <Lottie options={{ loop: true, autoplay: true, animationData: icon, rendererSettings: { preserveAspectRatio: 'xMidYMid slice', }, }} height={iconSize || 30} width={iconSize || 30} style={{ margin: 0 }}/>}
        {preIcon && (iconType === "gif" || iconType === "icon") && <img src={icon} style={{ height: iconSize || "30px", width: iconSize || "30px", color: color || "#000" }} alt="onboarding icon" />}
        {preIcon && iconClass && <i className={"pi " + iconClass} style={{ fontSize: iconSize || '2rem', color: color || "#000" }}></i>}
        {label && <p style={{ fontSize: fontSize || "1rem", color: color || "#000", margin: preIcon ? "0px 0px 0px 6px" : postIcon ? "0px 6px 0px 0px" : "0" }}>{label}</p>}
        {postIcon && iconType === "lottify" && <Lottie options={{ loop: true, autoplay: true, animationData: icon, rendererSettings: { preserveAspectRatio: 'xMidYMid slice', }, }} height={iconSize || 30} width={iconSize || 30} style={{ margin: 0 }}/>}
        {postIcon && (iconType === "gif" || iconType === "icon") && <img src={icon} style={{ height: iconSize || "30px", width: iconSize || "30px", color: color || "#000" }} alt="onboarding icon" />}
        {postIcon && iconClass && <i className={"pi " + iconClass} style={{ fontSize: iconSize || '2rem', color: color || "#000" }}></i>}
    </div>
};

export default JoyrideCustomButton;
