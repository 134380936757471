// export const SET_LOGIN_USER = "SET_LOGIN_USER";
// export const LOGOUT = "LOGOUT";
// export const SET_SOLUTIONS_OPTIONS = "SET_SOLUTIONS_OPTIONS";
// export const SET_DATA_TABLE = "SET_DATA_TABLE";
// export const SET_TOOL_SUPPORTED = "SET_TOOL_SUPPORTED"
// export const SET_DATA_TYPE_SUPPORTED = "SET_DATA_TYPE_SUPPORTED"

export const SET_EVENTS = "SET_EVENTS"
export const SET_EVENTS_COUNT = "SET_EVENTS_COUNT"
export const SET_DISEASE_CATEGORIES = "SET_DISEASES_CATEGORIES"
export const SET_STATE_CATEGORIES = "SET_STATE_CATEGORIES"
export const SET_DISTRICT_CATEGORIES = "SET_DISTRICT_CATEGORIES"
export const SET_EVENT_SUMMARY = "SET_EVENT_SUMMARY"
export const SET_EVENT_SUB_TYPES = "SET_EVENT_SUB_TYPES"
export const SET_SOURCE_CATEGORIES = "SET_SOURCE_CATEGORIES"
export const SET_LANGUAGE_CATEGORIES = "SET_LANGUAGE_CATEGORIES"
export const SET_SORT_CATEGORIES = "SET_SORT_CATEGORIES"
export const SET_GENRATE_PDF = "SET_GENRATE_PDF"
export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA"
export const SET_JOB_ID = "SET_JOB_ID";
export const SET_REPORT_DOWNLOAD = "SET_REPORT_DOWNLOAD";
