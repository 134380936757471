import React from 'react';

const useNetworkStatus = () => {
  const [isOnline, setNetwork] = React.useState(window.navigator.onLine);

  const updateNetwork = () => {
    const online = window.navigator.onLine;
    setNetwork(online);
  };

  React.useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);

    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  }, []);

  return isOnline;
};

export default useNetworkStatus;
