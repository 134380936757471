import React from "react";
import './CustomDialogBox.css';
import { Close } from '@material-ui/icons';
import bgImage from '../../../resources/pattern.png';

const CustomDialogBox = ({ showDialog, InnerComp, headerIcon, headerText, bodyText, buttonText, buttonIcon, footer, setShowDialog = () => {}, handleButtonAction = () => {} }) => {
    const defaultComp = <>
        <div className="custom-dialog-box-inner-container" style={{ backgroundImage: `url(` + bgImage + `)` }}>
            <div className="header-wrapper">
                <img src={headerIcon} alt="img-with-header" />
                <div className="heder-text" >
                    {headerText}
                </div>
            </div>
            {bodyText && <div className="body-text">
                <p>{bodyText}</p>
            </div>}
            {buttonText && <div className="button-wrapper" >
                <div onClick={handleButtonAction} >
                    {buttonIcon && <img src={buttonIcon} alt="pre-button-icon" />}
                    <p>{buttonText}</p>
                </div>
            </div>}
            {footer && <div>{footer}</div>}
        </div>
    </>

    const innerElem = InnerComp ? <>{InnerComp}</> : <>{defaultComp}</>

    if (!showDialog) {
        return <></>
    }

    return (
        <div className="custom-dialog-box-container">
            <div >
                <div>
                    {innerElem}
                </div>
                <button className='custom-close-button' onClick={setShowDialog} >
                    <Close style={{ fontSize: '1.5rem', color: '#fff' }} />
                </button>
            </div>
        </div>
    )
}

export default CustomDialogBox;
