import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import * as React from 'react';
import "./CustomPaginator.css";

const CustomPaginator = ({ totalPages, currentPageValue, handlePageChange = () => {} }) => {
    function nextPage () {
        if (currentPageValue < totalPages) {
            handlePageChange(currentPageValue + 1)
        }
    }

    function prevPage () {
        if (currentPageValue > 1) {
            handlePageChange(currentPageValue - 1)
        }
    }
    return (
        <div className='paginator-main-div'>
                        <button className='paginator-action-button' style={{ cursor: currentPageValue !== 1 ? "pointer" : "", fontWeight: currentPageValue !== 1 ? "500" : "" }} disabled={currentPageValue === 1} onClick={() => prevPage()}>Previous Page <ArrowBackIos style={{ marginLeft: "0.5rem" }}/></button>
            <div className='paginator-label'>
                <span className='label-item current-page-label'>{totalPages >= 1 ? currentPageValue : 0}</span>
                <span className='label-item'>of</span>
                <span className='label-item'>{totalPages}</span>
            </div>
            <button className='paginator-action-button' style={{ cursor: totalPages !== 0 && currentPageValue !== totalPages ? "pointer" : "", fontWeight: totalPages !== 0 && currentPageValue !== totalPages ? "500" : "" }} disabled={totalPages === 0 || currentPageValue === totalPages} onClick={() => nextPage()}><ArrowForwardIos style={{ marginRight: "0.5rem" }}/> Next Page</button>
        </div>
    )
}

export default CustomPaginator;
