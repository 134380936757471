// import { SET_SOLUTIONS_OPTIONS } from "../actions/types";

// const initialState = {
//   annotationOptionsFetched: false,
//   annotationOptionsFetching: false,
//   errannotationOptions: false,
//   payload: [],

// };

// export default (state = initialState, action = {}) => {
//   switch (action.type) {
//     case SET_SOLUTIONS_OPTIONS:
//       return Object.assign({}, state, {
//         annotationOptionsFetched: true,
//         annotationOptionsFetching: false,
//         errAnnotationOptions: false,
//         payload: action.data
//       });
//     default:
//       return state;
//   }
// };

import { SET_DISEASE_CATEGORIES, SET_EVENTS, SET_EVENTS_COUNT, SET_STATE_CATEGORIES } from "../actions/types";

const initialState = {
    events: {},
    count: 0,
    diseaseCategory: {},
    stateCategory: {}
  };
  export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_EVENTS:
            return Object.assign({}, state, {
                events: action.data
              });
        case SET_EVENTS_COUNT:
          return Object.assign({}, state, {
              count: action.data
            });
        case SET_DISEASE_CATEGORIES:
          return Object.assign({}, state, {
              diseaseCategory: action.data
            });
        case SET_STATE_CATEGORIES:
          return Object.assign({}, state, {
              stateCategory: action.data
            });
        default:
        return state;
    }
  };
