import * as React from 'react';
import "./CustomCardNumber.css";

const CustomCardNumber = ({ index, totalCount }) => {
    return (
        <div className='card-number-div'>
            {index}/{totalCount}
        </div>
    )
}

export default CustomCardNumber;
