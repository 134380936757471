import { Tooltip } from '@material-ui/core';
import React from 'react';
import Lottie from 'react-lottie';

const CustomButtonWithIconTwo = ({ airalLabel, tooltipText, color, fontSize, fontWeight, iconSize, iconClass, iconType, icon, preIcon, postIcon, label, padding, background, hideNextButton, borderColor, onClick = () => { } }) => {
    const DefaultComp = <div className='custom-button-with-icon-two' onClick={onClick} style={{ backgroundColor: background || "#FF7A5A", borderRadius: "8px", padding: padding || "14px 20px", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer", border: `1px solid ${borderColor || "transparent"}` }} aria-label={label}>
        {preIcon && iconType === "lottify" && <Lottie options={{ loop: true, autoplay: true, animationData: icon, rendererSettings: { preserveAspectRatio: 'xMidYMid slice', }, }} height={iconSize || 30} width={iconSize || 30} style={{ margin: 0 }}/>}
        {preIcon && (iconType === "gif" || iconType === "icon") && <img src={icon} style={{ height: iconSize || "30px", width: iconSize || "30px", color: color || "#000" }} alt="onboarding icon" />}
        {preIcon && iconClass && <i className={"pi " + iconClass} style={{ fontSize: iconSize || '2rem', color: color || "#000", fontWeight: 'bolder' }}></i>}
        {label && <p style={{ fontSize: fontSize || "1rem", color: color || "#000", fontWeight: fontWeight || "normal", margin: preIcon ? "0px 0px 0px 6px" : postIcon ? "0px 6px 0px 0px" : "0", textWrap: "nowrap" }}>{label}</p>}
        {postIcon && iconType === "lottify" && <Lottie options={{ loop: true, autoplay: true, animationData: icon, rendererSettings: { preserveAspectRatio: 'xMidYMid slice', }, }} height={iconSize || 30} width={iconSize || 30} style={{ margin: 0 }}/>}
        {postIcon && (iconType === "gif" || iconType === "icon") && <img src={icon} style={{ height: iconSize || "30px", width: iconSize || "30px", color: color || "#000" }} alt="onboarding icon" />}
        {postIcon && iconClass && <i className={"pi " + iconClass} style={{ fontSize: iconSize || '2rem', color: color || "#000" }}></i>}
    </div>;

    const Component = tooltipText ? <Tooltip title={tooltipText} placement="top" arrow>
        {DefaultComp}
    </Tooltip>
    : DefaultComp;

    return <>{Component}</>;
};

export default CustomButtonWithIconTwo;
