/* eslint-disable camelcase */
import { Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

export const language_state_map = {
    "Jammu and Kashmir": ["English", "Hindi"],
    "Himachal Pradesh": ["English", "Hindi"],
    Punjab: ["English", "Hindi", "Punjabi"],
    Chandigarh: ["English", "Hindi", "Punjabi"],
    Uttarakhand: ["English", "Hindi"],
    Haryana: ["English", "Hindi"],
    Delhi: ["English", "Hindi"],
    Rajasthan: ["English", "Hindi"],
    "Uttar Pradesh": ["English", "Hindi"],
    Bihar: ["English", "Hindi"],
    Sikkim: ["English", "Hindi"],
    "Arunachal Pradesh": ["English", "Hindi"],
    Nagaland: ["English", "Hindi"],
    Manipur: ["English", "Hindi"],
    Mizoram: ["English", "Hindi"],
    Tripura: ["English", "Hindi"],
    Meghalaya: ["English", "Hindi"],
    Assam: ["English", "Hindi"],
    "West Bengal": ["English", "Hindi", "Bengali"],
    Jharkhand: ["English", "Hindi"],
    Odisha: ["English", "Hindi", "Odia"],
    Chhatisgarh: ["English", "Hindi", "Odia"],
    "Madhya Pradesh": ["English", "Hindi"],
    Gujarat: ["English", "Hindi", "Gujarati"],
    Maharashtra: ["English", "Hindi", "Marathi"],
    "Andhra Pradesh": ["English", "Hindi", "Telugu"],
    Karnataka: ["English", "Hindi", "Kannada"],
    Goa: ["English", "Hindi"],
    Lakshadweep: ["English", "Hindi"],
    Kerala: ["English", "Hindi", "Malayalam"],
    "Tamil Nadu": ["English", "Hindi", "Tamil"],
    Puducherry: ["English", "Hindi", "Tamil"],
    "Andaman & Nicobar Islands": ["English", "Hindi"],
    Telangana: ["English", "Hindi", "Telugu"],
    Ladakh: ["English", "Hindi"],
    "The Dadra And Nagar Haveli And Daman And Diu": ["English", "Hindi"]
}

export const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#DADCE0",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#DADCE0",
            borderWidth: "1px",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#DADCE0",
            },
            "&:hover fieldset": {
                borderColor: "#DADCE0",
                borderWidth: "1px",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#DADCE0",
                borderWidth: "1px",
            },
        },
        "&.makeStyles-textField2-28": {
          margin: 0
        }
    },
})(TextField);

export const CssTypography = withStyles({
  root: {
    "&.MuiTypography-body1": {
      fontWeight: 500
    }
  }
})(Typography);

export const useStyles = makeStyles((theme) => ({
    textField: {
      // className: "MuiGrid-item",
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
    },
    textField2: {
      width: "85%"
    },
    textarea: {
      resize: "vertical"
    },
    topBar: {
      borderBottom: "3px solid #2F95C8 ",
      color: "#2F95C8",
    },
    snackbar: {
      fontSize: '20px !important',
      background: 'rgb(224 251 224 / 70%) !important',
      color: 'green !important',
      fontWeight: 'bold !important',
      padding: '1rem !important',
      borderRadius: '10px !important',
      width: '500px !important',
      borderLeft: '10px solid green !important'
    },
    failedsnackbar: {
      fontSize: '20px !important',
      background: 'rgb(255 175 175 / 70%) !important',
      color: '#b70c0c !important',
      fontWeight: 'bold !important',
      padding: '1rem !important',
      borderRadius: '10px !important',
      width: '500px !important',
      borderLeft: '10px solid #b70c0c !important'
    },
    snackbarEventLimit: {
      background: 'red'
    },
    paginator: {
      justifyContent: "center",
      padding: "10px"
    },
    formControl: {
      fullWidth: true,
      marginRight: "10px",
      display: "flex",
      wrap: "nowrap"
    }
  }))

export const actionButtonColorMap = {
  primary: "#413F40",
  secondary: "#D7686D",
  tertiary: "#85CDD0",
  green: "#16754C"
}

export const actionTypeMap = {
  shortlist: "Shortlist",
  publish: "Publish",
  reject: "Reject",
  remove: "Remove",
  unreject: "Un-Reject",
  status: "Show Status",
  republish: "Republish",
  sendForApproval: "Send For Approval",
  pendingForApproval: "Pending For Approval",
  approvalDenied: "Approvla Denied",
  sendEmail: "Send Email",
  printPDF: "Print PDF",
  downloadReport: "Download the Report",
}

export const subActionTypeMap = {
  shortlistNewEvent: 1,
  addToShortlistCluster: 2,
  publishNewEvent: 3,
  addToPublishCluster: 4,
  shortlistAsHealthInfo: 5,
  rejectFakeSource: 6,
  rejectOldNews: 7,
  rejectIrrelevant: 8,
  rejectPublished: 9,
  rejectInternationalEvent: 10,
}

export const notificationBarText = {
  shortlisted: "This article is similar to the Cluster you shortlisted today. You can either add this to the same cluster or create a new event during Shortlist",
  published: "This article is similar to the Cluster you published today. You can either add this to the same cluster or create a new event during Publish",
}

export const customThemes = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
  grey: "#78828C",
}

export const statusValues = {
  pending: "Pending",
  ongoing: "Ongoing",
  completed: "Completed",
  closed: "Closed"
}

export const eventTypeMap = {
  extractedEvents: "Extracted",
  shortlistedEvents: "Shortlisted",
  publishedEvents: "Published"
}

export const HeaderEventTypeMap = {
  Dashboard: "dashboard",
  "Media Alerts List": "extractedEvents",
  Shortlisted: "shortlistedEvents",
  Published: "publishedEvents"
}

export const langaugeAbbreMap = {
  en: "English",
  hi: "Hindi",
  te: "Telugu",
  ta: "Tamil",
  kn: "Kannada",
  mr: "Marathi",
  bn: "Bengali",
  gu: "Gujarati",
  pa: "Punjabi",
  ml: "Malayalam",
  or: "Odia"
}

export const dashboardPerformances = [
  { type: "Reporting Performance", dashboardType: "1" },
  { type: "Disease Performance", dashboardType: "2" },
];

export const dashboardSubTypes = {
  extractedEvents: [
      { type: "All Media List", code: "0", count: -1 },
      { type: "Unusual Health Events", code: "1", count: -1 },
      { type: "Rejected", code: "3", count: -1 }
    ],
  shortlistedEvents: [
      { type: "All Shortlisted", code: "0", count: -1 },
      { type: "Health Event", code: "4", count: -1 },
      { type: "Health Information", code: "2", count: -1 },
      { type: "Rejected", code: "3", count: -1 }
    ],
  publishedEvents: [
      { type: "All Published", code: "0", count: -1 },
      { type: "All Event Stages", code: "9", count: -1 },
      { type: "Ongoing Investigation", code: "10", count: -1 },
      { type: "Ongoing Outbreaks", code: "11", count: -1 },
      { type: "Closed Outbreaks", code: "12", count: -1 },
      { type: "Closed Events", code: "13", count: -1 },
      { type: "Health Information", code: "2", count: -1 }
    ]
}
