import React from "react";
import DatePicker from 'rsuite/DatePicker';
import "./CustomDateTimePicker.css";
import 'rsuite/dist/rsuite-no-reset.min.css';
import icon from '../../../resources/dateIcon.png'

const CustomDateTimePicker = ({ date, minDate, maxDate, setDate = () => { } }) => {
    const handleDateChange = (date) => {
        setDate(date);
    };

    const disabledDate = (date) => {
        return date < minDate || date > maxDate;
    };

    const customLocale = {
        ok: 'Save',
    };

    return (
        <div style={{ position: 'relative', width: "100%" }} className="multiselect-wrapper">
            <div className="multiselect-icon-wrapper" style={{ zIndex: 6 }}>
                <img src={icon} className="multiselect-icon" alt="icon" />
            </div>
            <DatePicker
            value={date}
            onChange={handleDateChange}
            format="dd-MM-yyyy hh:mm aa"
            placeholder="Select a date"
            disabledDate={disabledDate}
            block
            cleanable={false}
            showMeridian
            container={() => document.querySelector('.rs-picker-date')}
            locale={customLocale}
            editable={true}
        />
        </div>
    )
}

export default CustomDateTimePicker;
