import { Divider } from '@material-ui/core';
import * as React from 'react';
import { HeaderEventTypeMap } from '../../../constants/app-constants';
import './CustomHeaderTab.css';

const CustomHeaderButton = ({ heading, content }) => {
    return (
        <div className='custom-header-div'>
            <button className='custom-header-button' style={{ color: content === HeaderEventTypeMap[heading] ? "#008BCA" : "#413F40" }}>{heading}</button>
            {content === HeaderEventTypeMap[heading] && <Divider className='custom-header-bar'/>}
        </div>
    )
}

export default CustomHeaderButton;
